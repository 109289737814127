<template>
  <CommonModal v-model="show" :modal-size="modalWidth ? '' : 'modal-xs'" :modal-width="modalWidth" :title="title">
    <template #header>
      <div class="confirm-modal-header">
        <div class="d-flex justify-space-between align-center">
          <div class="confirm-modal-header__title">{{ title }}</div>
          <v-icon color="#000" @click="close">mdi-close</v-icon>
        </div>
      </div>
    </template>
    <template #body>
      <div class="confirm-modal-body d-flex align-center">
        <div class="confirm-modal-body__text">{{ msg }}</div>
      </div>
    </template>
    <template #footer>
      <div class="confirm-modal-footer text-right">
        <AppBtn class="btn-black" :loading="loading" @click="confirm">{{ $t('act.confirm') }}</AppBtn>
      </div>
    </template>
  </CommonModal>
</template>
<script>
import CommonModal from './index';
export default {
  name: 'SuccessModal',
  components: {
    CommonModal,
  },
  props: {
    modalWidth: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    msg: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>
<style lang="scss" scoped>
.confirm-modal-header {
  // background-color: #fff;
  padding: 15px 15px 15px;

  &__title {
    color: #000;
    font-size: 1.25rem;
    font-weight: 600;
  }
}
.confirm-modal-body {
  // background-color: #fff;
  min-height: 80px;
  line-height: 1.3;
  &__text {
    // color: var(--primary-color);
    font-size: 1rem;
    padding: 10px 15px 10px;
    // font-weight: 600;
  }
}
.confirm-modal-footer {
  padding: 10px 15px 10px;
}
</style>
