import Api from '../api';
import axios from 'axios';

export const orderApi = {
  ddl(params) {
    return Api.get('order/picklist', params);
  },
  list(params) {
    return Api.get('order/list', params);
  },
  detail(params) {
    return Api.get('order', params);
  },
  req(params) {
    return Api.post('order/req', params);
  },
  place(data) {
    return Api.post('order/place', data);
  },
  update(data) {
    return Api.post('order/update', data);
  },
  updateEx(data) {
    return Api.post('order/update-ex', data);
  },
  void(data) {
    return Api.post('order/void', data);
  },
  confirm(data) {
    return Api.post('order/confirm', data);
  },
  complete(data) {
    return Api.post('order/complete', data);
  },
  close(data) {
    return Api.post('order/close', data);
  },
  todayDelivery(params) {
    return Api.get('order/list?type=4', params);
  },
};

export const orderItemApi = {
  ddl(params) {
    return Api.get('order/item/picklist', params);
  },
  list(params) {
    return Api.get('order/item/list', params);
  },
  detail(params) {
    return Api.get('order/item', params);
  },
  create(data) {
    return Api.post('order/item/create', data);
  },
  update(data) {
    return Api.post('order/item/update', data);
  },
  void(data) {
    return Api.post('order/item/void', data);
  },
};

export const orderItemListApi = {
  list(params) {
    return Api.get('order/item/list', params);
  },
  combinedOpenJobList(params) {
    return Api.get('order/item/list/combined-open-job', params);
  },
  openJob(data) {
    return Api.post('order/item/list/open-job', data);
  },
};

export const printApi = {
  orderReceipt(template) {
    const url = 'http://127.0.0.1';
    const path = '/print';
    const data = {
      template,
    };
    return axios.post(url + path, data);
  },
};
