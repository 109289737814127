import { isUndefinedNullOrEmpty } from '@/util/tools';
import i18n from '@/translations';

const ELEMENT_TYPE = {
  COMMAND: 'COMMAND',
};

export default class DeliveryNote {
  constructor(data) {
    this.receiptFormat = {
      version: 1,
      proto: 'ESCPOS',
      command: {
        init: '{{0x1b40}}',
        post: '',
      },
      printTimeout: 30000,
      elements: [],
    };
    this.company = data.company;
    this.store = data.store;
    this.orderDT = data.orderDT;
    this.orderSeq = data.orderSeq;
    this.items = data.items;
    this.totalAmt = data.totalAmt;
    this.openCashReg = data.openCashReg;
    this.customerInfo = data.customer;
    this.deliveryAddress = data.deliveryAddress;
    this.payMethod = data.payMethod;
  }

  getElement(type, item) {
    return {
      type,
      value: item,
    };
  }

  get printObj() {
    let items = [];

    for (let item of this.items) {
      const element = this.getElement(ELEMENT_TYPE.COMMAND, `${item.rowItem}{{0x0a}}`);
      items.push(element);
    }

    let elements = [];

    if (this.payMethod == 2 /* COD */) {
      elements = [
        this.getElement(ELEMENT_TYPE.COMMAND, `{{0x1b6131}}${this.store}{{0x0a}}`),
        this.getElement(ELEMENT_TYPE.COMMAND, `${this.orderDT}{{0x0a}}{{0x0a}}{{0x1b6130}}`),
        this.getElement(
          ELEMENT_TYPE.COMMAND,
          `{{0x1b6131}}${i18n.t('orders.lbl.orderNo')}: {{0x1b2d32}}{{0x1d2110}}${
            this.orderSeq
          }{{0x1d2100}}{{0x1b2d30}}{{0x0a}}{{0x0a}}{{0x1b6130}}`
        ),
        this.getElement(ELEMENT_TYPE.COMMAND, `{{0x1b6131}}{{0x1d2110}}COD{{0x1d2100}}{{0x0a}}{{0x0a}}{{0x1b6130}}`),
        this.getElement(ELEMENT_TYPE.COMMAND, `${this.customerInfo.phone} ${this.customerInfo.custName}{{0x0a}}`),
        this.getElement(
          ELEMENT_TYPE.COMMAND,
          `${this.deliveryAddress.address}, ${this.deliveryAddress.postcode} ${this.deliveryAddress.city}{{0x0a}}{{0x0a}}`
        ),
        ...items,
        this.getElement(ELEMENT_TYPE.COMMAND, `{{0x0a}}{{0x1b6131}}baogerlab.com{{0x1b6405}}{{0x1b6130}}`),
      ];
    } else {
      elements = [
        this.getElement(ELEMENT_TYPE.COMMAND, `{{0x1b6131}}${this.store}{{0x0a}}`),
        this.getElement(ELEMENT_TYPE.COMMAND, `${this.orderDT}{{0x0a}}{{0x0a}}{{0x1b6130}}`),
        this.getElement(
          ELEMENT_TYPE.COMMAND,
          `{{0x1b6131}}${i18n.t('orders.lbl.orderNo')}: {{0x1b2d32}}{{0x1d2110}}${
            this.orderSeq
          }{{0x1d2100}}{{0x1b2d30}}{{0x0a}}{{0x0a}}{{0x1b6130}}`
        ),
        this.getElement(ELEMENT_TYPE.COMMAND, `${this.customerInfo.phone} ${this.customerInfo.custName}{{0x0a}}`),
        this.getElement(
          ELEMENT_TYPE.COMMAND,
          `${this.deliveryAddress.address}, ${this.deliveryAddress.postcode} ${this.deliveryAddress.city}{{0x0a}}{{0x0a}}`
        ),
        ...items,
        this.getElement(ELEMENT_TYPE.COMMAND, `{{0x0a}}{{0x1b6131}}baogerlab.com{{0x1b6405}}{{0x1b6130}}`),
      ];
    }

    let data = {
      ...this.receiptFormat,
      elements,
    };
    return data;
  }
}
