export const MENU_TYPE = {
  FIRST_LAYER: 0,
  SUB_SECTION: 1,
  LOWEST_LAYER: 2,
};

export const MENU_GRP = {
  SET: 1,
  ALACARTE: 2,
  PROMOTION: 3,
  FIXED_PRICE: 4,
  COMBO: 7,
  SPECIAL: 9,
};

export const MENU_ITEM_FLAG = {
  HIDE: 9,
};

// export { MENU_TYPE, PRODUCT_TYPE, INGREDIENT_TYPE, PO_STATUS, GRN_ITEM_ACT, STOCK_IN_OUT_MODE, JOB_STATUS, SEMI_GOODS_LABEL_STATUS, GOODS_LABEL_STATUS };
