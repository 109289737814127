<template>
  <div>
    <DataTable
      :headers="headers"
      :items="list"
      :mobile-breakpoint="0"
      :height="'70vh'"
      :show-footer="false"
      :item-class-func="rowClassFunc"
      disable-pagination
      @getList="getStockBalList"
    >
      <template #item-balCustom="{ item }">
        <div>{{ `${Math.floor(item.bal / item.serving)} ${item.uom} ${item.bal % item.serving}` }}</div>
      </template>
      <template #item-total="{ item }">
        <div>{{ item.bal + item.ordered }}</div>
      </template>
      <template #item-par="{ item }">
        <div :class="{ 'font-red-common font-weight-bold': item.bal <= item.par && item.bal > item.low }">{{ item.par }}</div>
      </template>
      <template #item-low="{ item }">
        <div :class="{ 'font-red-common font-weight-bold': item.bal <= item.low }">{{ item.low }}</div>
      </template>
      <template #item-action="{ item }">
        <div class="d-flex py-1">
          <AppBtn class="btn-blue-light mr-2" :width="80" @click.stop="showStockAdjModal(item)">
            {{ 'Adjust' }}
          </AppBtn>
          <AppBtn
            v-if="item.status == statusEnum.ACTIVE"
            class="btn-orange"
            :width="80"
            @click.stop="showConfirmModal(actionTypeEnum.STOP, item)"
            >{{ 'Stop' }}</AppBtn
          >
          <AppBtn v-else class="btn-orange" :width="80" @click.stop="showConfirmModal(actionTypeEnum.RESUME, item)">{{
            'Resume'
          }}</AppBtn>
        </div>
      </template>
    </DataTable>
    <div class="text-center mt-2">
      <AppBtn class="btn-black" :height="42" :width="160" @click="showReorderModal">{{ $t('stocks.act.restock') }}</AppBtn>
    </div>
    <ReorderModal v-if="reorderModalShow" v-model="reorderModalShow" :list="allowReorderList" @getList="getStockBalList" />
    <CommonModal
      v-if="confirmModal"
      v-model="confirmModal"
      :modal-size="'modal-xs'"
      :title="confirmModalTitle"
      @close="resetFields"
    >
      <template #body-content>
        <ValidationObserver ref="observer">
          <div class="my-3">
            <label class="font-14">
              {{ $t('lbl.reason') }}
              <span class="font-red">{{ rules.reason.required ? '*' : '' }}</span>
            </label>
            <TextField v-model="form.reason" :rules="rules.reason" :label="$t('lbl.reason')" />
          </div>
        </ValidationObserver>
      </template>
      <template #footer-content>
        <div class="text-right">
          <AppBtn class="btn-black" :height="42" :loading="loading" @click="confirm">{{ $t('act.confirm') }}</AppBtn>
        </div>
      </template>
    </CommonModal>
    <SuccessModal v-model="successModalShow" :msg="successModalMsg" />
    <StockAdjModal
      v-if="stockAdjModalShow"
      v-model="stockAdjModalShow"
      :data="stockAdjData"
      @close="closeStockAdjModal"
      @success="stockAdjSuccess"
    />
  </div>
</template>
<script>
import { stockApi, stockItemApi } from '@/api/stock';
import CommonModal from '@/components/Modal';
import DataTable from '@/components/Table/DataTable';
import ReorderModal from './StockReorderModal';
import StockAdjModal from '../Adjustment/components/AdjModal';
export default {
  name: 'StockLevel',
  components: {
    CommonModal,
    DataTable,
    ReorderModal,
    StockAdjModal,
  },
  data() {
    return {
      list: [],
      allowReorderList: [],
      loading: false,
      pagination: {
        page: 1,
        pageSize: 100,
        total: 0,
      },
      reorderModalShow: false,
      statusEnum: {
        ACTIVE: 1,
        OUT_OF_STOCK: 1,
      },
      form: {
        itemId: null,
        reason: '',
      },
      rules: {
        reason: { required: false },
      },
      actionTypeEnum: {
        STOP: 1,
        RESUME: 2,
      },
      actionType: null,
      itemId: null,
      confirmModalTitle: '',
      confirmModal: false,
      successModalShow: false,
      successModalMsg: '',

      stockAdjModalShow: false,
      stockAdjData: {},
    };
  },
  computed: {
    headers() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return [
          { width: '32%', text: this.$t('stocks.lbl.item'), value: 'item' },
          { width: '13%', text: '', value: 'balCustom' },
          { width: '9%', text: this.$t('stocks.lbl.bal'), value: 'bal' },
          { width: '9%', text: this.$t('stocks.lbl.ordered'), value: 'ordered' },
          { width: '9%', text: this.$t('stocks.lbl.total'), value: 'total' },
          { width: '15%', text: this.$t('stocks.lbl.reorderPar'), value: 'par' },
          { width: '13%', text: this.$t('stocks.lbl.lowPar'), value: 'low' },
          { width: '15%', text: '', value: 'action' },
        ];
      } else if (this.$vuetify.breakpoint.sm) {
        return [
          { width: '32%', text: this.$t('stocks.lbl.item'), value: 'item' },
          { width: '13%', text: '', value: 'balCustom' },
          { width: '9%', text: this.$t('stocks.lbl.bal'), value: 'bal' },
          { width: '9%', text: this.$t('stocks.lbl.ordered'), value: 'ordered' },
          { width: '9%', text: this.$t('stocks.lbl.total'), value: 'total' },
          { width: '15%', text: this.$t('stocks.lbl.reorderPar'), value: 'par' },
          { width: '13%', text: this.$t('stocks.lbl.lowPar'), value: 'low' },
          { width: '15%', text: '', value: 'action' },
        ];
      } else {
        return [
          { width: '180px', text: this.$t('stocks.lbl.item'), value: 'item' },
          { width: '90px', text: '', value: 'balCustom' },
          { width: '80px', text: this.$t('stocks.lbl.bal'), value: 'bal' },
          { width: '80px', text: this.$t('stocks.lbl.ordered'), value: 'ordered' },
          { width: '70px', text: this.$t('stocks.lbl.total'), value: 'total' },
          { width: '110px', text: this.$t('stocks.lbl.reorderPar'), value: 'par' },
          { width: '80px', text: this.$t('stocks.lbl.lowPar'), value: 'low' },
          { width: '80px', text: '', value: 'action' },
        ];
      }
    },
  },
  created() {
    this.getStockBalList();
    this.getAllowReorderList();
  },
  methods: {
    // getListNTotalRec() {
    //   this.getTotalRec().then(() => {
    //     this.getStockBalList();
    //   });
    // },
    // getTotalRec() {
    //   const data = {
    //     page: 0
    //   };
    //   return stockApi.list(data).then(res => {
    //     this.pagination.total = res.data.total;
    //   });
    // },
    getStockBalList() {
      const data = {
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
      };
      return stockApi.list(data).then(res => {
        this.list = res.data;
      });
    },
    getAllowReorderList() {
      const params = {
        page: 1,
        pageSize: 50,
      };
      return stockItemApi.allowReorderList(params).then(res => {
        this.allowReorderList = res.data;
      });
    },
    showReorderModal() {
      this.reorderModalShow = true;
    },
    showConfirmModal(type, data) {
      this.actionType = type;
      this.itemId = data.itemId;
      this.confirmModalTitle = this.actionType == this.actionTypeEnum.STOP ? 'Stop selling item' : 'Resume selling item';
      this.$nextTick(() => {
        this.confirmModal = true;
      });
    },
    confirm() {
      if (this.actionType == this.actionTypeEnum.STOP) {
        this.stop({ itemId: this.itemId, reason: this.form.reason });
      } else if (this.actionType == this.actionTypeEnum.RESUME) {
        this.resume({ itemId: this.itemId, reason: this.form.reason });
      }
    },
    stop(data) {
      this.loading = true;
      return stockItemApi
        .stop(data)
        .then(res => {
          this.loading = false;
          if (res.ret == '0') {
            this.confirmModal = false;
            this.successModalShow = true;
            this.successModalMsg = 'Item stopped for selling';
            let currentItem = this.list.find(item => item.itemId == this.itemId);
            currentItem.status = 0;
            setTimeout(() => {
              this.resetFields();
              this.successModalShow = false;
            }, 500);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    resume(data) {
      this.loading = true;
      return stockItemApi
        .resume(data)
        .then(res => {
          this.loading = false;
          if (res.ret == '0') {
            this.confirmModal = false;
            this.successModalShow = true;
            this.successModalMsg = 'Item resumed for selling';
            let currentItem = this.list.find(item => item.itemId == this.itemId);
            currentItem.status = 1;
            setTimeout(() => {
              this.resetFields();
              this.successModalShow = false;
            }, 500);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    showStockAdjModal(item) {
      this.stockAdjData = item;
      this.$nextTick(() => {
        this.stockAdjModalShow = true;
      });
    },
    stockAdjSuccess(data) {
      const itemIndex = this.list.findIndex(item => item.itemId == data.itemId);
      const itemObj = this.list[itemIndex];
      const bal = itemObj.bal + data.qty;

      this.$set(this.list, itemIndex, {
        ...this.list[itemIndex],
        bal,
        uomQty: bal / itemObj.serving,
      });

      this.closeStockAdjModal();
    },
    closeStockAdjModal() {
      this.stockAdjData = {};
      this.stockAdjModalShow = false;
    },
    resetFields() {
      this.form.itemId = null;
      this.form.reason = '';
      this.actionType = null;
      this.itemId = null;
      this.confirmModalTitle = '';
      this.confirmModal = false;
      this.successModalMsg = '';
    },
    rowClassFunc(item) {
      if (item.bal <= item.low) {
        return 'bg-red-lighten-3';
      } else if (item.bal <= item.par) {
        return 'bg-yellow-lighten-3';
      }
    },
  },
};
</script>
