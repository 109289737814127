const ERRORS = {
  NOT_CONNECTED: 1,
};

const ERROR_MSG = {
  NOT_CONNECTED: 'Not Connected',
  INVALID_EVENT: 'Invalid event',
  INVALID_CALLBACK: 'Invalid callback',
  INVALID_URL: 'Invalid URL',
  INVALID_EMIT_PARAM: 'Invalid emit param',
};

export { ERRORS, ERROR_MSG };
