<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6">
        <div class="menu-title mb-2">{{ $t('menu.lbl.setMenu') }}</div>
        <div class="menu-box-wrap">
          <MenuBox
            v-for="(menu, index) in menuListGrp1"
            :key="index"
            class="px-2 py-2"
            :data="menu"
            @clicked="setClicked(menu)"
          />
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="menu-title mb-2">{{ $t('menu.lbl.aLaCarte') }}</div>
        <div class="menu-box-wrap">
          <MenuBox
            v-for="(menu, index) in menuListGrpALaCarteNBev"
            :key="index"
            class="px-2 py-2"
            :data="menu"
            @clicked="setClicked(menu)"
          />
        </div>
      </v-col>
    </v-row>

    <AddItemOptModal
      v-if="modalShow"
      v-model="modalShow"
      :data="menuData"
      :pre-selected-data="preSelectedData"
      @success="success"
    />

    <ComboSetModalWrap v-if="comboModalWrapShow" v-model="comboModalWrapShow" :data="menuData" @comboClick="comboClick" />

    <ComboSetModal v-if="comboModalShow" v-model="comboModalShow" :data="menuData" @success="comboSetSuccess" />

    <ALaCarteModal v-if="aLaCarteModalShow" v-model="aLaCarteModalShow" :data="menuData" @success="aLaCarteSuccess" />

    <BeverageModal v-if="beverageModalShow" v-model="beverageModalShow" :data="menuData" @success="beverageSuccess" />

    <PromoSetModal v-if="promoModalShow" v-model="promoModalShow" :data="menuData" @promoClick="promoClick" />

    <CommonModal v-model="successModal" :modal-size="'modal-xs'" :header="false" :footer="false">
      <template #body>
        <div class="modal-body d-flex align-center">
          <v-icon class="mr-2" color="var(--primary-color)" size="60">mdi-check-circle-outline</v-icon>
          <div class="modal-body__text">{{ $t('cart.success.add') }}</div>
        </div>
      </template>
    </CommonModal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { MENU_GRP } from '@/common/enum';
import CommonModal from '@/components/Modal';
import MenuBox from './MenuBox';
import AddItemOptModal from './components/AddItemOptModal';
import ComboSetModalWrap from './components/ComboSetModalWrap';
import ComboSetModal from './components/ComboSetModal';
import ALaCarteModal from './components/ALaCarteModal';
import BeverageModal from './components/BeverageModal';
import PromoSetModal from './components/PromoSetModal';

export default {
  name: 'MenuAll',
  components: {
    CommonModal,
    MenuBox,
    AddItemOptModal,
    ComboSetModalWrap,
    ComboSetModal,
    ALaCarteModal,
    BeverageModal,
    PromoSetModal,
  },
  data() {
    return {
      menuData: {},
      preSelectedData: {},

      modalShow: false,
      comboModalWrapShow: false,
      comboModalShow: false,
      aLaCarteModalShow: false,
      beverageModalShow: false,
      promoModalShow: false,

      successModal: false,
    };
  },
  computed: {
    ...mapState({
      menuList: state => state.menu.menuList,
      menuItemList: state => state.menu.menuItemList,
      menuItemOptList: state => state.menu.menuItemOptList,
      menuItemOptGrpList: state => state.menu.menuItemOptGrpList,
      menuItemOptGrpSubList: state => state.menu.menuItemOptGrpSubList,
    }),
    menuListGrp1() {
      return this.menuList.filter(
        item =>
          item.grp == MENU_GRP.SET ||
          item.grp == MENU_GRP.COMBO ||
          item.grp == MENU_GRP.PROMOTION ||
          item.grp == MENU_GRP.FIXED_PRICE ||
          item.grp == MENU_GRP.SPECIAL
      );
    },
    menuListGrpALaCarteNBev() {
      return this.menuList.filter(item => item.grp == MENU_GRP.ALACARTE || item.id == 21 /* Beverage */);
    },
  },
  watch: {
    modalShow(value) {
      if (value == false) {
        this.menuData = {};
        this.preSelectedData = {};
      }
    },
    comboModalShow(value) {
      if (value == false) {
        this.menuData = {};
      }
    },
    promoModalShow(value) {
      if (value == false) {
        this.menuData = {};
      }
    },
    aLaCarteModalShow(value) {
      if (value == false) {
        this.menuData = {};
      }
    },
    beverageModalShow(value) {
      if (value == false) {
        this.menuData = {};
      }
    },
  },
  methods: {
    ...mapActions(['addToCart', 'removeFromCart', 'clearCart']),
    setClicked(menu) {
      switch (menu.grp) {
        case MENU_GRP.SET:
        case MENU_GRP.FIXED_PRICE:
          this.menuData = menu;
          this.$nextTick(() => {
            this.modalShow = true;
          });
          break;
        case MENU_GRP.COMBO:
          this.menuData = menu;
          this.$nextTick(() => {
            this.comboModalWrapShow = true;
          });
          break;
        case MENU_GRP.ALACARTE:
          if (menu.id == 21 /* Beverage */) {
            this.menuData = menu;
            this.$nextTick(() => {
              this.beverageModalShow = true;
            });
            break;
          } else {
            this.menuData = menu;
            this.$nextTick(() => {
              this.aLaCarteModalShow = true;
            });
            break;
          }
        case MENU_GRP.PROMOTION:
          this.menuData = menu;
          this.$nextTick(() => {
            this.promoModalShow = true;
          });
          break;
        default:
          break;
      }
    },
    // aLaCarteClicked(item) {
    //   const data = {
    //     qty: 1,
    //     price: item.price,
    //     label: item.label,
    //     images: item.images,
    //     itemId: item.itemId,
    //   };
    //   this.addToCart(data);
    //   this.successModal = true;
    //   setTimeout(() => {
    //     this.successModal = false;
    //   }, 500);
    // },
    success() {
      this.modalShow = false;
      this.successModal = true;
      setTimeout(() => {
        this.successModal = false;
      }, 500);
    },
    comboSetSuccess() {
      this.comboModalShow = false;
      this.successModal = true;
      setTimeout(() => {
        this.successModal = false;
      }, 500);
    },
    aLaCarteSuccess() {
      this.aLaCarteModalShow = false;
      this.successModal = true;
      setTimeout(() => {
        this.successModal = false;
      }, 500);
    },
    beverageSuccess() {
      this.beverageModalShow = false;
      this.successModal = true;
      setTimeout(() => {
        this.successModal = false;
      }, 500);
    },
    comboClick(item) {
      this.comboModalWrapShow = false;
      this.$nextTick(() => {
        this.menuData = item;
        this.$nextTick(() => {
          this.comboModalShow = true;
        });
      });
    },
    promoClick(item) {
      this.promoModalShow = false;
      this.$nextTick(() => {
        this.preSelectedData = item;
        const menuData = this.menuList.find(menu => menu.id == item.setId);
        this.menuData = menuData;
        this.$nextTick(() => {
          this.modalShow = true;
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../styles/mixin.scss';
.menu-title {
  font-size: 1.3rem;
  font-weight: 500;
}
.menu-desc {
  font-size: 0.9rem;
}
.modal-body {
  background-color: #231f20;
  height: 100px;

  &__text {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 600;
  }
}
.menu-box-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.7rem;
  row-gap: 0.7rem;

  @include for-lg-min {
    grid-template-columns: repeat(4, 1fr);
  }

  @include for-sm {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
