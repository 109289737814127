<template>
  <div class="page-wrap">
    <v-row class="mx-0">
      <v-col cols="12" sm="3" class="py-1 py-sm-3">
        <AppBtn
          class="tab-btn w-100"
          :class="active == TAB.COMPLETED ? 'btn-black' : 'btn-orange-border'"
          :height="50"
          @click="active = TAB.COMPLETED"
        >
          {{ $t('orders.tab.completedOrders') }}
        </AppBtn>
      </v-col>
    </v-row>
    <v-row class="mx-0">
      <v-col v-if="active == TAB.COMPLETED" cols="12">
        <CompletedOrders />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CompletedOrders from './components/CompletedOrders';

export default {
  name: 'OrderHistory',
  components: {
    CompletedOrders,
  },
  data() {
    return {
      loading: false,
      TAB: {
        COMPLETED: 1,
      },
      active: 1,
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.page-wrap {
  // overflow-y: scroll;
}
.tab-btn {
  text-transform: uppercase;
}
</style>
