import AppStorage from '@/plugins/storage/index.js';
import { isUndefinedNullOrEmpty } from '@/util/tools.js';

const store = {
  state: {
    locId: null,
    storeLocSet: AppStorage.local.get('storeLocSet') || 0,
    storeLocModalShow: false,
  },
  getters: {
    getLocId: state => {
      return state.locId ? state.locId : AppStorage.local.get('locId');
    },
    getStoreLocSet: state => {
      return state.storeLocSet;
    },
  },
  mutations: {
    SET_STORE_LOC_MODAL_SHOW(state, payload) {
      state.storeLocModalShow = payload;
    },
    SET_LOC_ID(state, payload) {
      state.locId = payload;
      !isUndefinedNullOrEmpty(payload) ? AppStorage.local.set('locId', payload) : AppStorage.local.remove('locId');
    },
    SET_STORE_LOC_FLAG(state, payload) {
      state.storeLocSet = payload;
      payload ? AppStorage.local.set('storeLocSet', payload) : AppStorage.local.remove('storeLocSet');
    },
  },
};
export default store;
