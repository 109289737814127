export const STOCK_ORDER_STATUS = {
  NEW: 0,
  CONFIRMED: 1,
  APPROVED: 2,
  DELETED: 3,
  CANCELLED: 4,
  PICKED_UP: 8,
  COMPLETED: 9,
};

export const STOCK_ORDER_TYPE = {
  AUTO: 1,
  MANUAL: 2,
  STORE_HQ: 3,
  STORE: 4,
};

export const STOCK_ADJ_MODE = {
  STOCKTAKE: 0,
  STOCK_ADJ: 1,
};

export const STOCK_ADJ_TYPE_DR = {
  ADD_BACK: 0,
  DEDUCT: 1,
};
