<template>
  <div>
    <CommonModal v-model="show" :modal-size="'modal-lg'">
      <template #header>
        <div class="modal-header" style="position:sticky; top:0">
          <div class="d-flex justify-space-between align-center">
            <div class="modal-header__title">{{ data.name }}</div>
            <v-icon color="#fff" @click="close">mdi-close</v-icon>
          </div>
        </div>
      </template>
      <template #body-content>
        <div class="bao-grp-wrap">
          <div
            v-for="(menu, index) in comboItemList"
            :key="index"
            class="item-wrap d-flex flex-column justify-space-between text-center pa-2"
            @click="clicked(menu)"
          >
            <div class="mb-2 h-100 d-flex justify-center">
              <img class="item-img" :src="getItemImg(menu.images[0].url)" alt="item" />
            </div>
            <div class="item-label mb-1">{{ menu.name }}</div>
            <div class="item-price font-weight-bold">{{ `${$t('lbl.rm')}${priceFormat(menu.price)}` }}</div>
            <!-- <template v-if="!isFixedPriceSet">
              <div v-if="!isUndefinedNullOrEmpty(bao.promoPrice)" class="d-flex flex-column align-center justify-center">
                <span class="item-price font-weight-bold">{{
                  `${$t('lbl.rm')}${priceFormat(bao.promoPrice)}`
                }}</span>
                <span class="line-through black--text">{{ `${$t('lbl.rm')}${priceFormat(baoGrp.price)}` }}</span>
              </div>
              <div v-else class="item-price font-weight-bold">{{ `${$t('lbl.rm')}${priceFormat(baoGrp.price)}` }}</div>
            </template> -->
          </div>
        </div>
      </template>
    </CommonModal>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import CommonModal from '@/components/Modal';
import { MENU_GRP } from '@/common/enum';

export default {
  name: 'ComboSetModal',
  components: {
    CommonModal,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultImg: require('@/assets/logo.svg'),
      comboItemList: [],
    };
  },
  computed: {
    ...mapState({
      menuList: state => state.menu.menuList,
      menuItemList: state => state.menu.menuItemList,
      menuItemOptList: state => state.menu.menuItemOptList,
      menuItemOptGrpList: state => state.menu.menuItemOptGrpList,
      menuItemOptGrpSubList: state => state.menu.menuItemOptGrpSubList,
    }),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  created() {
    this.comboItemList = this.menuItemList.filter(item => item.menuId == this.data.id);
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    clicked(menu) {
      this.$emit('comboClick', menu);
    },
    getItemImg(imgUrl) {
      if (this.isUndefinedNullOrEmpty(imgUrl)) {
        return this.defaultImg;
      }
      return imgUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../../styles/mixin.scss';
.modal-header {
  background-color: #000;
  &__title {
    color: #fff !important;
    font-size: 1.25rem;
    font-weight: 600;
  }
}
.bao-grp-wrap {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 0.75rem;
  row-gap: 0.3rem;

  @include for-sm {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
}
.item-wrap {
  border: 2px solid #000;
  border-radius: 5px;
}

.item-img {
  max-width: 85px;
  width: 100%;
  @include for-sm {
    max-width: 85px;
  }
}
.item-label {
  color: var(--primary-black-color);
  font-size: 0.9rem;
  font-weight: 500;
}
.item-price {
  color: var(--red-common);
  font-size: 0.9rem;
  font-weight: 500;
}
</style>
