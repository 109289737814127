import AppStorage from '@/plugins/storage/index.js';
import { isUndefinedNullOrEmpty } from '@/util/tools.js';

const order = {
  state: {
    config: {
      completedOrderLimit: 10,
    },
    completedOrderList: [],
    lastOrderId: null,
  },
  getters: {
    getCompletedOrderList: state => {
      if (!isUndefinedNullOrEmpty(state.completedOrderList)) return state.completedOrderList;
      else if (!isUndefinedNullOrEmpty(AppStorage.local.get('compOrd'))) {
        return JSON.parse(AppStorage.local.get('compOrd'));
      } else return [];
    },
  },
  mutations: {
    SET_COMPLETED_ORDER_LIST(state, payload) {
      state.completedOrderList = payload;
      AppStorage.local.set('compOrd', JSON.stringify(payload));
    },
    SET_LAST_ORDER_ID(state, payload) {
      state.lastOrderId = payload;
    },
  },
  actions: {
    appendCompletedOrderList({ commit, state, getters }, data) {
      let completedOrderList = getters.getCompletedOrderList;
      if (completedOrderList?.length >= state.config.completedOrderLimit) {
        completedOrderList.pop();
      }
      completedOrderList.unshift(data);
      commit('SET_COMPLETED_ORDER_LIST', completedOrderList);
    },
  },
};
export default order;
