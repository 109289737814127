<template>
  <div>
    <default-bar />

    <default-drawer />

    <default-view />

    <default-footer />

    <!-- <default-settings /> -->

    <!-- <SuccessModal v-model="modalShow" :msg="modalShowMsg" /> -->

    <CommonModal v-model="modalShow" :modal-size="'modal-sm-2'" :footer="true">
      <template #header>
        <div class="modal-header">
          <slot name="header-content">
            <div class="d-flex justify-space-between align-center">
              <div class="modal-header__title">{{ 'Notification' }}</div>
              <v-icon color="#fff" @click="close">mdi-close</v-icon>
            </div>
          </slot>
        </div>
      </template>
      <template #body-content>
        <div class="body-wrap text-center py-6">
          <div class="font-1dot5r font-weight-regular">
            <span class="font-red-common">*</span> {{ modalShowMsg }} <span class="font-red-common">*</span>
          </div>
        </div>
      </template>
      <template #footer-content>
        <div class="text-right">
          <AppBtn class="btn-orange" :height="44" @click="navigateCB">{{ modalBtnText }}</AppBtn>
        </div>
      </template>
    </CommonModal>
    <StoreLocModal v-if="compStoreLocModalShow" v-model="compStoreLocModalShow" />
    <audio id="audio" src="./beep.wav"></audio>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { EVENT_ORDER } from '@/module/notification/enum';
import { processEvtData, processEvtPayload } from '@/module/notification';
import { ORDER_TAB } from '@/common/orderEnum';
import CommonModal from '@/components/Modal';
import StoreLocModal from '@/views/Store/Location/DetailModal';
export default {
  name: 'DefaultLayout',
  components: {
    DefaultBar: () =>
      import(
        /* webpackChunkName: "default-app-bar" */
        './AppBar'
      ),
    DefaultDrawer: () =>
      import(
        /* webpackChunkName: "default-drawer" */
        './Drawer'
      ),
    DefaultFooter: () =>
      import(
        /* webpackChunkName: "default-footer" */
        './Footer'
      ),
    // DefaultSettings: () =>
    //   import(
    //     /* webpackChunkName: "default-settings" */
    //     './Settings'
    //   ),
    DefaultView: () =>
      import(
        /* webpackChunkName: "default-view" */
        './View'
      ),
    CommonModal,
    StoreLocModal,
  },
  data() {
    return {
      modalShow: false,
      modalShowMsg: 'You have a new order',
      modalBtnText: 'Go',
      navigateCB: null,
    };
  },
  computed: {
    ...mapState({
      lastOrderId: state => state.order.lastOrderId,
      storeLocModalShow: state => state.store.storeLocModalShow,
    }),
    ...mapGetters(['getToken', 'getStoreLocSet']),
    compStoreLocModalShow: {
      get() {
        return this.storeLocModalShow;
      },
      set(value) {
        this.SET_STORE_LOC_MODAL_SHOW(value);
      },
    },
  },
  beforeDestroy() {
    // this.$socket.unsubscribe(EVENT_ORDER.OrderCreated);
    this.$socket.unsubscribe(EVENT_ORDER.OrderConfirmed);
  },
  created() {
    if (this.getStoreLocSet != 1) this.SET_STORE_LOC_MODAL_SHOW(true);
    this.emitAuthorize();
    this.reconnect();
  },
  methods: {
    ...mapMutations(['SET_LAST_ORDER_ID', 'SET_STORE_LOC_MODAL_SHOW']),
    emitAuthorize() {
      if (!this.isUndefinedNullOrEmpty(this.getToken)) {
        let token = this.getToken;
        const params = {
          seq: 1, // Just hardcode, no meaning for now
          payload: {
            token,
          },
        };
        this.$socket.emit('auth', params, () => {
          // this.subOrderCreated();
          this.subOrderConfirmed();
        });
      }
    },
    reconnect() {
      this.$socket.reconnect(() => {
        this.emitAuthorize();
        console.log('Emit auth after reconnect');
      });
    },
    subOrderConfirmed() {
      this.$socket.subscribe(EVENT_ORDER.OrderConfirmed.hash, data => {
        const { event, values } = processEvtData(data);
        const payload = processEvtPayload(EVENT_ORDER.OrderConfirmed.keys, values);

        if (!this.modalShow) {
          if (!this.lastOrderId || this.lastOrderId != values[1]) {
            this.modalShowMsg = 'You have a new order in Order Queue';
            this.modalBtnText = this.$t('lbl.go');

            if (this.$route.name == 'Orders') {
              const currentTab = this.$route.query.tab;
              if (currentTab == ORDER_TAB.ORDER_QUEUE) {
                this.modalBtnText = this.$t('lbl.ok');
              }
            }

            this.navigateCB = () => {
              this.$router.push({ name: 'Orders', query: { tab: ORDER_TAB.ORDER_QUEUE } }).catch(() => {});

              this.modalShow = false;
              this.modalShowMsg = '';
              this.modalBtnText = '';
            };

            this.modalShow = true;

            const audio = document.getElementById('audio');
            audio.play();
          }
          this.SET_LAST_ORDER_ID(null);
        }
      });
    },
    close() {
      this.modalShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-header {
  background-color: #ff0000 !important;
}
</style>
