<template>
  <div class="page-wrap">
    <v-row class="mx-0">
      <v-col cols="12" sm="3" class="py-1 py-sm-3">
        <AppBtn
          class="tab-btn w-100"
          :class="active == TAB.PAY_QUEUE ? 'btn-black' : 'btn-orange-border'"
          :height="50"
          @click="switchTab(TAB.PAY_QUEUE)"
        >
          {{ $t('orders.tab.payQueue') }}
          <Badge v-if="dashStats.orders.queue > 0" class="ml-2" :count="dashStats.orders.queue" :bg-color="'var(--badge-red)'" />
        </AppBtn>
      </v-col>
      <v-col cols="12" sm="3" class="py-1 py-sm-3">
        <AppBtn
          class="tab-btn w-100"
          :class="active == TAB.ORDER_QUEUE ? 'btn-black' : 'btn-orange-border'"
          :height="50"
          @click="switchTab(TAB.ORDER_QUEUE)"
        >
          {{ $t('orders.tab.orderQueue') }}
          <Badge v-if="dashStats.orders.todo > 0" class="ml-2" :count="dashStats.orders.todo" :bg-color="'var(--badge-blue)'" />
        </AppBtn>
      </v-col>
      <v-col cols="12" sm="3" class="py-1 py-sm-3">
        <AppBtn
          class="tab-btn w-100"
          :class="active == TAB.PREPARE_LIST ? 'btn-black' : 'btn-orange-border'"
          :height="50"
          @click="switchTab(TAB.PREPARE_LIST)"
          >{{ $t('orders.tab.prepareList') }}</AppBtn
        >
      </v-col>
      <v-col cols="12" sm="3" class="py-1 py-sm-3">
        <AppBtn
          class="tab-btn w-100"
          :class="active == TAB.COLLECT_QUEUE ? 'btn-black' : 'btn-orange-border'"
          :height="50"
          @click="switchTab(TAB.COLLECT_QUEUE)"
        >
          {{ $t('orders.tab.collectQueue') }}
          <Badge
            v-if="dashStats.orders.ready > 0"
            class="ml-2"
            :count="dashStats.orders.ready"
            :bg-color="'var(--badge-green)'"
          />
        </AppBtn>
      </v-col>
    </v-row>
    <v-row class="mx-0">
      <v-col v-if="active == TAB.PAY_QUEUE" cols="12">
        <PayQueue />
      </v-col>
      <v-col v-if="active == TAB.ORDER_QUEUE" cols="12">
        <QueueList />
      </v-col>
      <v-col v-if="active == TAB.PREPARE_LIST" cols="12">
        <PrepareList />
      </v-col>
      <v-col v-if="active == TAB.COLLECT_QUEUE" cols="12">
        <CollectQueue />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import CollectQueue from './components/CollectQueue';
import PayQueue from './components/PayQueue';
import PrepareList from './components/PrepareList';
import QueueList from './components/QueueList';
import { dashApi } from '@/api/dash';
import Badge from '@/components/Badge';

export default {
  name: 'Orders',
  components: {
    Badge,
    CollectQueue,
    PayQueue,
    PrepareList,
    QueueList,
  },
  data() {
    return {
      loading: false,
      TAB: {
        PAY_QUEUE: 1,
        ORDER_QUEUE: 2,
        PREPARE_LIST: 3,
        COLLECT_QUEUE: 4,
      },
      active: 1,
    };
  },
  computed: {
    ...mapState({
      dashStats: state => state.app.dashStats,
    }),
  },
  watch: {
    $route(to) {
      this.active = to.query.tab;
    },
  },
  created() {
    this.getDashboardStats();
    const currentTab = this.$route.query?.tab;
    if (currentTab) {
      const tabList = [this.TAB.PAY_QUEUE, this.TAB.ORDER_QUEUE, this.TAB.PREPARE_LIST, this.TAB.COLLECT_QUEUE];
      if (tabList.indexOf(Number(currentTab)) != -1) {
        this.active = currentTab;
      }
    }
  },
  methods: {
    ...mapMutations(['SET_DASH_STATS']),
    getDashboardStats() {
      return dashApi.dashboardStats().then(res => {
        this.SET_DASH_STATS(res.data);
      });
    },
    switchTab(tab) {
      this.active = tab;
      this.$router.replace({ name: 'Orders', query: { tab } }).catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  // overflow-y: scroll;
}
.tab-btn {
  text-transform: uppercase;
}
</style>
