<template>
  <!-- <v-badge
    color="green"
    :content="badgeCount"
    overlap
    offset-x="20"
    offset-y="43"
    :value="displayBadge"
  > -->
  <div
    class="bar-item-wrap cursor-pointer d-flex justify-center justify-sm-space-between align-center"
    @click="$emit('clicked', itemKey)"
  >
    <div v-if="isMobileView" class="badge-figure-wrap">
      <slot name="badge-mobile"></slot>
    </div>
    <div class="d-flex flex-column align-center">
      <img class="bar-item-icon mb-0 mb-sm-1" :src="icon" alt="orders" />
      <div class="bar-item-icon__text">{{ label }}</div>
    </div>
    <div v-if="!isMobileView" class="bar-item-icon-wrap">
      <!-- <div class=""> -->
      <slot name="badge">
        <!-- <span class="bar-item-icon__figure mr-1">{{badgeCount}}</span>
            <span class="bar-item-icon__figure mr-1">{{badgeCount}}</span>
            <span class="bar-item-icon__figure mr-1">{{badgeCount}}</span> -->
      </slot>
      <!-- </div> -->
      <!-- <div class="bar-item-icon__figure">{{ badgeCount }}</div>
        <div class="bar-item-icon__figure">{{ badgeCount }}</div> -->
    </div>
  </div>
  <!-- </v-badge> -->
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    badgeCount: {
      type: Number,
      default: 0,
    },
    itemKey: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    showBadge: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    displayBadge() {
      return this.showBadge && this.badgeCount != 0;
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin for-sm {
  @media (max-width: 599px) {
    @content;
  }
}

.bar-item-icon-wrap {
  display: inline-flex;
}

.bar-item-icon {
  height: 35px;
  width: 35px;

  @include for-sm {
    height: 30px;
    width: 30px;
  }

  &__text {
    font-size: 18px;
    font-weight: 600;
  }
}
.bar-item-wrap {
  position: relative;
  padding: 10px 15px;
}

.badge-figure-wrap {
  position: absolute;
  right: -5px;
  top: 5px;
  z-index: 1;
}
.badge-figure {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1px;
  font-size: 12px;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  // background-color: var(--red);
}
.bar-item-wrap::before {
  content: '';
  border-top: 4px solid #000;
  border-left: 4px solid #000;
  border-right: 4px solid #000;
  position: absolute;
  top: 0;
  bottom: 55px;
  left: 0;
  right: 0;
  @include for-sm {
    bottom: 50px;
  }
}
.bar-item-wrap::after {
  content: '';
  border-bottom: 4px solid #000;
  border-left: 4px solid #000;
  border-right: 4px solid #000;
  position: absolute;
  top: 55px;
  bottom: 0;
  left: 0;
  right: 0;
  @include for-sm {
    top: 50px;
  }
}
</style>
