import Api from '../api';

export const lookupApi = {
  payMethodList(params) {
    return Api.get('lookup?category=PosPayMethod', params);
  },
  salesChannelList() {
    return Api.get('lookup?category=SalesChannel');
  },
  getScheduleList({ grp = null }) {
    const params = {
      category: 'DeliveryTime',
      grp,
    };
    return Api.get('lookup', params);
  },
  deliveryChannelList() {
    return Api.get('lookup?category=DeliveryChannel');
  },
  provinceList() {
    return Api.get('lookup?category=Province&country=MY');
  },
  stockAdjTypeList() {
    return Api.get('lookup?category=StockAdj');
  },
};
