import i18n from '@/translations';
import { MENU_TYPE } from '@/common/enum.js';
import { isUndefinedNullOrEmpty } from '@/util/tools';
import AppStorage from '@/plugins/storage/index.js';
import { orderApi } from '@/api/order';
import { ORDER_STATUS } from '@/common/orderEnum';

const app = {
  state: {
    drawer: false,
    drawerImage: false,
    mini: true,
    items: [
      {
        type: MENU_TYPE.FIRST_LAYER,
        title: i18n.t('bar.lbl.orderHistory'),
        icon: 'mdi-clipboard-text-multiple-outline',
        to: '/order-history',
      },
      {
        type: MENU_TYPE.FIRST_LAYER,
        title: i18n.t('bar.lbl.deliveryOrder'),
        icon: 'mdi-truck-delivery-outline',
        to: '/delivery-order',
      },
      // {
      //   type: MENU_TYPE.FIRST_LAYER,
      //   title: i18n.t('bar.lbl.lockdown'),
      //   icon: require('@/assets/bar/lockdown.svg'),
      //   to: '/dashboard/temp1',
      // },
      // {
      //   type: MENU_TYPE.FIRST_LAYER,
      //   title: i18n.t('bar.lbl.logout'),
      //   icon: require('@/assets/bar/logout.svg'),
      //   to: '/dashboard/temp2',
      // },
      // {
      //   type: MENU_TYPE.FIRST_LAYER,
      //   title: i18n.t('menu.lbl.procurement'),
      //   icon: 'mdi-cart',
      //   to: '/',
      //   items: [
      //     // {
      //     //   type: MENU_TYPE.SUB_SECTION,
      //     //   title: i18n.t('menu.lbl.purchaseOrder'),
      //     //   icon: 'fa-circle',
      //     // },
      //     {
      //       type: MENU_TYPE.LOWEST_LAYER,
      //       title: i18n.t('menu.lbl.purchaseOrderList'),
      //       icon: 'fa-dot-circle-o',
      //       to: '/purchase-order/list',
      //     },
      //     // {
      //     //   type: MENU_TYPE.SUB_SECTION,
      //     //   title: i18n.t('menu.lbl.goodsReceive'),
      //     //   icon: 'fa-circle',
      //     // },
      //     {
      //       type: MENU_TYPE.LOWEST_LAYER,
      //       title: i18n.t('menu.lbl.grnList'),
      //       icon: 'fa-dot-circle-o',
      //       to: '/grn/list',
      //     },
      //   ],
      // },
      // {
      //   type: MENU_TYPE.FIRST_LAYER,
      //   title: i18n.t('menu.lbl.production'),
      //   icon: 'mdi-briefcase-variant-outline',
      //   to: '/',
      //   items: [
      //     // {
      //     //   type: MENU_TYPE.SUB_SECTION,
      //     //   title: i18n.t('menu.lbl.production'),
      //     //   icon: 'fa-circle',
      //     // },
      //     {
      //       type: MENU_TYPE.LOWEST_LAYER,
      //       title: i18n.t('menu.lbl.productionDashboard'),
      //       icon: 'fa-dot-circle-o',
      //       to: '/job/dashboard',
      //     },
      //     {
      //       type: MENU_TYPE.LOWEST_LAYER,
      //       title: i18n.t('menu.lbl.productionListing'),
      //       icon: 'fa-dot-circle-o',
      //       to: '/job/list',
      //     },
      //   ],
      // },
      // {
      //   type: MENU_TYPE.FIRST_LAYER,
      //   title: i18n.t('menu.lbl.inventory'),
      //   icon: 'mdi-cube-outline',
      //   to: '/',
      //   items: [
      //     // {
      //     //   type: MENU_TYPE.SUB_SECTION,
      //     //   title: i18n.t('menu.lbl.rawMat'),
      //     //   icon: 'fa-circle',
      //     // },
      //     {
      //       type: MENU_TYPE.LOWEST_LAYER,
      //       title: i18n.t('menu.lbl.rawMatInventory'),
      //       icon: 'fa-dot-circle-o',
      //       to: '/inventory/raw-materials/list',
      //     },
      //     {
      //       type: MENU_TYPE.LOWEST_LAYER,
      //       title: i18n.t('menu.lbl.semiFGoodsInventory'),
      //       icon: 'fa-dot-circle-o',
      //       to: '/inventory/semi-fgs/list',
      //     },
      //     {
      //       type: MENU_TYPE.LOWEST_LAYER,
      //       title: i18n.t('menu.lbl.fGoodsInventory'),
      //       icon: 'fa-dot-circle-o',
      //       to: '/inventory/goods/list',
      //     },
      //     // {
      //     //   type: MENU_TYPE.SUB_SECTION,
      //     //   title: i18n.t('menu.lbl.lblPrinting'),
      //     //   icon: 'fa-circle',
      //     // },
      //     {
      //       type: MENU_TYPE.LOWEST_LAYER,
      //       title: i18n.t('menu.lbl.rawMatLbl'),
      //       icon: 'fa-dot-circle-o',
      //       to: '/raw-materials/labels/print',
      //     },
      //     {
      //       type: MENU_TYPE.LOWEST_LAYER,
      //       title: i18n.t('menu.lbl.semiFGLbl'),
      //       icon: 'fa-dot-circle-o',
      //       to: '/semi-fgs/labels/print',
      //     },
      //     {
      //       type: MENU_TYPE.LOWEST_LAYER,
      //       title: i18n.t('menu.lbl.fGoodsLbl'),
      //       icon: 'fa-dot-circle-o',
      //       to: '/goods/labels/print',
      //     },
      //   ],
      // },
      // {
      //   type: MENU_TYPE.FIRST_LAYER,
      //   title: i18n.t('menu.lbl.knowledge'),
      //   icon: 'mdi-book-open-blank-variant',
      //   to: '/',
      //   items: [
      //     // {
      //     //   type: MENU_TYPE.SUB_SECTION,
      //     //   title: i18n.t('menu.lbl.recipeMgmt'),
      //     //   icon: 'fa-circle',
      //     // },
      //     {
      //       type: MENU_TYPE.LOWEST_LAYER,
      //       title: i18n.t('menu.lbl.manageCategories'),
      //       icon: 'fa-dot-circle-o',
      //       to: '/recipes/categories/list',
      //     },
      //     {
      //       type: MENU_TYPE.LOWEST_LAYER,
      //       title: i18n.t('menu.lbl.manageRecipes'),
      //       icon: 'fa-dot-circle-o',
      //       to: '/recipes/category-list',
      //     },
      //   ],
      // },
      // {
      //   type: MENU_TYPE.FIRST_LAYER,
      //   title: i18n.t('menu.lbl.settings'),
      //   icon: 'mdi-cog-outline',
      //   to: '/',
      //   items: [
      //     // {
      //     //   type: MENU_TYPE.SUB_SECTION,
      //     //   title: i18n.t('menu.lbl.maintenance'),
      //     //   icon: 'fa-circle',
      //     // },
      //     {
      //       type: MENU_TYPE.LOWEST_LAYER,
      //       title: i18n.t('menu.lbl.suppliers'),
      //       icon: 'fa-dot-circle-o',
      //       to: '/maintenance/suppliers/list',
      //     },
      //     {
      //       type: MENU_TYPE.LOWEST_LAYER,
      //       title: i18n.t('menu.lbl.rawMat'),
      //       icon: 'fa-dot-circle-o',
      //       to: '/maintenance/raw-materials/list',
      //     },
      //     {
      //       type: MENU_TYPE.LOWEST_LAYER,
      //       title: i18n.t('menu.lbl.semiFinishedGoods'),
      //       icon: 'fa-dot-circle-o',
      //       to: '/maintenance/semi-fgs/list',
      //     },
      //     {
      //       type: MENU_TYPE.LOWEST_LAYER,
      //       title: i18n.t('menu.lbl.fGoods'),
      //       icon: 'fa-dot-circle-o',
      //       to: '/maintenance/goods/list',
      //     },
      //     {
      //       type: MENU_TYPE.LOWEST_LAYER,
      //       title: i18n.t('menu.lbl.warehouses'),
      //       icon: 'fa-dot-circle-o',
      //       to: '/maintenance/warehouses/list',
      //     },
      //     // {
      //     //   type: MENU_TYPE.SUB_SECTION,
      //     //   title: i18n.t('menu.lbl.sysSettings'),
      //     //   icon: 'fa-circle',
      //     // },
      //     {
      //       type: MENU_TYPE.LOWEST_LAYER,
      //       title: i18n.t('menu.lbl.branches'),
      //       icon: 'fa-dot-circle-o',
      //       to: '/maintenance/branches/list',
      //     },
      //   ],
      // },
      // {
    ],
    currentLang: {},
    pageSizeOpts: [2, 5, 10, 15, 20, 50, -1],
    paginationOpts: {
      page: 1,
      pageSize: 10,
    },
    dateFormat: 'YYYY-MM-DD',
    defaultPageAfterLogin: 'Home',
    badges: {
      orders: 0,
    },
    dashStats: {
      orders: {
        queue: 0, // Pay queue
        todo: 0, // Order queue
        ready: 0, // Collect queue
        sales: 0,
      },
      stock: {
        par: 0,
        low: 0,
      },
    },
  },
  getters: {
    getCurrentLang() {
      let lang = AppStorage.local.get('lang');
      if (!isUndefinedNullOrEmpty(lang)) {
        return JSON.parse(lang);
      } else {
        lang = JSON.stringify({ code: 'en_US', name: 'English' });
        AppStorage.local.set('lang', lang);
        return lang;
      }
    },
  },
  mutations: {
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_DRAWER_IMG(state, payload) {
      state.drawerImage = payload;
    },
    SET_MINI(state, payload) {
      state.mini = payload;
    },
    SET_ORDER_COUNT(state, payload) {
      state.badges.orders = payload;
    },
    SET_DASH_STATS(state, payload) {
      state.dashStats = payload;
    },
  },
  actions: {
    getOrderQueueTotalRec({ commit }) {
      const data = {
        page: 0,
        status: ORDER_STATUS.CONFIRMED,
      };
      return orderApi.list(data).then(res => {
        commit('SET_ORDER_COUNT', res.data.total);
      });
    },
  },
};

export default app;
