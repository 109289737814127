import Api from '../api';

export const storeShiftApi = {
  ddl(params) {
    return Api.get('store/shift/picklist', params);
  },
  list(params) {
    return Api.get('store/shift/list', params);
  },
  detail(params) {
    return Api.get('store/shift', params);
  },
  currentShift(params) {
    return Api.get('store/shift/list/current', params);
  },
  close(data) {
    return Api.post('store/shift/close', data);
  },
};

export const storeLocApi = {
  update(data) {
    return Api.post('store/loc/update', data);
  },
};

export const gisLocApi = {
  ddl(params) {
    return Api.get('gis/loc/picklist', params);
  },
  list(params) {
    return Api.get('gis/loc/list', params);
  },
  detail(params) {
    return Api.get('gis/loc', params);
  },
};
