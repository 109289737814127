export const EVENT_ORDER = {
  OrderCreated: {
    hash: 'c042598c06ecd928b7448d87c6018a21',
    keys: ['storeId', 'orderId', 'seq', 'curSeq'],
  },
  OrderConfirmed: {
    hash: '5aa9e61a36dc2d1338f03990c18cba85',
    keys: ['storeId', 'orderId', 'seq', 'curSeq', 'deliveryId'],
  },
};

// | Domain Event | MD5 Hash |
// | ------------ | -------- |
// | FCM.OrderCreated(storeId: number, orderId: number, status: ORDER_STATUS, channel: ORDER_CHANNEL, seq?: number, curSeq?: number) | 2feda24c72e25908e11e43c18d7cc5fe |
// | FCM.OrderVoided(storeId: number, orderId: number) | 68deae4846c8ca246e49294ab4f735bb |
// | FCM.OrderConfirmed(storeId: number, orderId: number, seq: number, curSeq?: number, deliveryId?: number) = 5aa9e61a36dc2d1338f03990c18cba85 |
// | FCM.OrderCompleted(storeId: number, orderId: number) | 4b2956acf95ad1d66a2d054d81743612 |
// | FCM.OrderClosed(storeId: number, orderId: number) | 3cf5f658ea02b637f6c3dc6bf700028a |
// | FCM.OrderFailed(storeId: number, orderId: number) | 146a2d92df89164a411b0007b3261ae3 |
// | FCM.OrderPayment(storeId: number, orderId: number, payMethod?: number, orderNo?: string, amt?: number, curr?: string) | 614544831dd530441fa3a43b685e8722 |
// | FCM.PgTxCreated(mod: string, orderId: number, amt: number, curr: string, orderNo: string, payType: number, txId: number) | a385fed85cac0f70dd62388f0c6a70ee |
// | FCM.PgTxApproved(mod: string, orderId: number, amt: number, curr: string, orderNo: string, payType: number, txId: number) | a6fd04144bd0649fbb9e9933ae9d3172 |
// | FCM.PgTxDeclined(mod: string, orderId: number, amt: number, curr: string, orderNo: string, payType: number, txId: number) | e80e8fa4049b94bd55cc9821360ab2d1 |
// | FCM.PgTxFailed(mod: string, orderId: number, amt: number, curr: string, orderNo: string, payType: number, txId: number) | 36bc9bf137a13729e4f0462fa3343462 |

// | FCM.DeliveryCreated(storeId: number, orderId: number, userId: number, deliveryId: number, refNo: string, status: DELIVERY_ORDER_STATUS) | b87752571330f0b52284be7c11556b87 |
// | FCM.DeliveryAssigning(storeId: number, orderId: number, userId: number) | 01485d9c1fb019ee5aac43fe1ea2bf53 |
// | FCM.DeliveryAssigned(storeId: number, orderId: number, userId: number, lat: number, lng: number) | 52e6c58093c01542865f2335f08ab59f |
// | FCM.DeliveryPickedUp(storeId: number, orderId: number, userId: number, lat: number, lng: number) | 79ddb29640bb29c7b3d780e6f99d8d6c |
// | FCM.DeliveryOnGoing(storeId: number, orderId: number, userId: number, lat: number, lng: number) | a668cd855bee8f21a89a37d347982e50 |
// | FCM.DeliveryDelivered(storeId: number, orderId: number, userId: number, lat: number, lng: number) | 570a587f511d39731113211b5dd657f7 |
// | FCM.DeliveryCancelled(storeId: number, orderId: number, userId: number) | e8d1061193a23f9c74e0511d9c6fe397 |
// | FCM.DeliveryFailed(storeId: number, orderId: number, userId: number) | 8baf24dfd02f9b1666953490185503a7 |
// | FCM.DeliveryRejected(storeId: number, orderId: number, userId: number) | dc6793737ed4ae77c522687ac9f8a16d |
