import moment from 'moment';

export function isUndefinedNullOrEmpty(value) {
  if (null === value || undefined === value) return true;
  if ('string' === typeof value) {
    if ('' == value) return true;
    return false;
  }

  if ('number' === typeof value) return false;

  if ('object' === typeof value) {
    if (Array.isArray(value)) {
      if (0 >= value.length) return true;
      return false;
    }

    if (0 >= Object.keys(value).length) return true;
    return false;
  }

  return true;
}

export function roundToDecimal(num, decimal = 2) {
  if (isUndefinedNullOrEmpty(num)) return;
  return parseFloat((Math.round((num + Number.EPSILON) * 100) / 100).toFixed(decimal));
}

export function priceFormat(num) {
  return num.toFixed(2);
}

export function priceWithCommas(value) {
  if (!value) {
    return value;
  }
  let val = (value / 1).toFixed(2);
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// keys: Array of wanted keys in json, data: full json data
export function extractJsonKey(keys, data) {
  if (isUndefinedNullOrEmpty(keys)) return data;
  let final = {};
  for (let key of keys) {
    if (data[key] !== undefined) {
      final[key] = data[key];
    }
  }
  return final;
}

export function calTotalPage(total, size) {
  if (!total) {
    return 0;
  }
  return Math.ceil(total / size);
}

export function setUTCTime(params) {
  return moment(params)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss');
}

export function dateOnly(dateTime) {
  if (isUndefinedNullOrEmpty(dateTime)) return '-';
  let date = null;
  try {
    date = moment(dateTime).format('YYYY-MM-DD');
  } catch (error) {
    return '-';
  }
  if (date == 'Invalid date') return '-';
  return date;
}

export function setTimeZone(dateTime, format, timeZone = null) {
  // If date format is incomplete, like missing second, use New Date(dateTime)
  if (dateTime !== undefined && dateTime !== null && dateTime !== '' && dateTime !== '0000-00-00 00:00:00') {
    if (!format) format = 'D MMM h:mma';
    // if (!currentFormat) currentFormat = 'YYYY-MM-DD HH:mm:ss';
    return moment
      .parseZone(dateTime)
      .local()
      .format(format);
  } else {
    return '-';
  }
}

export function getCurrentDate() {
  return moment().format('YYYY-MM-DD');
}

export function isToday(someDate) {
  if (someDate instanceof Date) {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  }
  return false;
}

export function getTimeRemaining(endtime) {
  const total = Date.parse(endtime) - Date.parse(new Date());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
}

export function processTimeRemaining(expireTime) {
  const calculatedTime = getTimeRemaining(expireTime);
  if (calculatedTime.days == 1) return calculatedTime.days + ' ' + this.$t('labels.timeLeft', { label: this.$t('labels.day') });
  else if (calculatedTime.days > 0)
    return calculatedTime.days + ' ' + this.$t('labels.timeLeft', { label: this.$t('labels.days') });
  else return 0 + ' ' + this.$t('labels.timeLeft', { label: this.$t('labels.day') });
  // else if (calculatedTime.days < 0) {
  //   if (calculatedTime.hours == 1)
  //     return (
  //       calculatedTime.hours +
  //       this.$t('labels.hour') +
  //       ' ' +
  //       calculatedTime.minutes +
  //       ' ' +
  //       this.$t('labels.minutes') +
  //       ' ' +
  //       this.$t('labels.left')
  //     );
  //   else if (calculatedTime.hours > 0) {
  //     return (
  //       calculatedTime.hours +
  //       this.$t('labels.hours') +
  //       ' ' +
  //       calculatedTime.minutes +
  //       ' ' +
  //       this.$t('labels.minutes') +
  //       ' ' +
  //       this.$t('labels.left')
  //     );
  //   }
  // }
}
