<template>
  <div>
    <CommonModal v-model="show" :modal-size="'modal-lg'">
      <template #header>
        <div class="modal-header">
          <div class="d-flex justify-space-between align-center">
            <div class="modal-header__title">{{ 'Restock' }}</div>
            <v-icon color="#fff" @click="close">mdi-close</v-icon>
          </div>
        </div>
      </template>
      <template #body-content>
        <div class="w-100">
          <DataTable
            :headers="headers"
            :items="localList"
            :pagination-config="pagination"
            :mobile-breakpoint="0"
            :show-footer="false"
            disable-pagination
          >
            <template #item-uom="{ item }">
              {{ `1 ${item.uom} = ${item.serving} ${$t('stocks.lbl.servings')}` }}
            </template>
            <template #item-qty="{ item }">
              <PlusMinusField v-model="item.qty" class="qty-bar" filled outlined dense />
            </template>
          </DataTable>
        </div>
      </template>
      <template #footer-content>
        <div class="d-flex justify-end">
          <AppBtn class="btn-black" :loading="loading" @click="confirm">{{ $t('act.confirm') }}</AppBtn>
        </div>
      </template>
    </CommonModal>
    <SuccessModal v-model="modalShow" :msg="$t('stocks.success.order')" :modal-width="450" />
  </div>
</template>
<script>
import CommonModal from '@/components/Modal';
import DataTable from '@/components/Table/DataTable';
import { stockOrderApi } from '@/api/stock';
export default {
  name: 'StockReorderModal',
  components: {
    CommonModal,
    DataTable,
  },
  props: {
    list: {
      type: Array,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      localList: [],
      pagination: {
        page: 1,
        pageSize: 50,
        total: 0,
      },
      modalShow: false,
    };
  },
  computed: {
    headers() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return [
          { width: '45%', text: this.$t('stocks.lbl.item'), value: 'name' },
          { width: '35%', text: this.$t('stocks.lbl.uom'), value: 'uom' },
          { width: '20%', text: this.$t('stocks.lbl.quantity'), value: 'qty' },
        ];
      } else if (this.$vuetify.breakpoint.sm) {
        return [
          { width: '45%', text: this.$t('stocks.lbl.item'), value: 'name' },
          { width: '35%', text: this.$t('stocks.lbl.uom'), value: 'uom' },
          { width: '20%', text: this.$t('stocks.lbl.quantity'), value: 'qty' },
        ];
      } else {
        return [
          { width: '45%', text: this.$t('stocks.lbl.item'), value: 'name' },
          { width: '35%', text: this.$t('stocks.lbl.uom'), value: 'uom' },
          { width: '20%', text: this.$t('stocks.lbl.quantity'), value: 'qty' },
        ];
      }
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  created() {
    this.localList = JSON.parse(JSON.stringify(this.list));
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    confirm() {
      let items = [];
      for (let i = 0; i < this.localList.length; i++) {
        let current = this.localList[i];
        if (current?.qty > 0) {
          items.push({ itemId: current.id, qty: current.qty });
        }
      }
      if (items.length <= 0) {
        return;
      }
      const data = {
        type: 4, // 1-Auto, 2-Manual, 3-Store HQ, 4-Store
        items,
      };
      this.loading = true;
      return stockOrderApi
        .create(data)
        .then(res => {
          this.loading = false;
          if (res.ret == '0') {
            this.modalShow = true;
            setTimeout(() => {
              this.close();
              this.modalShow = false;
              this.$emit('getList');
            }, 500);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-header {
  background-color: #000;
  &__title {
    color: #fff !important;
    font-size: 1.25rem;
    font-weight: 600;
  }
}
.v-data-table::v-deep {
  .v-data-table__wrapper {
    border-radius: 0px;
  }
}
.qty-bar::v-deep {
  max-width: 100px;
  .v-input {
    .v-input__prepend-outer,
    .v-input__append-outer {
      .v-btn {
        background-color: transparent;
        border: none;
      }
    }
  }
}
</style>
