<template>
  <v-main>
    <v-container class="content-wrap" fluid>
      <router-view :key="$route.path" />
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'DefaultView',
};
</script>
<style lang="scss" scoped>
@mixin for-sm {
  @media (max-width: 599px) {
    @content;
  }
}

.v-main {
  background-color: var(--primary-color);
  min-height: 100vh;
}
.content-wrap {
  padding: 6px 20px;
  @include for-sm {
    padding: 6px 12px;
  }
}
</style>
