/*
Naming convention
  Module: Eg. Supplier, Raw Mat, etc.
  Type: act-action, lbl-label, success, error

  {module}.{type}.{actual wording short form}
  {module}.{sub module}.{type}.{actual wording short form}
*/

import labels from './labels/en';
import maps from './maps/en';
export default {
  ...labels,
  ...maps,
  app: {
    name: 'Food Market',
    org: {
      name: 'BaogerLab',
      storePrefix: 'Baoger',
    },
  },

  'act.add': 'Add',
  'act.addToCart': 'Add to Cart',
  'act.back': 'Back',
  'act.cancel': 'Cancel',
  'act.close': 'Close',
  'act.confirm': 'Confirm',
  'act.next': 'Next',
  'act.ok': 'OK',
  'act.print': 'Print',
  'act.submit': 'Submit',

  'bar.lbl.deliveryOrder': 'Delivery Order',
  'bar.lbl.endShift': 'End Shift',
  'bar.lbl.lockdown': 'Lockdown',
  'bar.lbl.logout': 'Logout',
  'bar.lbl.orderHistory': 'Order History',
  'bar.lbl.recommendationOrder': 'Recommendation Order',

  'cart.act.addMore': 'Add More',
  'cart.act.placeOrder': 'Place Order',
  'cart.lbl.editCart': 'Edit Cart',
  'cart.lbl.editCart.confirmMsg1':
    'If you choose to continue, your pending items in cart will be replaced by items of selected order.',
  'cart.lbl.editCart.confirmMsg2': 'Items of selected order will be loaded into cart. Are you sure to continue?',
  'cart.success.add': 'Added to cart successfully',
  'cart.success.order.confirm': 'Order has been confirmed',

  'delivery.lbl.address': 'Address',
  'delivery.lbl.city': 'City',
  'delivery.lbl.delivery': 'Delivery',
  'delivery.lbl.deliveryChannel': 'Delivery Channel',
  'delivery.lbl.deliveryFee': 'Delivery Fee',
  'delivery.lbl.deliveryPartner': 'Delivery Partner',
  'delivery.lbl.deliveryTime': 'Delivery Time',
  'delivery.lbl.driver': 'Driver',
  'delivery.lbl.name': 'Name',
  'delivery.lbl.phoneNo': 'Phone Number',
  'delivery.lbl.postcode': 'Postcode',
  'delivery.lbl.selectTimeSlot': 'Select Time Slot',
  'delivery.lbl.unitBlockHouse': 'Unit/Block/House No',
  'delivery.err.locNotAvailable': 'Sorry, we are unable to serve your order location temporarily.',
  'delivery.tab.todayDelivery': "Today's Order",

  'header.lbl.cart': 'Cart',
  'header.lbl.cashPool': 'Cash Pool',
  'header.lbl.stock': 'Stock',
  'header.lbl.orders': 'Orders',

  'lbl.amount': 'Amount',
  'lbl.cash': 'Cash',
  'lbl.confirmation': 'Confirmation',
  'lbl.date': 'Date',
  'lbl.time': 'Time',
  'lbl.go': 'Go',
  'lbl.now': 'Now',
  'lbl.of': 'of',
  'lbl.ok': 'OK',
  'lbl.orderDate': 'Order Date',
  'lbl.reason': 'Reason',
  'lbl.remarks': 'Remarks',
  'lbl.results': 'Results',
  'lbl.rm': 'RM',
  'lbl.sessionTimeout': 'Session Timeout',
  'lbl.plsSelect': 'Please Select',
  'lbl.status': 'Status',
  'lbl.total': 'Total',
  'lbl.todaySales': "Today's Sales",

  'login.act.login': 'Login',
  'login.act.logout': 'Logout',
  'login.act.setPassword': 'Set Password',
  'login.lbl.confirmNewPassword': 'Confirm New Password',
  'login.lbl.newPassword': 'New Password',
  'login.lbl.password': 'Password',
  'login.lbl.setPassword': 'Set Password',
  'login.lbl.username': 'Username',

  'member.lbl.name': 'Name',
  'member.lbl.phoneNo': 'Phone Number',

  'menu.lbl.aLaCarte': 'À la carte',
  'menu.lbl.beverage': 'Beverage',
  'menu.lbl.nonFood': 'Non-Food',
  'menu.lbl.setMenu': 'Set Menu',

  'stocks.adj.lbl.addBack': 'Add Back',
  'stocks.adj.lbl.deduct': 'Deduct',
  'stocks.adj.lbl.stockAdj': 'Stock Adjust',
  'stocks.adj.lbl.stockAdjustment': 'Stock Adjustment',
  'stocks.adj.lbl.stocktake': 'Stocktake',

  'stocks.act.receive': 'Receive',
  'stocks.act.restock': 'Restock',
  'stocks.tab.refillDO': 'Refill DO',
  'stocks.tab.stockLevel': 'Stock Level',
  'stocks.tab.wastage': 'Wastage',
  'stocks.lbl.bal': 'Balance',
  'stocks.lbl.doItem': 'DO Item',
  'stocks.lbl.item': 'Item',
  'stocks.lbl.lowPar': 'Low PAR',
  'stocks.lbl.nos': 'NOS',
  'stocks.lbl.serves': 'serves',
  'stocks.lbl.orderDate': 'Order Date',
  'stocks.lbl.orderId': 'Order ID',
  'stocks.lbl.ordered': 'Ordered',
  'stocks.lbl.quantity': 'Quantity',
  'stocks.lbl.reorderPar': 'Reorder PAR',
  'stocks.lbl.servings': 'Servings',
  'stocks.lbl.total': 'Total',
  'stocks.lbl.uom': 'UOM',
  'stocks.success.order': 'Stock order has been placed',
  'stocks.success.refillOrder': 'Refill DO completed',

  'store.lbl.address': 'Address',
  'store.lbl.cashAmt': 'Cash Amount',
  'store.lbl.city': 'City',
  'store.lbl.postcode': 'Postcode',
  'store.lbl.province': 'Province',
  'store.lbl.storeLoc': 'Store Location',

  'success.update': 'Updated successfully',

  'orders.act.cancel': 'Cancel',
  'orders.act.choosePymtMethod': 'Choose Payment Method',
  'orders.act.collect': 'Collect',
  'orders.act.complete': 'Complete',
  'orders.act.edit': 'Edit',
  'orders.act.pay': 'Pay',
  'orders.act.ready': 'Ready',
  'orders.lbl.address': 'Address',
  'orders.lbl.beverage': 'Beverage',
  'orders.lbl.cartEmpty': 'Your cart is empty',
  'orders.lbl.channel': 'Channel',
  'orders.lbl.contactNo': 'Contact No.',
  'orders.lbl.customer': 'Customer',
  'orders.lbl.takeawayInformation': 'Takeaway Information',
  'orders.lbl.deliveryInformation': 'Delivery Information',
  'orders.lbl.deliveryTime': 'Delivery Time',
  'orders.lbl.food': 'Food',
  'orders.lbl.item': 'Item',
  'orders.lbl.remarks': 'Remarks',
  'orders.lbl.orderInAdvance': 'Order in Advance',
  'orders.lbl.orderNo': 'Order No',
  'orders.lbl.orderNow': 'Order Now',
  'orders.lbl.orderItems': 'Order Items',
  'orders.lbl.orderRemarks': 'Order Remarks',
  'orders.lbl.orderStatus': 'Order Status',
  'orders.lbl.quantity': 'Quantity',
  'orders.lbl.receiver': 'Receiver',
  'orders.lbl.payMethod': 'Payment Method',
  'orders.lbl.pickupInformation': 'Pickup Information',
  'orders.lbl.pickupTime': 'Pickup Time',
  'orders.lbl.printReceipt': 'Print Receipt',
  'orders.lbl.selfOrder': 'Self Order',
  'orders.print.failed': 'Print receipt failed',
  'orders.print.doNote.failed': 'Print delivery note failed',
  'orders.success.cancel': 'Order has been cancelled',
  'orders.success.collect': 'Order collected',
  'orders.success.complete': 'Order completed',
  'orders.success.pay': 'Payment has been made successfully',
  'orders.success.printRequestSent': 'Print request sent',
  'orders.tab.collectQueue': 'Collect Queue',
  'orders.tab.completedOrders': 'Completed Orders',
  'orders.tab.orderQueue': 'Order Queue',
  'orders.tab.payQueue': 'Pay Queue',
  'orders.tab.prepareList': 'Prepare List',

  'route.Cart': 'Cart',
  'route.DeliveryOrder': 'Delivery Order',
  'route.Stocks': 'Stocks',
  'route.Orders': 'Orders',
  'route.OrderHistory': 'Order History',

  stockAdjModeConfirmMsg: {
    stockTake: 'Stock balance of <span class="font-weight-medium">{item}</span> will be adjusted to: <span class="font-red-common font-weight-medium">{uomQty} {uom}, {serves} SERVES.</span>',
    stockAdj: 'Stock adjustment of <span class="font-weight-medium">{item}</span>:<br> <span class="font-red-common font-weight-medium">{mode} {uomQty} {uom}, {serves} SERVES.</span>',
  },
  stockAdjModeMsg: {
    stockTake: 'You are now in Stocktake mode. Please fill in the balance of the item.',
    stockAdj: 'You are now in Stock Adjust mode. Please fill in qty to adjust the stock balance.',
  },
};
