<template>
  <v-app-bar
    id="default-app-bar"
    app
    absolute
    class="v-bar--underline"
    color="var(--primary-color)"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    :height="barHeight"
  >
    <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />

    <div class="d-flex w-100" :class="$route.name == 'Home' ? 'align-start' : 'align-center'">
      <default-drawer-toggle class="hidden-sm-and-down" />

      <div v-if="$route.name == 'Home'" class="flex-grow-1">
        <v-row class="mx-0">
          <v-col cols="4" sm="4" class="py-2">
            <BarItem
              class="w-100"
              :badge-count="orderCount"
              :icon="require('@/assets/bar/orders.svg')"
              :item-key="BAR_ENUM.ORDER"
              :label="$t('header.lbl.orders')"
              @clicked="barItemClicked"
            >
              <template #badge>
                <div>
                  <span v-if="dashStats.orders.queue > 0" class="badge-figure badge-red mr-1">{{ dashStats.orders.queue }}</span>
                  <span v-if="dashStats.orders.todo > 0" class="badge-figure badge-blue mr-1">{{ dashStats.orders.todo }}</span>
                  <span v-if="dashStats.orders.ready > 0" class="badge-figure badge-green mr-1">{{
                    dashStats.orders.ready
                  }}</span>
                </div>
              </template>
              <template #badge-mobile>
                <div>
                  <div v-if="dashStats.orders.queue > 0" class="badge-figure badge-red">{{ dashStats.orders.queue }}</div>
                  <div v-if="dashStats.orders.todo > 0" class="badge-figure badge-blue">{{ dashStats.orders.todo }}</div>
                  <div v-if="dashStats.orders.ready > 0" class="badge-figure badge-green">{{ dashStats.orders.ready }}</div>
                </div>
              </template>
            </BarItem>
          </v-col>
          <v-col cols="4" sm="4" class="py-2">
            <BarItem
              class="w-100"
              :show-badge="false"
              :icon="require('@/assets/bar/stock.svg')"
              :item-key="BAR_ENUM.STOCK"
              :label="$t('header.lbl.stock')"
              @clicked="barItemClicked"
            >
              <template #badge>
                <div>
                  <span v-if="dashStats.stock.low > 0" class="badge-figure badge-red mr-1">{{ dashStats.stock.low }}</span>
                  <span v-if="dashStats.stock.par > 0" class="badge-figure badge-blue mr-1">{{ dashStats.stock.par }}</span>
                </div>
              </template>
              <template #badge-mobile>
                <div>
                  <div v-if="dashStats.stock.low > 0" class="badge-figure badge-red">{{ dashStats.stock.low }}</div>
                  <div v-if="dashStats.stock.par > 0" class="badge-figure badge-blue">{{ dashStats.stock.par }}</div>
                </div>
              </template>
            </BarItem>
          </v-col>
          <!-- <v-col cols="6" sm="3">
            <BarItem :icon="require('@/assets/bar/cash.svg')" :item-key="BAR_ENUM.CASH_POOL" :label="$t('header.lbl.cashPool')" :value="300" />
          </v-col> -->
          <v-col cols="4" sm="4" class="py-2">
            <BarItem
              class="w-100"
              :badge-count="getCartItemsCount"
              :icon="require('@/assets/bar/cart.svg')"
              :item-key="BAR_ENUM.CART"
              :label="$t('header.lbl.cart')"
              @clicked="barItemClicked"
            >
              <template #badge>
                <div class="font-2r font-weight-bold">{{ getCartItemsCount }}</div>
              </template>
              <template #badge-mobile>
                <div v-if="getCartItemsCount > 0" class="badge-figure badge-green">{{ getCartItemsCount }}</div>
              </template>
            </BarItem>
          </v-col>
        </v-row>
      </div>
      <div v-else class="d-flex justify-space-between align-center flex-grow-1">
        <div class="d-flex align-center">
          <img class="icon mr-2" :src="icon" alt="icon" />
          <div class="header-text mr-2">{{ name }}</div>
        </div>
        <img class="logo cursor-pointer" src="@/assets/logo.svg" alt="logo" @click="navigatePush('Home')" />
      </div>
    </div>
    <v-spacer />

    <!-- <default-search class="hidden-sm-and-down" /> -->

    <!-- <default-go-home /> -->

    <!-- <default-notifications /> -->

    <!-- <default-account /> -->
  </v-app-bar>
</template>

<script>
import BarItem from './bar-item';
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'DefaultBar',

  components: {
    BarItem,
    // DefaultAccount: () =>
    //   import(
    //     /* webpackChunkName: "default-account" */
    //     './widgets/Account'
    //   ),
    DefaultDrawerToggle: () =>
      import(
        /* webpackChunkName: "default-drawer-toggle" */
        './widgets/DrawerToggle'
      ),
    // DefaultGoHome: () =>
    //   import(
    //     /* webpackChunkName: "default-go-home" */
    //     './widgets/GoHome'
    //   ),
    // DefaultNotifications: () => import(
    //   /* webpackChunkName: "default-notifications" */
    //   './widgets/Notifications'
    // ),
    // DefaultSearch: () => import(
    //   /* webpackChunkName: "default-search" */
    //   './widgets/Search'
    // ),
  },
  data() {
    return {
      BAR_ENUM: {
        ORDER: 'ORDER',
        STOCK: 'STOCK',
        CASH_POOL: 'CASH_POOL',
        CART: 'CART',
      },
    };
  },
  computed: {
    ...mapState({
      orderCount: state => state.app.badges.orders,
      dashStats: state => state.app.dashStats,
    }),
    ...mapGetters(['getCartItemsCount']),
    drawer: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(value) {
        this.$store.commit('SET_DRAWER', value);
      },
    },
    // mini: {
    //   get() {
    //     return this.$store.state.app.mini;
    //   },
    //   set(value) {
    //     this.$store.commit('SET_MINI', value);
    //   },
    // },
    name() {
      return this.$t(`route.${this.$route.name}`);
    },
    icon() {
      const routeName = this.$route.name;
      switch (routeName) {
        case 'Orders':
          return require('@/assets/bar/orders.svg');
        case 'Stocks':
          return require('@/assets/bar/stock.svg');
        case 'Cart':
          return require('@/assets/bar/cart.svg');
        case 'OrderHistory':
          return require('@/assets/bar/orders.svg');
        case 'DeliveryOrder':
          return require('@/assets/bar/truck-delivery-outline.svg');
        default:
          return '';
      }
    },
    isHomePage() {
      return this.$route.name == 'Home' ? true : false;
    },
    barHeight() {
      return this.isMobileView ? 85 : this.isHomePage ? 100 : 85;
    },
  },
  methods: {
    barItemClicked(key) {
      switch (key) {
        case this.BAR_ENUM.ORDER:
          this.navigatePush('Orders');
          break;
        case this.BAR_ENUM.STOCK:
          this.navigatePush('Stocks');
          break;
        case this.BAR_ENUM.CART:
          this.navigatePush('Cart');
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#default-app-bar {
  box-shadow: none;

  .header-text {
    font-size: 1.5rem;
    font-weight: 600;
  }
}
.icon {
  width: 40px;
}
.logo {
  max-width: 75px;
  width: 100%;
}
.badge-figure {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px;
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  // &--red {
  //   background-color: var(--red);
  // }
  // &--blue {
  //   background-color: #59b3ff;
  // }
  // &--green {
  //   background-color: var(--green);
  // }
}
</style>
