<template>
  <div>
    <CommonModal
      v-model="stockAdjModalShow"
      :modal-size="'modal-sm'"
      :title="$t('stocks.adj.lbl.stockAdjustment')"
      :footer="false"
      :persistent="true"
      @close="$emit('input', false)"
    >
      <template #body>
        <div class="px-4 py-3">
          <ValidationObserver ref="observer" v-slot="{ passes }">
            <div class="d-flex align-center mb-2">
              <h3 class="mr-2">{{ form.item }}</h3>
              <div class="font-14">({{ `${form.serving} per ${form.uom}` }})</div>
            </div>
            <v-btn-toggle v-model="form.mode" class="btn-toggle mb-2" mandatory @change="modeChg">
              <AppBtn>
                {{ $t('stocks.adj.lbl.stocktake') }}
              </AppBtn>
              <AppBtn>
                {{ $t('stocks.adj.lbl.stockAdj') }}
              </AppBtn>
            </v-btn-toggle>
            <div class="mb-4"><span class="font-red-common">*</span> {{ modeMsg }}</div>
            <v-row>
              <v-col cols="12" sm="12">
                <Select
                  v-model="form.type"
                  :rules="rules.type"
                  :options="stockAdjTypeList"
                  :label="'Type'"
                  :single-line="false"
                  :placeholder="'Type'"
                  :clearable="false"
                />
              </v-col>
              <v-col cols="6" sm="6">
                <TextField v-model="form.uomQty" :suffix="form.uom" :label="form.uom" :type="'number'" />
              </v-col>
              <v-col cols="6" sm="6">
                <TextField
                  v-model="form.serves"
                  :suffix="$t('stocks.lbl.serves').toUpperCase()"
                  :label="$t('stocks.lbl.serves').toUpperCase()"
                  :type="'number'"
                />
              </v-col>
              <v-col cols="12" sm="12">
                <TextField v-model="form.reason" :label="$t('lbl.reason')" :single-line="false" :rules="rules.reason" />
              </v-col>
              <v-col cols="12">
                <div class="text-right">
                  {{ stockAdjQtyText }}: <span class="font-weight-medium">{{ totalQty }}</span>
                </div>
              </v-col>
              <v-col class="text-right">
                <AppBtn class="btn-black" @click="passes(showConfirmModal)">{{ $t('act.submit') }}</AppBtn>
              </v-col>
            </v-row>
          </ValidationObserver>
        </div>
      </template>
    </CommonModal>
    <CommonModal
      v-if="confirmModalShow"
      v-model="confirmModalShow"
      :modal-size="'modal-xs'"
      :title="confirmModalTitle"
      :persistent="true"
      @close="confirmModalBack"
    >
      <template #body-content>
        <div class="my-3">
          <p class="font-1dot1r" v-html="confirmModalMsg"></p>
        </div>
      </template>
      <template #footer-content>
        <div class="confirm-modal-footer text-right">
          <AppBtn class="btn-grey mr-2" :disabled="loading" @click="confirmModalBack">{{ $t('act.back') }}</AppBtn>
          <AppBtn class="btn-black" :loading="loading" @click="create">{{ $t('act.confirm') }}</AppBtn>
        </div>
      </template>
    </CommonModal>
  </div>
</template>
<script>
import CommonModal from '@/components/Modal';
import { stockAdjApi } from '@/api/stock';
import { mapState, mapActions } from 'vuex';
import { STOCK_ADJ_MODE, STOCK_ADJ_TYPE_DR } from '@/common/stockEnum';

export default {
  components: {
    CommonModal,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        mode: STOCK_ADJ_MODE.STOCKTAKE,
        reason: '',
        type: null,
        status: 1,
      },
      loading: false,

      rules: {
        type: { required: true },
        reason: { required: false, max: 150 },
      },

      initialBal: null,
      initialUomQty: null,
      initialServes: null,

      stockAdjModalShow: false,

      confirmModalShow: false,
      confirmModalTitle: this.$t('stocks.adj.lbl.stockAdjustment'),
      confirmModalMsg: '',

      stockAdjTypeObj: {},
    };
  },
  computed: {
    ...mapState({
      stockAdjTypeList: state => state.lookup.stockAdjTypeList,
    }),
    // show: {
    //   get() {
    //     return this.value;
    //   },
    //   set(value) {
    //     this.$emit('input', value);
    //   },
    // },
    STOCK_ADJ_MODE() {
      return STOCK_ADJ_MODE;
    },
    totalQty() {
      return Number(this.form.uomQty * this.form.serving) + Number(this.form.serves);
    },
    modeMsg() {
      return this.form.mode == STOCK_ADJ_MODE.STOCKTAKE
        ? this.$t('stockAdjModeMsg.stockTake')
        : this.$t('stockAdjModeMsg.stockAdj');
    },
    stockAdjQtyText() {
      return this.form.mode == STOCK_ADJ_MODE.STOCKTAKE ? 'Balance' : 'Adjust Qty';
    },
  },
  created() {
    this.populateData(this.data);
    if (this.isUndefinedNullOrEmpty(this.stockAdjTypeList)) {
      this.fetchStockAdjTypeList();
    }
    this.stockAdjModalShow = true;
  },
  methods: {
    ...mapActions(['fetchStockAdjTypeList']),
    populateData(data) {
      this.initialBal = data.bal;
      this.initialUomQty = Math.floor(data.bal / data.serving);
      this.initialServes = data.bal % data.serving;

      this.form = {
        ...this.form,
        item: data.item,
        itemId: data.itemId,
        uom: data.uom,
        uomQty: this.initialUomQty,
        serves: this.initialServes,
        serving: data.serving,
      };
    },
    showConfirmModal() {
      this.stockAdjTypeObj = this.stockAdjTypeList.find(item => item.value == this.form.type);

      if (this.isUndefinedNullOrEmpty(this.totalQty) || isNaN(this.totalQty)) {
        this.showError('Invalid qty!');
        return;
      }
      if (this.form.mode == STOCK_ADJ_MODE.STOCKTAKE) {
        if (this.initialBal == this.totalQty) {
          this.showError('Stock count before and after adjusted are same. Please make sure it is correct.');
          return;
        }

        if (this.stockAdjTypeObj.dr == STOCK_ADJ_TYPE_DR.DEDUCT) {
          if (this.totalQty > this.initialBal) {
            this.showError('Please choose the correct stock adjustment type.');
            return;
          }
        } else if (this.stockAdjTypeObj.dr == STOCK_ADJ_TYPE_DR.ADD_BACK) {
          if (this.initialBal > this.totalQty) {
            this.showError('Please choose the correct stock adjustment type.');
            return;
          }
        }
      } else if (this.form.mode == STOCK_ADJ_MODE.STOCK_ADJ) {
        if (!Number(this.form.uomQty) && !Number(this.form.serves)) {
          this.showError('Please fill in stock adjustment qty.');
          return;
        }
      }

      this.stockAdjModalShow = false;
      if (this.form.mode == STOCK_ADJ_MODE.STOCKTAKE) {
        this.confirmModalMsg = this.$t('stockAdjModeConfirmMsg.stockTake', {
          item: this.form.item,
          uomQty: this.form.uomQty || 0,
          uom: this.form.uom,
          serves: this.form.serves || 0,
        });
      } else if (this.form.mode == STOCK_ADJ_MODE.STOCK_ADJ) {
        this.confirmModalMsg = this.$t('stockAdjModeConfirmMsg.stockAdj', {
          item: this.form.item,
          mode:
            this.stockAdjTypeObj.dr == STOCK_ADJ_TYPE_DR.DEDUCT
              ? this.$t('stocks.adj.lbl.deduct')
              : this.$t('stocks.adj.lbl.addBack'),
          uomQty: this.form.uomQty || 0,
          uom: this.form.uom,
          serves: this.form.serves || 0,
        });
      }
      this.confirmModalShow = true;
    },
    confirmModalBack() {
      this.confirmModalShow = false;
      this.stockAdjModalShow = true;
    },
    modeChg(value) {
      if (value == STOCK_ADJ_MODE.STOCKTAKE) {
        this.form.uomQty = this.initialUomQty;
        this.form.serves = this.initialServes;
      } else if (value == STOCK_ADJ_MODE.STOCK_ADJ) {
        this.form.uomQty = 0;
        this.form.serves = 0;
      }
    },
    create() {
      let qty = null;
      if (this.form.mode == STOCK_ADJ_MODE.STOCKTAKE) {
        qty = this.initialBal - this.totalQty;
      } else if (this.form.mode == STOCK_ADJ_MODE.STOCK_ADJ) {
        const tempQty = Number(this.form.uomQty) * this.form.serving + Number(this.form.serves);
        if (this.stockAdjTypeObj.dr == STOCK_ADJ_TYPE_DR.DEDUCT) {
          qty = Math.abs(tempQty);
        } else if (this.stockAdjTypeObj.dr == STOCK_ADJ_TYPE_DR.ADD_BACK) {
          qty = -Math.abs(tempQty);
        }
      }

      const data = {
        itemId: this.form.itemId,
        qty,
        uom: this.form.uom,
        reason: this.form.reason,
        type: this.form.type,
        status: this.form.status,
      };
      this.loading = true;

      return stockAdjApi
        .create(data)
        .then(res => {
          if (res.ret == '0') {
            this.showSuccess('Stock adjusted successfully');
            const qtyToUpdate = this.stockAdjTypeObj.dr == STOCK_ADJ_TYPE_DR.DEDUCT ? -Math.abs(qty) : Math.abs(qty);
            this.$emit('success', { itemId: this.form.itemId, qty: qtyToUpdate });
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-toggle {
  .v-btn {
    &.v-item--active {
      background-color: var(--primary-color);
    }
  }
}
</style>
