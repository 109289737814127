export const ORDER_STATUS = {
  DRAFT: 0,
  CLOSED: 1,
  FAILED: 2,
  EXPIRED: 3,
  VOIDED: 4,
  PICKED_UP: 7,
  READY: 8,
  CONFIRMED: 9,
  PENDING_PAYMENT: 11,
};

export const ORDER_ITEM_STATUS = {
  DRAFT: 0,
  CLOSED: 1,
  VOIDED: 4,
  READY: 8,
  PLACED: 9,
};

export const ORDER_ITEM_TYPE = {
  SET: 1,
  ALA_CARTE: 2,
  DELIVERY: 9,
  BUNDLED: 11,
  PROMO: 12,
  VOUCHER: 21,
  DISCOUNT: 22,
  ADJUSTMENT: 29,
  PAYMENT: 31,
};

export const ORDER_CHANNEL = {
  POS: 1,
  TAKEAWAY_WEB: 2,
  TAKEAWAY_MOBILE: 3,
  POS_DINE_IN: 21,
  DINE_IN_WEB: 22,
  DINE_IN_MOBILE: 23,
  PICKUP_WEB: 32,
  PICKUP_MOBILE: 33,
  DELIVERY_POS: 41,
  DELIVERY_WEB: 42,
  DELIVERY_MOBILE: 43,
  PREORDER_WEB: 52,
  PREORDER_MOBILE: 53,
  FOODPANDA: 71,
  GRABFOOD: 72,
  OTHERS: 99,
};

export const ORDER_TAB = {
  PAY_QUEUE: 1,
  ORDER_QUEUE: 2,
  PREPARE_LIST: 3,
  COLLECT_QUEUE: 4,
};

export const ORDER_TYPE = {
  TAKEAWAY: 1,
  DELIVERY: 2,
};

export const SCHEDULE_TYPE = {
  NOW: 1,
  IN_ADVANCE: 2,
};

export const DELIVERY_STATUS = {
  PENDING: 0,
  DELIVERED: 1,
  ERROR: 2,
  REJECTED: 3,
  CANCELLED: 4,
  ASSIGNED: 5,
  COMING: 6,
  PICKED_UP: 7,
  ASSIGNING: 8,
  PLACED: 9,
};
