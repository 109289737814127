<template>
  <v-list expand nav v-bind="$attrs" v-on="$listeners">
    <template v-for="(item, i) in items">
      <default-list-group v-if="item.items" :key="`group-${i}`" :item="item" />

      <default-list-item v-else :key="`item-${i}`" :item="item" />
    </template>
    <v-list-item class="cursor" :class="['py-1']" v-bind="$attrs" v-on="$listeners" @click="endShiftModal = true">
      <v-list-item-icon class="my-2 align-self-center">
        <v-icon class="custom-v-icon" color="var(--primary-color)">mdi-clock-out</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title class="drawer-text" v-text="$t('bar.lbl.endShift')" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item class="cursor" :class="['py-1']" v-bind="$attrs" v-on="$listeners" @click="logout">
      <v-list-item-icon class="my-2 align-self-center">
        <v-img class="custom-icon" :src="require('@/assets/bar/logout.svg')" />
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title class="drawer-text" v-text="$t('bar.lbl.logout')" />
      </v-list-item-content>
    </v-list-item>
    <CommonModal v-model="endShiftModal" :modal-size="'modal-xs'" :title="$t('bar.lbl.endShift')">
      <template #body>
        <ValidationObserver ref="observer">
          <div class="modal-body d-flex justify-start align-center py-6">
            <TextField
              v-model="shiftCashAmt"
              class="w-100"
              :type="'number'"
              :prefix="$t('lbl.rm')"
              :rules="rules.shiftCashAmt"
              :label="$t('store.lbl.cashAmt')"
            />
          </div>
        </ValidationObserver>
      </template>
      <template #footer-content>
        <div class="w-100 text-right">
          <AppBtn class="btn-black" :loading="loading" @click="endShift">{{ $t('act.confirm') }}</AppBtn>
        </div>
      </template>
    </CommonModal>
  </v-list>
</template>

<script>
import { mapActions } from 'vuex';
import { storeShiftApi } from '@/api/store';
import CommonModal from '@/components/Modal';
export default {
  name: 'DefaultList',

  components: {
    CommonModal,
    DefaultListGroup: () => import('./ListGroup'),
    DefaultListItem: () => import('./ListItem'),
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      endShiftModal: false,
      shiftCashAmt: null,
      loading: false,
      rules: {
        shiftCashAmt: { required: true },
      },
    };
  },
  methods: {
    ...mapActions(['logout_act']),
    logout() {
      this.logout_act().then(() => {
        this.navigateReplace('Login');
        this.$store.commit('SET_DRAWER', false);
      });
    },
    async endShift() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      const data = {
        cashAmt: this.shiftCashAmt,
      };
      this.loading = true;
      return storeShiftApi
        .close(data)
        .then(() => {
          this.loading = false;
          this.logout();
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-list {
  .v-list-item::v-deep {
    .v-list-item__icon {
      margin-right: 15px;
      min-width: 20px;
    }
  }
  .v-list-group::v-deep {
    .v-list-item__icon {
      margin-right: 15px;
      min-width: 20px;
    }
  }
}
.custom-icon {
  width: 25px;
  font-size: 18px;
  margin-right: 8px;
}
.custom-v-icon {
  font-size: 30px;
  margin-left: -6px;
  margin-right: 8px;
}
.drawer-text {
  color: var(--primary-color);
  font-size: 16px;
}
</style>
