<template>
  <CommonModal v-model="show" :modal-size="'modal-lg'" :title="$t('orders.lbl.orderItems')" :footer="false">
    <template #body-content>
      <v-row class="ma-n2">
        <v-col cols="12" sm="6">
          <div class="font-1dot1r font-weight-medium mb-1">By Set</div>
          <v-simple-table class="table-wrap simple-table w-100" fixed-header>
            <template v-slot:default>
              <thead class="header">
                <tr>
                  <th class="text-left" style="height: 40px">
                    {{ $t('orders.lbl.item') }}
                  </th>
                </tr>
              </thead>
              <tbody class="body">
                <tr v-for="(item, index) in listByGroup" :key="index">
                  <td>
                    <div class="font-1dot1r font-weight-medium">{{ item.qty }}x {{ item.label }}</div>
                    <ul>
                      <li v-for="(menuItem, index2) in item.items" :key="index2">
                        {{ menuItem.name }}
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="font-1dot1r font-weight-medium mb-1">By Item</div>
          <v-simple-table class="table-wrap simple-table w-100" fixed-header>
            <template v-slot:default>
              <thead class="header">
                <tr>
                  <th class="text-left" style="height: 40px">
                    {{ $t('orders.lbl.item') }}
                  </th>
                  <th class="text-left" style="height: 40px">
                    {{ $t('orders.lbl.quantity') }}
                  </th>
<!--                   <th class="text-left" style="height: 40px">
                    {{ $t('lbl.status') }}
                  </th>
 -->                  <th class="text-right" style="height: 40px"></th>
                </tr>
              </thead>
              <tbody class="body">
                <tr v-for="(item, index) in orderedList" :key="index">
                  <td>{{ item.item }}</td>
                  <td>{{ item.qty }}</td>
                  <!-- <td>{{ $t('map.orderItemStatus')[item.status] }}</td> -->
                  <td class="text-right">
                    <AppBtn
                      v-if="item.status == ORDER_ITEM_STATUS.PLACED"
                      class="btn-orange"
                      :loading="item.loading"
                      @click="ready(item)"
                      >{{ $t('orders.act.ready') }}</AppBtn
                    >
                    <v-icon v-if="item.status == ORDER_ITEM_STATUS.READY" class="mr-5" color="var(--green)" size="30"
                      >mdi-check-circle-outline</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </template>
  </CommonModal>
</template>
<script>
import CommonModal from '@/components/Modal';
import { orderItemApi } from '@/api/order';
import { ORDER_ITEM_STATUS } from '@/common/orderEnum';
export default {
  name: 'OrderItemModal',
  components: {
    CommonModal,
  },
  props: {
    list: {
      type: Array,
      default: null,
    },
    listByGroup: {
      type: Array,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    orderedList() {
      const list = this.list;
      return list.sort(this.sortJsonArr);
    },
    ORDER_ITEM_STATUS() {
      return ORDER_ITEM_STATUS;
    },
  },
  created() {},
  methods: {
    ready(item) {
      const data = {
        id: item.id,
        status: ORDER_ITEM_STATUS.READY,
      };
      item.loading = true;
      return orderItemApi
        .update(data)
        .then(res => {
          item.loading = false;
          if (res.ret == '0') {
            item.status = ORDER_ITEM_STATUS.READY;
          }
        })
        .catch(() => {
          item.loading = false;
        });
    },
    sortJsonArr(x, y) {
      if (x.item < y.item) {
        return -1;
      }
      if (x.item > y.item) {
        return 1;
      }
      return 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-data-table.simple-table::v-deep {
  border: 1px solid #d0d0d0;
  border-radius: 0px;

  .v-data-table__wrapper {
    border-radius: 0px;
    max-height: 520px;
  }
  .header {
    tr > th {
      // background-color: #cccccc;
      color: #000;
      font-weight: 500 !important;
    }
  }
  .body {
    tr > td {
      font-weight: 500 !important;
    }
  }
}
</style>
