<template>
  <PlainLayout>
    <div v-if="step == LOGIN_STEP.LOGIN" class="box-wrap">
      <div class="title-wrap d-flex justify-center mb-4">
        <!-- <strong class="mr-1 font-weight-black red--text">FOOD</strong>
        <span class="black--text">MARKET</span> -->
        <img class="logo" :src="logo" alt="logo" />
      </div>
      <ValidationObserver ref="observer1" v-slot="{ passes }">
        <v-row class="px-6">
          <v-col class="py-2" cols="12">
            <!-- <label class="form-row-col__label">
              {{ $t('login.lbl.username') }}
            </label> -->
            <TextField
              ref="username"
              v-model="form.username"
              :rules="rules.username"
              :outlined="false"
              :hide-details="false"
              :single-line="false"
              :label="$t('login.lbl.username')"
            />
          </v-col>
          <v-col class="py-2" cols="12">
            <!-- <label class="form-row-col__label">
              {{ $t('login.lbl.password') }}
            </label> -->
            <TextField
              v-model="form.password"
              :type="showPwd ? 'text' : 'password'"
              :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="rules.password"
              :outlined="false"
              :hide-details="false"
              :single-line="false"
              :label="$t('login.lbl.password')"
              @clickAppend="showPwd = !showPwd"
              @keyup.enter.native="passes(login)"
            />
          </v-col>
        </v-row>
        <div class="text-center mt-4 mb-3">
          <AppBtn class="btn-black" :height="42" :width="160" :loading="loading" @click="passes(login)">{{
            $t('login.act.login')
          }}</AppBtn>
        </div>
      </ValidationObserver>
    </div>
    <div v-else-if="step == LOGIN_STEP.SET_PWD" class="box-wrap">
      <div class="title-wrap d-flex flex-column align-center justify-center mb-6">
        <!-- <img class="logo mb-3" :src="logo" alt="logo" /> -->
        <div class="font-weight-bold">{{ $t('login.lbl.setPassword') }}</div>
      </div>
      <ValidationObserver ref="observer2" v-slot="{ passes }">
        <v-row class="px-6">
          <v-col class="py-2" cols="12">
            <TextField
              v-model="form.username"
              :rules="rules.username"
              :outlined="false"
              :hide-details="false"
              :single-line="false"
              :label="$t('login.lbl.username')"
              read-only
            />
          </v-col>
          <v-col class="py-2" cols="12">
            <TextField
              ref="newPassword"
              v-model="setPwdForm.newPassword"
              :mode="'eager'"
              :type="showNewPwd ? 'text' : 'password'"
              :append-icon="showNewPwd ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="rules.newPassword"
              :outlined="false"
              :hide-details="false"
              :single-line="false"
              :label="$t('login.lbl.newPassword')"
              :vid="'newPassword'"
              @clickAppend="showNewPwd = !showNewPwd"
            />
          </v-col>
          <v-col class="py-2" cols="12">
            <TextField
              v-model="setPwdForm.confirmNewPassword"
              :mode="'eager'"
              :type="showConfirmNewPwd ? 'text' : 'password'"
              :append-icon="showConfirmNewPwd ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="rules.confirmNewPassword"
              :outlined="false"
              :hide-details="false"
              :single-line="false"
              :label="$t('login.lbl.confirmNewPassword')"
              @clickAppend="showConfirmNewPwd = !showConfirmNewPwd"
              @keyup.enter.native="passes(setPwd)"
            />
          </v-col>
        </v-row>
        <div class="text-center mt-4 mb-3">
          <AppBtn class="btn-black" :height="42" :width="160" :loading="loading" @click="passes(setPwd)">{{
            $t('login.act.setPassword')
          }}</AppBtn>
        </div>
      </ValidationObserver>
    </div>
  </PlainLayout>
</template>
<script>
import PlainLayout from '@/layouts/plain/Index.vue';
import { authApi } from '@/api/auth';
import { mapState, mapActions } from 'vuex';

const LOGIN_STEP = {
  LOGIN: 1,
  SET_PWD: 2,
};

export default {
  name: 'Login',
  components: {
    PlainLayout,
  },
  data() {
    return {
      loading: false,
      showPwd: false,
      showNewPwd: false,
      showConfirmNewPwd: false,
      logo: require('@/assets/logo.svg'),
      form: {
        username: '',
        password: '',
      },
      setPwdForm: {
        newPassword: '',
        confirmNewPassword: '',
      },
      rules: {
        username: { required: true },
        password: { required: true },
        newPassword: {
          required: true,
          min: 5,
          max: 32,
        },
        confirmNewPassword: {
          required: true,
          confirmed: 'newPassword',
        },
      },
      step: LOGIN_STEP.LOGIN,
    };
  },
  computed: {
    ...mapState({
      defaultPageAfterLogin: state => state.app.defaultPageAfterLogin,
    }),
    LOGIN_STEP() {
      return LOGIN_STEP;
    },
  },
  methods: {
    ...mapActions(['login_act', 'processLoginSuccess_act']),
    async login() {
      const data = {
        ...this.form,
      };
      this.loading = true;
      return this.login_act(data)
        .then(async res => {
          this.loading = false;
          if (res.ret == '0') {
            await this.processLoginSuccess_act(res.data);
            this.navigateReplace(this.defaultPageAfterLogin);
          } else if (res.ret == 'NotInitialized') {
            this.step = LOGIN_STEP.SET_PWD;
            await this.$refs.observer1.reset();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setPwd() {
      const data = {
        ...this.form,
        newPassword: this.setPwdForm.newPassword,
      };
      this.loading = true;
      return authApi
        .setPwd(data)
        .then(async res => {
          this.loading = false;
          if (res.ret == '0') {
            await this.processLoginSuccess_act(res.data);
            this.navigateReplace(this.defaultPageAfterLogin);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.box-wrap {
  // background-color: #ebfdffe6;
  // border-radius: 8px;
  max-width: 450px;
  padding: 10px 30px;
  width: 100%;
  z-index: 1;
}
.title-wrap {
  font-size: 32px;
}
.logo {
  max-width: 140px;
}
</style>
