import { isUndefinedNullOrEmpty, setTimeZone, priceFormat } from '@/util/tools.js';
import { ORDER_CHANNEL } from '@/common/orderEnum';
import { orderApi } from '@/api/order';

export default {
  data() {
    return {};
  },
  methods: {
    isSelfOrder(channelId) {
      const condition1 =
        channelId == ORDER_CHANNEL.TAKEAWAY_WEB ||
        channelId == ORDER_CHANNEL.TAKEAWAY_MOBILE ||
        channelId == ORDER_CHANNEL.DINE_IN_WEB ||
        channelId == ORDER_CHANNEL.DINE_IN_MOBILE ||
        channelId == ORDER_CHANNEL.PICKUP_WEB ||
        channelId == ORDER_CHANNEL.PICKUP_MOBILE ||
        channelId == ORDER_CHANNEL.DELIVERY_WEB ||
        channelId == ORDER_CHANNEL.DELIVERY_MOBILE;

      return condition1;
    },
    isDelivery(channelId) {
      return (
        channelId == ORDER_CHANNEL.DELIVERY_POS ||
        channelId == ORDER_CHANNEL.DELIVERY_WEB ||
        channelId == ORDER_CHANNEL.DELIVERY_MOBILE
      );
    },
    isPickup(channelId) {
      return channelId == ORDER_CHANNEL.PICKUP_WEB || channelId == ORDER_CHANNEL.PICKUP_MOBILE;
    },
    getOrderDetail(id) {
      return orderApi.detail({ id }).then(res => {
        return res.data;
      });
    },
    processEditCartItems(data) {
      let oCart = [];
      let oItem = {};
      let oSubItems = [];
      let iLastIdx = null;
      let iSetQty = 1;

      oItem.itemId = 0;
      data.forEach(rec => {
        if (this.isUndefinedNullOrEmpty(iLastIdx)) iLastIdx = rec.idx;
        if (rec.idx != iLastIdx) {
          if (oSubItems.length > 0) {
            oItem.items = oSubItems;
          }
          let oNewItem = JSON.parse(JSON.stringify(oItem));
          oCart.push(oNewItem);
          oItem = {};
          oItem.itemId = 0;
          oSubItems = [];
        }
        if (oItem.itemId == 0) {
          oItem.itemId = rec.itemId;
          oItem.qty = rec.qty;
          oItem.price = rec.amt;
          oItem.label = rec.item;
          oItem.images = [{ url: '' }];
          iSetQty = rec.qty;
        } else {
          oSubItems.push({ qty: rec.qty / iSetQty, itemId: rec.itemId, name: rec.item });
        }
        iLastIdx = rec.idx;
      });
      if (oSubItems.length > 0) {
        oItem.items = oSubItems;
      }
      oCart.push(oItem);
      return oCart;
    },
  },
};
