<template>
  <div class="item-wrap d-flex flex-column align-center pa-2" @click="clicked(data)">
    <div class="w-100 h-100 d-flex justify-center align-center">
      <img class="item-img mb-1" :src="getItemImg(data.images[0].url)" alt="item" />
    </div>
    <div class="menu-title text-center mb-2">{{ data.name }}</div>
    <!-- <div class="flex-grow-1 d-flex align-end font-weight-bold">{{ `${$t('lbl.rm')}${priceFormat(data.price)}` }}</div> -->
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      defaultImg: require('@/assets/menu/bao.png'),
    };
  },
  computed: {},
  methods: {
    clicked(item) {
      this.$emit('clicked', item);
    },
    getItemImg(imgUrl) {
      if (this.isUndefinedNullOrEmpty(imgUrl)) {
        return this.defaultImg;
      }
      return imgUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.item-wrap {
  // border: 2px solid #000;
  border-radius: 4px;
  background-color: #fffaee;
  cursor: pointer;
  height: 100%;
}
.item-img {
  max-width: 120px;
  width: 100%;
  padding: 5px;
}
.menu-title {
  font-size: 1.1rem;
  font-weight: 500;
}
</style>
