import { isUndefinedNullOrEmpty } from '@/util/tools';

export function processEvtData(data) {
  const { event, values } = JSON.parse(data?.payload?.data);
  if (isUndefinedNullOrEmpty(event) || isUndefinedNullOrEmpty(values)) return null;
  return { event, values };
}

export function processEvtPayload(keys, values) {
  if (isUndefinedNullOrEmpty(keys) || isUndefinedNullOrEmpty(values)) {
    alert('Empty keys or values');
    return;
  }
  if (keys.length != values.length) {
    alert('keys and values length do not match');
    return;
  }
  let payload = {};
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    payload[key] = values[i];
  }
  return payload;
}

// Payload sample --> {"seq":0,"payload":{"ret":"0","data":"{\"event\":{\"name\":\"FCM.OrderCreated\",\"hash\":\"c042598c06ecd928b7448d87c6018a21\"},\"values\":[999,100449,339,323]}"}}
