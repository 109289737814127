<template>
  <v-navigation-drawer
    id="default-drawer"
    v-model="drawer"
    :dark="dark"
    :right="$vuetify.rtl"
    :src="drawerImage ? image : ''"
    app
    width="300"
    temporary
  >
    <template v-if="drawerImage" #img="props">
      <v-img :key="image" :gradient="gradient" v-bind="props" />
    </template>

    <div class="px-2 h-100">
      <default-drawer-header />

      <v-divider class="mx-3 mb-2" />

      <div class="d-flex justify-space-between align-center">
        <div class="mr-1 px-5 py-1 font-primary-orange font-1r">{{ getStoreName }}</div>
        <IconWrap
          class="cursor-pointer mr-2"
          :icon="'mdi-pencil'"
          :color="'#e2e2e2'"
          :size="24"
          @click.native.stop="SET_STORE_LOC_MODAL_SHOW(true)"
        />
      </div>

      <v-divider class="mx-3 my-2" />

      <div class="mb-2 mr-1 px-5 py-1 font-primary-orange font-1r d-flex align-center">
        {{ `${$t('lbl.todaySales')}: ${$t('lbl.rm')}${sales}` }}
        <v-icon class="ml-3 cursor-pointer" size="20" color="var(--primary-color)" @click="toggleShowPwd">{{
          showPwd ? 'mdi-eye' : 'mdi-eye-off'
        }}</v-icon>
      </div>

      <div class="mr-1 px-5 py-1 font-primary-orange font-1r d-flex align-center">
        {{ `${$t('lbl.cash')}: ${$t('lbl.rm')}${cash}` }}
        <v-icon class="ml-3 cursor-pointer" size="20" color="var(--primary-color)" @click="toggleShowCash">{{
          showCash ? 'mdi-eye' : 'mdi-eye-off'
        }}</v-icon>
      </div>

      <default-list :items="items" />
      <div class="font-primary-orange pl-5" style="position: absolute; bottom: 10px;">{{ version }}</div>
    </div>

    <!-- <template #append>
      <div class="pa-4 text-center">
        <app-btn
          class="text-none mb-4"
          color="white"
          href="https://vuetifyjs.com"
          small
          text
        >
          Documentation
        </app-btn>

        <app-btn
          block
          class="text-none"
          color="secondary"
          href="https://store.vuetifyjs.com/products/vuetify-material-dashboard-pro"
        >
          <v-icon left>
            mdi-package-up
          </v-icon>

          Upgrade to Pro
        </app-btn>
      </div>
    </template> -->

    <!-- <div class="pt-12" /> -->
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { priceWithCommas } from '@/util/tools';
export default {
  name: 'DefaultDrawer',
  components: {
    DefaultDrawerHeader: () =>
      import(
        /* webpackChunkName: "default-drawer-header" */
        './widgets/DrawerHeader'
      ),
    DefaultList: () =>
      import(
        /* webpackChunkName: "default-list" */
        './List'
      ),
  },
  data() {
    return {
      showPwd: false,
      showCash: false,
      version: '',
    };
  },
  computed: {
    ...mapState({
      dashStats: state => state.app.dashStats,
      drawerImage: state => state.app.drawerImage,
      items: state => state.app.items,
    }),
    ...mapGetters(['dark', 'gradient', 'image', 'getStoreName']),
    drawer: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(value) {
        this.$store.commit('SET_DRAWER', value);
      },
    },
    sales() {
      return this.showPwd ? priceWithCommas(this.priceFormat(this.dashStats.orders.sales)) : '*****';
    },
    cash() {
      return this.showCash ? priceWithCommas(this.priceFormat(this.dashStats.orders.cash)) : '*****';
    },
    // mini: {
    //   get() {
    //     return this.$store.state.app.mini;
    //   },
    //   set(value) {
    //     this.$store.commit('SET_MINI', value);
    //   },
    // },
  },
  created() {
    this.version = `Ver ${require('../../../package.json').version}`;
  },
  methods: {
    ...mapMutations(['SET_STORE_LOC_MODAL_SHOW']),
    toggleShowPwd() {
      this.showPwd = !this.showPwd;
    },
    toggleShowCash() {
      this.showCash = !this.showCash;
    },
  },
};
</script>

<style lang="scss">
#default-drawer {
  background-color: var(--primary-black-color);
  .v-list-item {
    margin-bottom: 0px;
  }
  .section {
    font-weight: 600;
  }
  .sub-section {
    &.v-list-item {
      min-height: 38px;
      // text-decoration: underline;
    }
    .v-list-item__content {
      display: inline-block;
      padding: 8px 0;
    }
    .v-list-item__title {
      border-bottom: 1px dashed #fff;
      display: inline-block;
      padding-bottom: 4px;
    }
  }

  .v-list-item::before,
  .v-list-item::after {
    display: none;
  }

  .v-list-group__header__prepend-icon,
  .v-list-item__icon {
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 4px;
  }

  &.v-navigation-drawer--mini-variant {
    .v-list-item {
      justify-content: flex-start !important;
    }
  }
}
</style>
