<template>
  <v-list-item class="mb-0 justify-space-between pl-3">
    <v-list-item-content class="pl-2 py-4">
      <v-list-item-title class="font-1dot25r">
        <strong class="mr-1 font-weight-black font-primary-orange font-1dot25r">Welcome {{ getUserName }}</strong>
      </v-list-item-title>
    </v-list-item-content>
    <!-- <v-list-item-avatar>
      <v-img :src="require('@/assets/vmd.svg')" />
    </v-list-item-avatar>

    <v-list-item-content class="pl-2">
      <v-list-item-title class="text-h3">
        <strong class="mr-1 font-weight-black red--text">FOOD</strong>

        <span class="white--text">CHAIN</span>
      </v-list-item-title>
    </v-list-item-content> -->
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'DefaultDrawerHeader',
  computed: {
    ...mapGetters(['getUserName']),
  },
};
</script>
