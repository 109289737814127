<template>
  <div class="page-wrap">
    <v-row class="mx-0">
      <v-col cols="12" sm="3" class="py-1 py-sm-3">
        <AppBtn
          class="tab-btn w-100"
          :class="active == TAB.TODAY_DELIVERY ? 'btn-black' : 'btn-orange-border'"
          :height="50"
          @click="active = TAB.TODAY_DELIVERY"
        >
          {{ $t('delivery.tab.todayDelivery') }}
        </AppBtn>
      </v-col>
    </v-row>
    <v-row class="mx-0">
      <v-col v-if="active == TAB.TODAY_DELIVERY" cols="12">
        <Today />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Today from './components/Today';

export default {
  name: 'OrderHistory',
  components: {
    Today,
  },
  data() {
    return {
      loading: false,
      TAB: {
        TODAY_DELIVERY: 1,
      },
      active: 1,
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.page-wrap {
  // overflow-y: scroll;
}
.tab-btn {
  text-transform: uppercase;
}
</style>
