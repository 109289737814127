const Layout = () => import('@/layouts/default/Index');

const Login = () => import('@/views/Login');

// const Dashboard = () => import('@/views/Dashboard');

const Home = () => import('@/views/Home');
const Cart = () => import('@/views/Cart');
const Orders = () => import('@/views/Orders');
const OrderHistory = () => import('@/views/OrderHistory');
const DeliveryOrder = () => import('@/views/DeliveryOrder');
const Stocks = () => import('@/views/Stocks');

export const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '',
    component: Layout,
    redirect: { name: 'Home' },
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home,
        meta: { requireAuth: true },
      },
      {
        path: 'cart',
        name: 'Cart',
        component: Cart,
        meta: { requireAuth: true },
      },
      {
        path: 'orders',
        name: 'Orders',
        component: Orders,
        meta: { requireAuth: true },
      },
      {
        path: 'order-history',
        name: 'OrderHistory',
        component: OrderHistory,
        meta: { requireAuth: true },
      },
      {
        path: 'delivery-order',
        name: 'DeliveryOrder',
        component: DeliveryOrder,
        meta: { requireAuth: true },
      },
      {
        path: 'stocks',
        name: 'Stocks',
        component: Stocks,
        meta: { requireAuth: true },
      },
      // {
      //   path: 'dashboard',
      //   name: 'Dashboard',
      //   component: Dashboard,
      // },
    ],
  },
];
