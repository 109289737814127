import { menuApi, menuItemApi, menuItemOptApi, menuItemOptGrpApi, menuItemOptGrpSubApi } from '@/api/menu';
// import AppStorage from '@/plugins/storage/index.js';

const menu = {
  state: {
    menuList: [],
    menuItemList: [],
    menuItemOptList: [],
    menuItemOptGrpList: [],
    menuItemOptGrpSubList: [],
  },
  getters: {
    // dark: (state, getters) => {
    //   return state.dark || getters.gradient.indexOf('255, 255, 255') === -1;
    // },
  },
  mutations: {
    SET_MENU_LIST(state, payload) {
      state.menuList = payload;
    },
    SET_MENU_ITEM_LIST(state, payload) {
      state.menuItemList = payload;
    },
    SET_MENU_ITEM_OPT_LIST(state, payload) {
      state.menuItemOptList = payload;
    },
    SET_MENU_ITEM_OPT_GRP_LIST(state, payload) {
      state.menuItemOptGrpList = payload;
    },
    SET_MENU_ITEM_OPT_GRP_SUB_LIST(state, payload) {
      state.menuItemOptGrpSubList = payload;
    },
  },
  actions: {
    fetchMenuList({ commit }, data) {
      return menuApi.list(data).then(response => {
        const data = response.data;
        commit('SET_MENU_LIST', data);
        return response;
      });
    },
    fetchMenuItemList({ commit }, data) {
      return menuItemApi.list(data).then(response => {
        const data = response.data;
        commit('SET_MENU_ITEM_LIST', data);
        return response;
      });
    },
    fetchMenuItemOptList({ commit }, data) {
      return menuItemOptApi.list(data).then(response => {
        const data = response.data;
        commit('SET_MENU_ITEM_OPT_LIST', data);
        return response;
      });
    },
    fetchMenuItemOptGrpList({ commit }, data) {
      return menuItemOptGrpApi.list(data).then(response => {
        const data = response.data;
        commit('SET_MENU_ITEM_OPT_GRP_LIST', data);
        return response;
      });
    },
    fetchMenuItemOptGrpSubList({ commit }, data) {
      return menuItemOptGrpSubApi.list(data).then(response => {
        const data = response.data;
        commit('SET_MENU_ITEM_OPT_GRP_SUB_LIST', data);
        return response;
      });
    },
  },
};
export default menu;
