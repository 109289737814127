export default {
  map: {
    orderStatus: {
      0: 'Draft',
      1: 'Closed',
      2: 'Failed',
      3: 'Expired',
      4: 'Voided',
      7: 'Picked Up',
      8: 'Ready',
      9: 'Confirmed',
      11: 'Pending Payment',
    },
    orderItemStatus: {
      0: 'Draft',
      1: 'Closed',
      4: 'Voided',
      8: 'Ready',
      9: 'Placed',
    },
    stockOrderStatus: {
      0: 'New',
      1: 'Confirmed',
      2: 'Approved',
      3: 'Deleted',
      4: 'Cancelled',
      8: 'Picked up',
      9: 'Completed',
    },
    deliveryStatus: {
      0: 'Pending',
      1: 'Delivered',
      2: 'Error',
      3: 'Rejected',
      4: 'Cancelled',
      5: 'Assigned',
      6: 'Coming',
      7: 'Picked up',
      8: 'Assigning',
      9: 'Placed',
    },
  },
};
