import Vue from 'vue';
import Vuex from 'vuex';

import app from './modules/app';
import cart from './modules/cart';
import lookup from './modules/lookup';
import menu from './modules/menu';
import order from './modules/order';
import store from './modules/store';
import user from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    cart,
    lookup,
    menu,
    order,
    store,
    user,
  },
});
