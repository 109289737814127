<template>
  <div>
    <CommonModal v-model="show" :modal-size="'modal-lg'">
      <template #header>
        <div class="modal-header">
          <div class="d-flex justify-space-between align-center">
            <div class="modal-header__title">{{ data.name }}</div>
            <v-icon color="#fff" @click="close">mdi-close</v-icon>
          </div>
        </div>
      </template>
      <template #body-content>
        <div class="mb-4">
          <v-row class="mx-0 my-0">
            <template v-for="(item, index) in list">
              <v-col v-if="item.flag != MENU_ITEM_FLAG.HIDE" :key="index" cols="12" sm="6" md="6" class="px-1 py-1">
                <div class="item-wrap d-flex px-2 py-2 px-sm-2 py-sm-1">
                  <div class="item-left d-flex align-center text-center mr-2" style="width: 18%">
                    <img class="item-img" :src="getItemImg(item.images[0].url)" alt="item" />
                  </div>
                  <div class="item-right d-flex flex-column justify-center align-start px-1" style="width: 48%">
                    <div class="item-label mb-2">{{ item.name }}</div>
                    <div class="item-price font-weight-bold">{{ `${$t('lbl.rm')}${priceFormat(item.price)}` }}</div>
                  </div>
                  <div class="d-flex align-center" style="width: 36%">
                    <PlusMinusField v-model="form[item.itemId].count" class="plus-minus-field" filled outlined dense />
                  </div>
                </div>
              </v-col>
            </template>
          </v-row>
        </div>
      </template>
      <template #footer>
        <div class="modal-footer">
          <div class="d-flex justify-space-between align-center">
            <div class="d-flex flex-column justify-center">
              <div class="total-text">{{ $t('lbl.total') }}</div>
              <div class="total-price">{{ `${$t('lbl.rm')}${priceFormat(totalPrice)}` }}</div>
            </div>
            <AppBtn class="btn-orange" :loading="loading" @click="preAddToCart">{{ $t('act.addToCart') }}</AppBtn>
          </div>
        </div>
      </template>
    </CommonModal>
    <MessageModal
      v-if="errMsgModalShow"
      v-model="errMsgModalShow"
      :body-content-styles="{ 'font-size': '1.1rem', 'font-weight': '500' }"
      :modal-size="'modal-xs-2'"
      :header-title="msgModalTitle"
      :msg="msgModalBody"
      @confirm="errMsgModalShow = false"
    >
      <template #header>
        <div></div>
      </template>
    </MessageModal>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import CommonModal from '@/components/Modal';
import { MENU_GRP, MENU_ITEM_FLAG } from '@/common/enum';

export default {
  name: 'BeverageModal',
  components: {
    CommonModal,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultImg: require('@/assets/logo.svg'),

      form: {},
      list: [],
      loading: false,

      totalPrice: 0,

      errMsgModalShow: false,
      msgModalTitle: '',
      msgModalBody: 'You have not selected any items.',
    };
  },
  computed: {
    ...mapState({
      menuList: state => state.menu.menuList,
      menuItemList: state => state.menu.menuItemList,
      menuItemOptList: state => state.menu.menuItemOptList,
      menuItemOptGrpList: state => state.menu.menuItemOptGrpList,
      menuItemOptGrpSubList: state => state.menu.menuItemOptGrpSubList,
    }),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    MENU_ITEM_FLAG() {
      return MENU_ITEM_FLAG;
    },
  },
  watch: {
    form: {
      deep: true,
      handler(value) {
        let totalPrice = 0;
        for (let i = 0; i < Object.keys(value).length; i++) {
          let item = value[Object.keys(value)[i]];
          totalPrice = totalPrice + item.count * item.price;
        }
        this.totalPrice = totalPrice;
      },
    },
  },
  created() {
    let menuItems = this.menuItemList.filter(item => item.menuId == this.data.id);

    for (let item of menuItems) {
      this.$set(this.form, item.itemId, {
        ...item,
        count: 0,
      });
    }
    this.list = menuItems;
  },
  methods: {
    ...mapActions(['addToCart']),
    close() {
      this.$emit('input', false);
    },
    clicked(menu) {
      switch (menu.grp) {
        case MENU_GRP.SET:
          this.$emit('promoClick', { setId: menu.setId, recId: menu.recId });
          break;
        default:
          break;
      }
    },
    getItemImg(imgUrl) {
      if (this.isUndefinedNullOrEmpty(imgUrl)) {
        return this.defaultImg;
      }
      return imgUrl;
    },
    preAddToCart() {
      let promises = [];

      for (let key in this.form) {
        let item = this.form[key];
        if (item.count > 0) {
          promises.push(
            this.addToCart({
              qty: item.count,
              price: item.price,
              label: item.label,
              images: item.images,
              itemId: item.itemId,
            })
          );
        }
      }

      if (promises.length > 0) {
        this.loading = true;
        return Promise.all(promises)
          .then(() => {
            this.loading = false;
            this.$emit('success');
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.errMsgModalShow = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../../styles/mixin.scss';
.modal-header {
  background-color: #000;
  position: sticky;
  top: 0;
  &__title {
    color: #fff !important;
    font-size: 1.25rem;
    font-weight: 600;
  }
}
.bao-grp-wrap {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0.75rem;
  row-gap: 0.3rem;

  @include for-sm {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
}
.item-wrap {
  border: 2px solid #000;
  border-radius: 5px;
}

.item-img {
  max-width: 70px;
  width: 100%;
  @include for-sm {
    max-width: 70px;
  }
}
.item-label {
  color: var(--primary-black-color);
  font-size: 0.9rem;
  font-weight: 500;
}
.item-price {
  color: var(--red-common);
  font-size: 0.9rem;
  font-weight: 500;
}
.total-text {
  color: #000;
  font-size: 1.25rem;
}
.total-price {
  color: var(--red-common);
  font-size: 1.5rem;
  font-weight: 500;
}
.modal-footer {
  background-color: #e0e0e0;
  border-top: 1px solid #bdbdbd;
  bottom: 0;
  position: sticky;
}
.plus-minus-field-wrap {
  max-width: 80%;
  margin: 0 auto;
}
</style>
