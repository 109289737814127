<template>
  <CommonModal v-model="show" :modal-size="'modal-sm-2'" :footer="true" persistent>
    <template #header>
      <div class="modal-header">
        <slot name="header-content">
          <div class="d-flex justify-space-between align-center">
            <div class="modal-header__title">{{ 'Update Store Location' }}</div>
            <v-icon v-if="getStoreLocSet == 1" color="#fff" @click="close">mdi-close</v-icon>
          </div>
        </slot>
      </div>
    </template>
    <template #body-content>
      <ValidationObserver ref="observer">
        <div class="body-wrap px-1">
          <v-row>
            <v-col class="pb-0" cols="12" sm="12">
              <div class="d-flex justify-space-between align-center">
                <div class="mb-1">{{ $t('store.lbl.storeLoc') }} <span v-if="rules.locId.required" class="font-red">*</span></div>
                <div v-if="!isNewLoc" class="font-12 font-italic cursor-pointer" style="color:#1867c0;" @click="newLocClicked">
                  New Location? Click here
                </div>
              </div>
              <Select
                v-model="form.locId"
                :rules="rules.locId"
                :options="storeLocList"
                :label="$t('store.lbl.storeLoc')"
                :placeholder="$t('lbl.plsSelect')"
                :clearable="false"
                @change="storeLocChg"
              />
            </v-col>
            <v-col class="pb-0" cols="12" sm="12">
              <div class="mb-1">{{ $t('store.lbl.address') }} <span v-if="rules.address.required" class="font-red">*</span></div>
              <TextField v-model="form.address" :rules="rules.address" :label="$t('store.lbl.address')" />
            </v-col>
            <v-col class="pb-0" cols="12" sm="12">
              <div class="mb-1">
                {{ $t('store.lbl.postcode') }} <span v-if="rules.postcode.required" class="font-red">*</span>
              </div>
              <TextField
                v-model="form.postcode"
                :rules="rules.postcode"
                :max-length="postcodeLength"
                :label="$t('store.lbl.postcode')"
                :disabled="!isNewLoc"
              />
            </v-col>
            <v-col class="pb-0" cols="12" sm="12">
              <div class="mb-1">{{ $t('store.lbl.city') }} <span v-if="rules.city.required" class="font-red">*</span></div>
              <TextField v-model="form.city" :rules="rules.city" :label="$t('store.lbl.city')" :disabled="!isNewLoc" />
            </v-col>
            <v-col class="pb-4" cols="12" sm="12">
              <div class="mb-1">
                {{ $t('store.lbl.province') }} <span v-if="rules.province.required" class="font-red">*</span>
              </div>
              <Select
                v-model="form.province"
                :rules="rules.province"
                :options="provinceList"
                :label="$t('store.lbl.province')"
                :placeholder="$t('lbl.plsSelect')"
                :clearable="false"
                :disabled="!isNewLoc"
                @keyup.enter.native="confirm"
              />
            </v-col>
          </v-row>
        </div>
      </ValidationObserver>
    </template>
    <template #footer-content>
      <div class="text-right">
        <AppBtn class="btn-orange" :loading="loading" @click="confirm">{{ modalBtnText }}</AppBtn>
      </div>
    </template>
  </CommonModal>
</template>
<script>
import CommonModal from '@/components/Modal';
import { storeLocApi, gisLocApi } from '@/api/store';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  components: {
    CommonModal,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // modalShow: false,
      modalBtnText: this.$t('act.confirm'),
      paginationOpts: {
        page: 1,
        pageSize: 50,
      },
      storeLocList: [],
      selectedStore: {},
      form: {
        locId: null,
        address: '',
        postcode: '',
        city: '',
        province: '',
      },
      rules: {
        locId: { required: true },
        address: { required: true },
        postcode: { required: true, length: 5 },
        city: { required: true },
        province: { required: true },
      },
      postcodeLength: 5,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      provinceList: state => state.lookup.provinceList,
    }),
    ...mapGetters(['getLocId', 'getStoreLocSet']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isNewLoc() {
      return this.form.locId == 0;
    },
  },
  async created() {
    this.getStoreLocList();
    if (this.getLocId) {
      this.form.locId = Number(this.getLocId);
      const locDetail = await this.getStoreLocDetail(this.getLocId);
      this.form.address = locDetail?.address;
      this.form.postcode = locDetail?.postcode;
      this.form.city = locDetail?.city;
      this.form.province = locDetail?.province;
    }
    if (this.isUndefinedNullOrEmpty(this.provinceList)) {
      this.fetchProvinceList();
    }
  },
  methods: {
    ...mapActions(['fetchProvinceList']),
    ...mapMutations(['SET_STORE_LOC_FLAG', 'SET_LOC_ID']),
    close() {
      this.$emit('input', false);
    },
    async confirm() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;

      const data = {
        ...this.form,
      };
      try {
        this.loading = true;
        const res = await storeLocApi.update(data);

        this.showSuccess(this.$t('success.update'));
        this.SET_STORE_LOC_FLAG(1);
        this.SET_LOC_ID(res.data.locId);
        this.$emit('input', false);

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    getStoreLocList() {
      const params = {
        ...this.paginationOpts,
      };
      return gisLocApi.list(params).then(res => {
        let storeLocList = res.data.map(item => {
          return {
            ...item,
            text: item.name,
            value: item.id,
          };
        });
        storeLocList.push({
          text: 'New Location',
          value: 0,
        });
        this.storeLocList = storeLocList;
      });
    },
    getStoreLocDetail(locId) {
      return gisLocApi.detail({ id: locId }).then(res => {
        return res.data;
      });
    },
    async storeLocChg(locId) {
      if (locId == 0) {
        this.newLocClicked();
        return;
      }
      const locObj = await this.getStoreLocDetail(locId);

      this.form.address = locObj.address;
      this.form.postcode = locObj.postcode;
      this.form.city = locObj.city;
      this.form.province = locObj.province;
    },
    newLocClicked() {
      this.form.locId = 0;
      this.form.address = '';
      this.form.postcode = '';
      this.form.city = '';
      this.form.province = '';
    },
  },
};
</script>
