<template>
  <v-icon class="ml-3 mr-4" size="60" color="#000" @click="drawer = !drawer">
    {{ drawer ? 'mdi-format-list-bulleted' : 'mdi-menu' }}
  </v-icon>
  <!-- <v-btn class="ml-3 mr-4" elevation="1" fab small @click="drawer = !drawer">
    <v-icon>
      {{ drawer ? 'mdi-format-list-bulleted' : 'mdi-menu' }}
    </v-icon>
  </v-btn> -->
</template>

<script>
export default {
  name: 'DefaultDrawerToggle',

  computed: {
    drawer: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(value) {
        this.$store.commit('SET_DRAWER', value);
      },
    },
  },
};
</script>
