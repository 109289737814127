<template>
  <div class="page-wrap">
    <v-row class="mx-0">
      <v-col cols="12" sm="4" class="py-1 py-sm-3">
        <AppBtn
          class="tab-btn w-100"
          :class="active == TAB.STOCK_LEVEL ? 'btn-black' : 'btn-orange-border'"
          :height="50"
          @click="active = TAB.STOCK_LEVEL"
        >
          {{ $t('stocks.tab.stockLevel') }}
          <Badge v-if="dashStats.stock.par > 0" class="ml-2" :count="dashStats.stock.par" :bg-color="'var(--badge-red)'" />
          <Badge v-if="dashStats.stock.low > 0" class="ml-2" :count="dashStats.stock.low" :bg-color="'var(--badge-blue)'" />
        </AppBtn>
      </v-col>
      <v-col cols="12" sm="4" class="py-1 py-sm-3">
        <AppBtn
          class="tab-btn w-100"
          :class="active == TAB.REFILL_DO ? 'btn-black' : 'btn-orange-border'"
          :height="50"
          @click="active = TAB.REFILL_DO"
          >{{ $t('stocks.tab.refillDO') }}</AppBtn
        >
      </v-col>
      <v-col cols="12" sm="4" class="py-1 py-sm-3">
        <AppBtn
          class="tab-btn w-100"
          :class="active == TAB.WASTAGE ? 'btn-black' : 'btn-orange-border'"
          :height="50"
          @click="active = TAB.WASTAGE"
          >{{ $t('stocks.tab.wastage') }}</AppBtn
        >
      </v-col>
    </v-row>
    <v-row class="mx-0">
      <v-col v-if="active == TAB.STOCK_LEVEL" cols="12">
        <StockLevel />
      </v-col>
      <v-col v-if="active == TAB.REFILL_DO" cols="12">
        <RefillDO />
      </v-col>
      <v-col v-if="active == TAB.WASTAGE" cols="12">
        <!-- <CollectQueue /> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { dashApi } from '@/api/dash';
// import CollectQueue from './components/CollectQueue';
import RefillDO from './components/RefillDO';
import StockLevel from './components/StockLevel';

export default {
  name: 'Orders',
  components: {
    // CollectQueue,
    RefillDO,
    StockLevel,
  },
  data() {
    return {
      loading: false,
      TAB: {
        STOCK_LEVEL: 1,
        REFILL_DO: 2,
        WASTAGE: 3,
      },
      active: 1,
    };
  },
  computed: {
    ...mapState({
      dashStats: state => state.app.dashStats,
    }),
  },
  created() {
    this.getDashboardStats();
  },
  methods: {
    ...mapMutations(['SET_DASH_STATS']),
    getDashboardStats() {
      return dashApi.dashboardStats().then(res => {
        this.SET_DASH_STATS(res.data);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  // overflow-y: scroll;
}
.tab-btn {
  text-transform: uppercase;
}
</style>
