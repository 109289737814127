// import { menuApi, menuItemApi, menuItemOptApi, menuItemOptGrpSubApi } from '@/api/menu';
import AppStorage from '@/plugins/storage/index.js';
import { isUndefinedNullOrEmpty } from '@/util/tools.js';

function itemsMatched(data, list) {
  if (data.length != list.length) return false;

  let sortedData = data.sort();
  let sortedList = list.sort();
  for (let i = 0; i < sortedList.length; i++) {
    if (sortedList[i].itemId != sortedData[i].itemId) return false;
  }
  return true;
}

const cart = {
  state: {
    cart: [],
    editOrderObj: {},
    currOrderId: null,
  },
  getters: {
    getCartItems: state => {
      if (!isUndefinedNullOrEmpty(state.cart)) return state.cart;
      else if (!isUndefinedNullOrEmpty(AppStorage.local.get('cart'))) {
        return JSON.parse(AppStorage.local.get('cart'));
      } else return [];
    },
    getCartItemsCount: state => {
      if (!isUndefinedNullOrEmpty(state.cart)) return state.cart.length;
      else if (!isUndefinedNullOrEmpty(AppStorage.local.get('cart'))) {
        const cart = JSON.parse(AppStorage.local.get('cart'));
        return cart.length > 0 ? cart.length : 0;
      } else return 0;
    },
    getEditOrder: state => {
      if (!isUndefinedNullOrEmpty(state.editOrderObj)) return state.editOrderObj;
      else if (!isUndefinedNullOrEmpty(AppStorage.local.get('editOrderObj'))) {
        return JSON.parse(AppStorage.local.get('editOrderObj'));
      } else {
        return {};
      }
    },
  },
  mutations: {
    ADD_TO_CART(state, payload) {
      state.cart = payload;
      AppStorage.local.set('cart', JSON.stringify(payload));
    },
    UPDATE_WHOLE_CART(state, payload) {
      state.cart = payload;
      AppStorage.local.set('cart', JSON.stringify(payload));
    },
    REMOVE_FROM_CART(state, payload) {
      // state.cart = payload;
      state.cart.splice(payload.index, 1);

      AppStorage.local.set('cart', JSON.stringify(state.cart));
    },
    CLEAR_CART(state) {
      state.cart = [];
      AppStorage.local.remove('cart');
    },
    UPDATE_EDIT_ORDER(state, payload) {
      state.editOrderObj = payload;
      isUndefinedNullOrEmpty(payload)
        ? AppStorage.local.remove('editOrderObj')
        : AppStorage.local.set('editOrderObj', JSON.stringify(payload));
    },
  },
  actions: {
    addToCart({ commit, getters }, data) {
      let cart = getters.getCartItems;

      // Handle duplicate item logic
      let pushed = false;
      for (let i = 0; i < cart.length; i++) {
        let current = cart[i];
        if (current.itemId == data.itemId) {
          if (isUndefinedNullOrEmpty(current.items) && isUndefinedNullOrEmpty(data.items)) {
            cart[i].qty += data.qty;
            pushed = true;
            break;
          }
          if (!isUndefinedNullOrEmpty(current.items) && !isUndefinedNullOrEmpty(data.items)) {
            if (itemsMatched(data.items, current.items)) {
              cart[i].qty += data.qty;
              pushed = true;
              break;
            }
          }
        }
      }
      if (!pushed) cart.push(data);
      commit('ADD_TO_CART', cart);
    },
    updateWholeCart({ commit, getters }, data) {
      commit('UPDATE_WHOLE_CART', data);
    },
    removeFromCart({ commit, state }, data) {
      commit('REMOVE_FROM_CART', data);
    },
    clearCart({ commit, state }) {
      commit('CLEAR_CART');
    },
    // fetchMenuList({ commit }, data) {
    //   return menuApi.list(data)
    //     .then(response => {
    //       const data = response.data;
    //       commit('SET_MENU_LIST', data);
    //       return response;
    //     });
    // },
  },
};
export default cart;
