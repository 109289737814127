<template>
  <div>
      <v-row class="ma-n2">
        <v-col cols="12" sm="6">
    <v-simple-table class="table-wrap simple-table mb-4" fixed-header height="75vh">
      <template v-slot:default>
        <thead class="header">
          <tr>
            <th class="text-left" style="width: 70%">
              {{ $t('orders.lbl.food') }}
            </th>
            <th class="text-left" style="width: 30%">
              {{ $t('orders.lbl.quantity') }}
            </th>
          </tr>
        </thead>
        <tbody class="body">
          <tr v-for="(item, index) in foodList" :key="index">
            <td style="height: 32px; font-size: 0.9rem;">{{ item.label }}</td>
            <td style="height: 32px; font-size: 0.9rem;">{{ item.qty }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
        </v-col>
        <v-col cols="12" sm="6">
    <v-simple-table class="table-wrap simple-table" fixed-header height="75vh">
      <template v-slot:default>
        <thead class="header">
          <tr>
            <th class="text-left" style="width: 70%">
              {{ $t('orders.lbl.beverage') }}
            </th>
            <th class="text-left" style="width: 30%">
              {{ $t('orders.lbl.quantity') }}
            </th>
          </tr>
        </thead>
        <tbody class="body">
          <tr v-for="(item, index) in beverageList" :key="index">
            <td style="height: 32px; font-size: 0.9rem;">{{ item.label }}</td>
            <td style="height: 32px; font-size: 0.9rem;">{{ item.qty }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
        </v-col>
      </v-row>
  </div>
</template>
<script>
import { orderItemListApi } from '@/api/order';
export default {
  name: 'PrepareList',
  data() {
    return {
      foodList: [],
      beverageList: [],
    };
  },
  created() {
    this.getCombinedOpenJobList();
  },
  methods: {
    getCombinedOpenJobList() {
      const data = {
        page: 1,
        pageSize: 100,
      };
      return orderItemListApi.combinedOpenJobList(data).then(res => {
        this.foodList = res.data.filter(item => item.type == 1);
        this.beverageList = res.data.filter(item => item.type == 2);
      });
    },
  },
};
</script>
