<template>
  <div>
    <CommonModal v-model="show" :modal-size="'modal-lg'">
      <template #header>
        <div class="modal-header">
          <div class="d-flex justify-space-between align-center">
            <div class="d-flex align-center">
              <v-icon v-if="step == 2" class="mr-3" color="#fff" @click="back">mdi-arrow-left</v-icon>
              <div class="modal-header__title">
                {{ data.name }} <span v-if="!isUndefinedNullOrEmpty(baoObj)">- {{ baoObj.name }}</span>
              </div>
            </div>
            <v-icon color="#fff" @click="close">mdi-close</v-icon>
          </div>
        </div>
      </template>
      <template #body-content>
        <div>
          <div v-if="step == 1">
            <div class="bao-grp-wrap">
              <div
                v-for="(item, index) in menuItemArr"
                :key="index"
                class="item-wrap text-center cursor-pointer px-1 py-1"
                @click="selectBao(index, item.itemId)"
              >
                <div class="mb-1">
                  <img class="item-img" :src="getItemImg(item.images[0].url)" alt="item" />
                </div>
                <div class="item-label mb-1">{{ item.name }}</div>
                <div class="item-price font-weight-bold">{{ `${$t('lbl.rm')}${priceFormat(item.price)}` }}</div>
              </div>
            </div>
          </div>
          <div v-else>
            <!-- <div class="d-flex px-2 py-0">
              <div class="item-left text-center mr-2">
                <img class="item-img" :src="getItemImg(baoObj.images[0].url)" alt="item" />
              </div>
              <div class="item-right d-flex flex-column justify-center align-start flex-grow-1 px-1">
                <div class="item-label mb-1">{{ `${baoObj.name}` }}</div>
                <div class="item-price font-weight-bold">{{ `${$t('lbl.rm')}${priceFormat(baoObj.price)}` }}</div>
              </div>
            </div> -->

            <!-- <v-divider class="set-divider my-2"></v-divider> -->

            <!-- Sauce start -->
            <div class="font-weight-medium mb-2">Choose Sauce</div>
            <div class="bao-grp-wrap">
              <div
                v-for="(item, index) in sauceItemArr"
                :key="index"
                class="item-wrap text-center cursor-pointer px-1 py-1"
                :class="{ selected: item.itemId == selectedSauce }"
                @click="selectedSauce = item.itemId"
              >
                <div class="mb-1 py-1">
                  <img class="item-img" :src="getItemImg(item.images[0].url)" alt="item" />
                </div>
                <div class="item-label mb-1">{{ item.name }}</div>
              </div>
            </div>
            <!-- Sauce end -->

            <v-divider class="set-divider my-2"></v-divider>

            <!-- Beverage start -->
            <div class="font-weight-medium mb-2">Beverage</div>
            <div class="bao-grp-wrap">
              <div
                v-for="(item, index) in beverageArr"
                :key="index"
                class="item-wrap text-center cursor-pointer px-1 py-1"
                :class="{ selected: item.itemId == selectedBeverage }"
                @click="selectBeverage(item)"
              >
                <div class="mb-1 py-1">
                  <img class="item-img" :src="getItemImg(item.images[0].url)" alt="item" />
                </div>
                <div class="item-label mb-1">{{ item.name }}</div>
              </div>
            </div>
            <!-- Beverage end -->

            <v-divider class="set-divider my-2"></v-divider>

            <!-- Add on items start -->
            <template v-if="data.id == boxMenuId">
              <div class="font-weight-medium mb-2">Add On</div>
              <div class="add-on-grp-wrap">
                <div
                  v-for="(item, index) in addOnArr"
                  :key="index"
                  class="item-wrap d-flex text-center cursor-pointer pa-2"
                  @click="addOnForm[item.itemId].selected = !addOnForm[item.itemId].selected"
                >
                  <div class="item-left text-center">
                    <img class="item-img-small" :src="getItemImg(item.images[0].url)" alt="item" />
                  </div>
                  <div class="item-right d-flex flex-column justify-center align-start flex-grow-1 px-1">
                    <div class="item-label mb-1">{{ item.name }}</div>
                    <div class="item-price font-weight-bold">{{ `+${$t('lbl.rm')}${priceFormat(item.price)}` }}</div>
                  </div>
                  <div class="d-flex align-center">
                    <Checkbox v-model="addOnForm[item.itemId].selected" @click.native.stop="" />
                  </div>
                </div>
              </div>
              <v-divider class="set-divider my-2"></v-divider>
            </template>
            <!-- Add on items end -->

            <!-- Extra sauce start -->
            <div class="font-weight-medium mb-2">Extras</div>
            <div class="add-on-grp-wrap">
              <div
                v-for="(item, index) in sauceArr"
                :key="index"
                class="item-wrap d-flex text-center cursor-pointer pa-2"
                @click="sauceForm[item.itemId].selected = !sauceForm[item.itemId].selected"
              >
                <div class="item-left text-center">
                  <img class="item-img-small" :src="getItemImg(item.images[0].url)" alt="item" />
                </div>
                <div class="item-right d-flex flex-column justify-center align-start flex-grow-1 px-1">
                  <div class="item-label mb-1">{{ item.name }}</div>
                  <div class="item-price font-weight-bold">{{ `+${$t('lbl.rm')}${priceFormat(item.price)}` }}</div>
                </div>
                <div class="d-flex align-center">
                  <Checkbox v-model="sauceForm[item.itemId].selected" @click.native.stop="" />
                </div>
              </div>
            </div>
            <!-- Extra sauce end -->

            <v-divider class="set-divider my-2"></v-divider>
          </div>
        </div>
      </template>
      <template #footer>
        <div v-if="step == 2" class="modal-footer" :style="footerStyle">
          <div class="d-flex justify-space-between align-center">
            <div class="d-flex flex-column justify-center">
              <div class="total-text">{{ $t('lbl.total') }}</div>
              <div class="total-price">{{ `${$t('lbl.rm')}${priceFormat(setTotalPrice)}` }}</div>
            </div>
            <div class="d-flex justify-center">
              <PlusMinusField v-model="qty" class="qty-bar px-4" :min-value="1" filled outlined dense />
            </div>
            <AppBtn class="btn-orange" :loading="loading" @click="preAddToCart">{{ $t('act.addToCart') }}</AppBtn>
          </div>
        </div>
      </template>
    </CommonModal>
    <MessageModal
      v-if="errMsgModalShow"
      v-model="errMsgModalShow"
      :body-content-styles="{ 'font-size': '1.1rem', 'font-weight': '500' }"
      :modal-size="'modal-xs-2'"
      :header-title="msgModalTitle"
      :msg="msgModalBody"
      @confirm="errMsgModalShow = false"
    >
      <template #header>
        <div></div>
      </template>
    </MessageModal>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import CommonModal from '@/components/Modal';
import { MENU_GRP } from '@/common/enum';

export default {
  name: 'AddItemOptModal',
  components: {
    CommonModal,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
    preSelectedData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      step: 1,
      baoArr: [],
      baoForm: [],
      loading: false,
      defaultImg: require('@/assets/logo.svg'),

      promotionItems: [],
      promotionItemMenuId: 9,

      beverageBasePrice: 0,

      setTotalPrice: 0,
      setBasePrice: 0,
      setPrice: 0,
      beveragePrice: 0,
      qty: 1,

      beverageGrpItemsArr: [],
      beverageForm: {},

      baoGroupErrArr: [],
      selectedDrink: null,
      // setQty: 1,

      errMsgModalShow: false,
      msgModalTitle: '',
      msgModalBody: 'Please select all required items.',

      boxMenuId: 6,

      baoItemId: null,
      baoObj: {},
      beverageRequired: false,
      beverageArr: [],
      beverageForm2: {},
      menuItemDetails: {},
      sauceItemArr: [],

      addOnMenuId: 13, // from /menu/item/list
      addOnArr: [],
      addOnForm: {},
      addOnTotalAmt: 0,

      sauceMenuId: 15, // from /menu/item/list
      sauceArr: [],
      sauceForm: {},
      sauceTotalAmt: 0,

      selectedSauce: null,
      selectedBeverage: null,

      footerStyle: {
        backgroundColor: 'var(--grey-lighten-1)',
        bottom: 0,
        position: 'sticky',
      },
    };
  },
  computed: {
    ...mapState({
      menuList: state => state.menu.menuList,
      menuItemList: state => state.menu.menuItemList,
      menuItemOptList: state => state.menu.menuItemOptList,
      menuItemOptGrpList: state => state.menu.menuItemOptGrpList,
      menuItemOptGrpSubList: state => state.menu.menuItemOptGrpSubList,
    }),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isFixedPriceSet() {
      return this.data.grp == MENU_GRP.FIXED_PRICE;
    },
    freeDrinks() {
      return this.beverageGrpItemsArr[0][0];
    },
  },
  watch: {
    // baoForm: {
    //   deep: true,
    //   handler() {
    //     this.setBasePrice = this.setPrice + this.beveragePrice;
    //     this.setTotalPrice = this.setPrice + this.beveragePrice;
    //     this.qty = 1; // reset qty to 1
    //   },
    // },
    // beverageForm: {
    //   deep: true,
    //   handler(value) {
    //     let count = 0;
    //     this.beveragePrice = 0;
    //     for (let key in value) {
    //       count = count + value[key].count;
    //       this.beveragePrice = this.beveragePrice + value[key].count * value[key].addPrice;
    //     }
    //     this.setBasePrice = this.setPrice + this.beveragePrice;
    //     this.setTotalPrice = this.setPrice + this.beveragePrice;
    //     this.qty = 1; // reset qty to 1
    //   },
    // },
    addOnForm: {
      deep: true,
      handler(value) {
        let addOnTotalAmt = 0;
        for (let key in value) {
          if (value[key].selected) {
            addOnTotalAmt += value[key].price;
          }
        }
        this.addOnTotalAmt = addOnTotalAmt;

        this.setBasePrice = this.setPrice + this.sauceTotalAmt + addOnTotalAmt;
        this.setTotalPrice = this.setBasePrice * this.qty;
      },
    },
    sauceForm: {
      deep: true,
      handler(value) {
        let sauceTotalAmt = 0;
        for (let key in value) {
          if (value[key].selected) {
            sauceTotalAmt += value[key].price;
          }
        }
        this.sauceTotalAmt = sauceTotalAmt;

        this.setBasePrice = this.setPrice + this.addOnTotalAmt + sauceTotalAmt;
        this.setTotalPrice = this.setBasePrice * this.qty;
      },
    },
    qty(value) {
      this.setTotalPrice = this.setBasePrice * value;
    },
  },
  created() {
    const baoItem = this.menuItemList.find(item => item.menuId == this.data.id);
    const baoOptGrp = baoItem.optGrp[0];
    let baoOptGrpSub = this.menuItemOptGrpSubList.find(item => item.itemId == baoItem.itemId && item.grpId == baoOptGrp);
    const baoOptItemIds = baoOptGrpSub.optItemIds;

    this.menuItemArr = baoOptItemIds.map(item => {
      const menuItem = this.menuItemList.find(menuItem => menuItem.itemId == item);
      const optItem = this.menuItemOptList.find(optItem => optItem.itemId == item && optItem.grpId == baoOptGrp);
      return {
        ...menuItem,
        price: menuItem.price + baoItem.price,
        images: optItem.images,
      };
    });

    // const baoFirstItem = this.menuItemList.find(item => item.menuId == this.data.id);

    // const baoOptGrp = baoFirstItem.optGrp.slice(0, baoFirstItem.optGrp.length - 1);

    // for (let optGrpId of baoOptGrp) {
    //   const matchedGrpSub = this.menuItemOptGrpSubList.find(
    //     grpSub => grpSub.itemId == baoFirstItem.itemId && grpSub.grpId == optGrpId
    //   );
    //   const items = matchedGrpSub.optItemIds.map(itemId => {
    //     const menuItemOpt = this.menuItemOptList.find(itemOpt => itemOpt.grpId == optGrpId && itemOpt.itemId == itemId);
    //     this.promotionItems = this.menuItemList.filter(item => item.menuId == this.promotionItemMenuId);

    //     const promoItemObj = this.promotionItems.find(
    //       promoItem => promoItem.recId == menuItemOpt.itemId && promoItem.setId == baoFirstItem.menuId
    //     );
    //     return {
    //       itemId: menuItemOpt.itemId,
    //       name: menuItemOpt.name,
    //       label: menuItemOpt.label,
    //       images: this.menuItemList.find(item => item.itemId == menuItemOpt.itemId).images,
    //       promoPrice: !this.isUndefinedNullOrEmpty(promoItemObj?.price) ? promoItemObj?.price : null,
    //     };
    //   });

    //   this.baoArr.push({
    //     price: baoFirstItem.price,
    //     menuId: baoFirstItem.menuId,
    //     setBase: baoFirstItem.setBase,
    //     cnt: matchedGrpSub.cnt,
    //     grpName: this.menuItemOptGrpList.find(item => item.id == optGrpId).name,
    //     items,
    //   });
    // }

    // for (let i = 0; i < this.baoArr.length; i++) {
    //   const bao = this.baoArr[i];
    //   this.$set(this.baoForm, i, {});
    //   for (let item of bao.items) {
    //     this.$set(this.baoForm[i], item.itemId, {
    //       count: bao.items.length == 1 ? bao.cnt : 0,
    //       cnt: bao.cnt, // limit
    //       price: bao.price,
    //       promoPrice: item.promoPrice,
    //       menuId: bao.menuId,
    //       setBase: bao.setBase,
    //       itemId: item.itemId,
    //       name: item.name,
    //       label: item.label,
    //     });

    //     if (!this.isUndefinedNullOrEmpty(this.preSelectedData)) {
    //       if (item.itemId == this.preSelectedData.recId && bao.menuId == this.preSelectedData.setId) {
    //         this.baoForm[i][item.itemId].count = 1;
    //         this.setPrice = !this.isUndefinedNullOrEmpty(this.baoForm[i][item.itemId].promoPrice)
    //           ? this.baoForm[i][item.itemId].promoPrice
    //           : this.baoArr[i].price;
    //       }
    //     }
    //   }
    // }

    // let filteredItems = this.menuItemList.filter(item => item.menuId == this.data.id);
    // let beverageGrp = [];
    // for (let item of filteredItems) {
    //   let temp = {
    //     ...item,
    //     items: [...this.menuItemOptList.filter(itemOpt => itemOpt.grpId == item.optGrp[item.optGrp.length - 1])],
    //   };
    //   beverageGrp.push(temp);
    // }

    // let beverageGrpItemsArr = beverageGrp.map(beverage => {
    //   let beverageItemId = beverage.itemId;
    //   let beverageOptGrpId = beverage.optGrp[beverage.optGrp.length - 1];
    //   let beverageOptItem = this.menuItemOptGrpSubList.find(
    //     item => item.itemId == beverageItemId && item.grpId == beverageOptGrpId
    //   );
    //   let beverageOptItemId = beverageOptItem.optItemIds[0]; // Get first item

    //   let beverageOptGrp = this.menuItemOptGrpList.find(item => item.id == beverageOptGrpId);

    //   let selectedItem = this.menuItemList.find(item => item.itemId == beverageOptItemId); // To get price & info of this beverage group
    //   return beverage.items.map(item => {
    //     return {
    //       ...item,
    //       grpName: beverageOptGrp.name,
    //       menuId: beverage.menuId,
    //       menuItemId: beverage.itemId,
    //       setBase: selectedItem.setBase,
    //       price: selectedItem.price,
    //     };
    //   });
    // });

    // this.beverageBasePrice = beverageGrpItemsArr[0][0].setBase;
    // this.selectedDrink = beverageGrpItemsArr[0][0].itemId;

    // for (let beverageGrp of beverageGrpItemsArr) {
    //   for (let i = 0; i < beverageGrp.length; i++) {
    //     let temp = beverageGrp[i];
    //     let count = temp.setBase > 0 ? 1 : 0;
    //     this.$set(this.beverageForm, temp.itemId, {
    //       ...temp,
    //       addPrice: temp.price == 0 ? 0 : temp.price - this.beverageBasePrice,
    //       count,
    //     });
    //   }
    // }

    // this.beverageGrpItemsArr = beverageGrpItemsArr;

    if (this.data.grp == MENU_GRP.FIXED_PRICE) {
      this.setPrice = this.data.price;
    }
  },
  methods: {
    ...mapActions(['addToCart']),
    close() {
      this.$emit('input', false);
    },
    preAddToCart() {
      // If bun not selected
      if (!this.selectedSauce) {
        this.msgModalBody = 'Please select your sauce.';
        this.errMsgModalShow = true;
        return;
      }

      let items = [];
      let baoItem = this.baoForm[this.selectedSauce];

      // Add bun & sauce
      items.push(
        { qty: 1, itemId: this.baoObj.itemId, name: this.baoObj.name },
        { qty: 1, itemId: baoItem.itemId, name: baoItem.name }
      );

      // If beverage required and not selected
      if (this.beverageRequired && !this.selectedBeverage) {
        this.msgModalBody = 'Please select your beverage.';
        this.errMsgModalShow = true;
        return;
      }

      // Add beverage
      let beverageItem = this.beverageForm2[this.selectedBeverage];
      if (beverageItem) {
        items.push({ qty: 1, itemId: beverageItem.itemId, name: beverageItem.name });
      }

      // Add add-ons (14/8/2021: For Box set only)
      if (this.data.id == this.boxMenuId) {
        for (let key in this.addOnForm) {
          let addOnItem = this.addOnForm[key];
          if (addOnItem.selected) {
            items.push({ qty: 1, itemId: addOnItem.itemId, name: addOnItem.name });
          }
        }
      }

      // Add extra sauce
      for (let key in this.sauceForm) {
        let sauceItem = this.sauceForm[key];
        if (sauceItem.selected) {
          items.push({ qty: 1, itemId: sauceItem.itemId, name: sauceItem.name });
        }
      }

      let finalData = {
        qty: this.qty,
        price: this.setBasePrice,
        label: this.menuItemDetails.label,
        menu: this.menuItemDetails.menu,
        images: this.menuItemDetails.images,
        itemId: this.menuItemDetails.itemId,
        items,
      };

      this.loading = true;
      return this.addToCart(finalData).then(() => {
        this.$emit('success');
        this.loading = false;
      });
    },
    // optGrpName(optGrpId) {
    //   return this.menuItemOptGrpList.find(item => item.id == optGrpId).name;
    // },
    // optGrpItemCnt(optGrpId, itemId) {
    //   return this.menuItemOptGrpSubList.find(item => item.grpId == optGrpId &&  item.itemId == itemId)?.cnt;
    // },
    getItemImg(imgUrl) {
      if (this.isUndefinedNullOrEmpty(imgUrl)) {
        return this.defaultImg;
      }
      return imgUrl;
    },
    selectBao(index, itemId) {
      this.step = 2;

      const menuItemObj = this.menuItemList.find(item => item.menuId == this.data.id);

      // Process bao data
      this.baoObj = JSON.parse(JSON.stringify(this.menuItemList.find(item => item.itemId == itemId)));
      this.baoObj.price += menuItemObj.price;

      const bunOptGrp = menuItemObj.optGrp[0];

      const baoOptItem = this.menuItemOptList.find(optItem => optItem.itemId == itemId && optItem.grpId == bunOptGrp);
      this.baoObj.images = baoOptItem.images;

      const sauceOptGrp = menuItemObj.optGrp[1];

      let optGrpSub = this.menuItemOptGrpSubList.find(item => item.itemId == menuItemObj.itemId && item.grpId == sauceOptGrp);
      const optItemIds = optGrpSub.optItemIds;

      // Assumption: Last item in optGrp is beverage
      const beverageOptGrp = menuItemObj.optGrp[menuItemObj.optGrp.length - 1];
      const beverageGrpSub = this.menuItemOptGrpSubList.find(
        item => item.itemId == menuItemObj.itemId && item.grpId == beverageOptGrp
      );

      this.beverageRequired = beverageGrpSub.flag == 0 ? true : false;

      this.sauceItemArr = optItemIds
        .map(item => {
          return this.menuItemList.find(menuItem => menuItem.itemId == item);
        })
        .map(item => {
          return {
            ...item,
            count: 0,
            price: this.baoObj.price + menuItemObj.price,
          };
        });

      this.menuItemDetails = menuItemObj;

      // this.promotionItems = this.menuItemList.filter(item => item.menuId == this.promotionItemMenuId);

      // Process add on items arr & form
      this.addOnArr = this.menuItemList.filter(item => item.menuId == this.addOnMenuId);
      for (let item of this.addOnArr) {
        this.$set(this.addOnForm, item.itemId, {
          ...item,
          selected: false,
        });
      }

      // Process extra sauce arr & form
      this.sauceArr = this.menuItemList.filter(item => item.menuId == this.sauceMenuId);
      for (let item of this.sauceArr) {
        this.$set(this.sauceForm, item.itemId, {
          ...item,
          selected: false,
        });
      }

      // Process beverage arr & form
      this.beverageArr = beverageGrpSub.optItemIds.map(item => {
        const menuItem = this.menuItemList.find(menuItem => menuItem.itemId == item);
        return {
          ...menuItem,
          price: 0,
        };
      });

      for (let [index, item] of Object.entries(this.beverageArr)) {
        this.$set(this.beverageForm2, item.itemId, {
          ...item,
          price: 0, // 10/8/2021: Free drinks for bun & box
          selected: false, // Set first beverage as default selected
          // count: index == 0 ? 1 : 0, // Set first beverage as default selected
        });
        if (index == 0) {
          this.selectedBeverage = item.itemId;
        }
      }

      let baoArr = [];
      for (let i = 0; i < this.sauceItemArr.length; i++) {
        const sauce = this.sauceItemArr[i];

        this.$set(this.baoForm, sauce.itemId, {
          ...sauce,
          // count: 0,
        });
      }
      this.baoArr = baoArr;

      this.setPrice = this.baoObj.price;
    },
    selectBeverage(item) {
      if (this.selectedBeverage == item.itemId) {
        this.selectedBeverage = null;
      } else {
        this.selectedBeverage = item.itemId;
      }
    },
    resetFields() {
      this.selectedSauce = null;
      this.selectedBeverage = null;

      for (let key in this.addOnForm) {
        if (this.addOnForm[key].selected) {
          this.addOnForm[key].selected = false;
        }
      }
      this.addOnTotalAmt = 0;

      for (let key in this.sauceForm) {
        if (this.sauceForm[key].selected) {
          this.sauceForm[key].selected = false;
        }
      }
      this.sauceTotalAmt = 0;
      this.baoObj = {};

      this.qty = 1;

      this.setTotalPrice = 0;
      this.setBasePrice = 0;
      this.setPrice = 0;
    },
    back() {
      this.resetFields();
      this.step = 1;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../../styles/mixin.scss';
.v-card {
  .grp-title {
    font-size: 1rem;
    font-weight: 500;
  }
  .grp-note {
    font-size: 0.85rem;
  }
}
.modal-header {
  background-color: #000;
  position: sticky;
  top: 0;
  &__title {
    color: #fff !important;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1;
  }
}
.bao-grp-wrap {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0.75rem;
  row-gap: 0.3rem;

  @include for-sm {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
}
.add-on-grp-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.75rem;
  row-gap: 0.3rem;

  @include for-sm {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
}
.item-wrap {
  border: 2px solid #000;
  border-radius: 5px;
  // box-shadow: 1px 1px 3px 1px #8e8e8e;
  &.selected {
    // border: 4px solid var(--primary-color);
    border: 4px solid #00b35f;
    box-shadow: none;
  }
}

.item-img {
  max-width: 85px;
  width: 100%;
  @include for-sm {
    max-width: 75px;
  }
}
.item-img-small {
  max-width: 60px;
  width: 100%;
  @include for-sm {
    max-width: 60px;
  }
}
.item-label {
  color: var(--primary-black-color);
  font-size: 0.9rem;
  font-weight: 500;
}
.item-price {
  color: var(--red-common);
  font-size: 0.9rem;
  font-weight: 500;
}
.qty-bar::v-deep {
  max-width: 200px;
  .v-input {
    .v-input__prepend-outer,
    .v-input__append-outer {
      .v-btn {
        background-color: transparent;
        border: none;
        i {
          color: #000 !important;
        }
      }
    }
  }
}
.total-text {
  color: #000;
  font-size: 1.25rem;
}
.total-price {
  color: var(--red-common);
  font-size: 1.5rem;
  font-weight: 500;
}
.modal-footer {
  // background-color: #e0e0e0;
  // border-top: 1px solid #bdbdbd;
  // bottom: 0;
  // position: sticky;
}
.set-divider {
  border-color: rgb(0 0 0 / 30%);
}
.font-black {
  color: var(--black);
}
// .plus-minus-wrap {
//   max-width: 150px;
//   margin: 0 auto;
// }
</style>
