<template>
  <div>
    <v-simple-table class="table-wrap simple-table" fixed-header height="560px">
      <template v-slot:default>
        <thead class="header">
          <tr>
            <th class="text-left">
              {{ $t('orders.lbl.orderNo') }}
            </th>
            <th class="text-left">
              {{ $t('orders.lbl.item') }}
            </th>
            <th class="text-left">
              {{ $t('orders.lbl.quantity') }}
            </th>
            <th class="text-left">
              {{ $t('orders.lbl.channel') }}
            </th>
            <th class="text-left">
              {{ $t('lbl.date') }}
            </th>
            <th class="text-right"></th>
          </tr>
        </thead>
        <tbody class="body">
          <tr v-for="(item, index) in list" :key="index">
            <td>
              <div class="font-weight-medium">{{ item.custName }}</div>
              <div class="d-flex align-center">
                <div class="mr-1">{{ item.seq }}</div>
                <div v-if="isSelfOrder(item.channel)" class="channel-badge">{{ $t('orders.lbl.selfOrder') }}</div>
              </div>
            </td>
            <td class="py-2">
              <ul>
                <li v-for="(orderItem, orderItemIndex) in item.items" :key="orderItemIndex" class="mb-0 items">
                  {{ orderItem }}
                </li>
              </ul>
              <div v-if="item.notes" class="ml-2 mt-1 fw-500" style="color: #ff5252">{{ `* ${item.notes}` }}</div>
            </td>
            <td>{{ item.totItem }}</td>
            <td>
              <div class="d-flex align-center">
                <div class="mr-2">{{ salesChannelMap[item.channel] }}</div>
                <div v-if="isDelivery(item.channel) || isPickup(item.channel) || item.custUserId>0" class="cursor-pointer">
                  <IconWrap :icon="'mdi-eye'" :size="24" @click.native.stop="viewDetails(item)" />
                </div>
              </div>
            </td>
            <td>{{ setTimeZone(item.doDate, 'h:mma') }}</td>
            <td class="text-right">
              <v-icon class="mr-3" @click.stop="showPrintReceiptModal(item)">mdi-printer</v-icon>
              <AppBtn class="btn-orange" :loading="item.loading" @click="close(item)">{{ $t('orders.act.collect') }}</AppBtn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <SuccessModal v-model="modalShow" :msg="modalShowMsg" />
    <ConfirmModal
      v-model="confirmModalShow"
      :title="confirmModalTitle"
      :msg="confirmModalMsg"
      :loading="conModalLoading"
      @confirm="printReceipt"
    />
    <CommonModal v-model="detailsModalShow" :modal-size="'modal-sm-2'" :title="detailsModalTitle" :footer="false">
      <template #body-content>
        <div class="body-wrap">
          <div v-if="isDelivery(orderDetails.channel) || isPickup(orderDetails.channel)" class="d-flex mb-2">
            <div class="mr-2">
              {{ isDelivery(orderDetails.channel) ? $t('orders.lbl.deliveryTime') : $t('orders.lbl.pickupTime') }}:
            </div>
            <div class="fw-500">
              {{ !isUndefinedNullOrEmpty(orderDetails.doDate) ? setTimeZone(orderDetails.doDate) : $t('lbl.now') }}
            </div>
          </div>
          <div class="d-flex mb-2">
            <div class="mr-2">{{ $t('orders.lbl.receiver') }}:</div>
            <div class="fw-500">{{ orderDetails.custName || '-' }}</div>
          </div>
          <div class="d-flex mb-2">
            <div class="mr-2">{{ $t('orders.lbl.contactNo') }}:</div>
            <div class="fw-500">{{ orderDetails.phone || '-' }}</div>
          </div>
          <div v-if="isDelivery(orderDetails.channel)" class="d-flex mb-2">
            <div class="mr-2">{{ $t('orders.lbl.address') }}:</div>
            <div class="fw-500">{{ `${orderDetails.address}, ${orderDetails.postcode} ${orderDetails.city}` }}</div>
          </div>
          <div class="d-flex mb-2" v-if="orderDetails.notes">
            <div class="mr-2">{{ $t('orders.lbl.remarks') }}:</div>
            <div class="fw-500">{{ orderDetails.notes }}</div>
          </div>
        </div>
      </template>
    </CommonModal>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { orderApi, printApi } from '@/api/order';
import { ORDER_STATUS, ORDER_CHANNEL } from '@/common/orderEnum';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import CommonModal from '@/components/Modal';
import SuccessModal from '@/components/Modal/SuccessModal';
import OrderReceipt from '@/module/order/receipt';
import DeliveryNote from '@/module/order/delivery-note';
import OrderMixin from '@/mixins/module/order';
import { receiptConfig } from '@/module/order/config';

export default {
  name: 'CollectQueue',
  components: {
    ConfirmModal,
    CommonModal,
    SuccessModal,
  },
  mixins: [OrderMixin],
  data() {
    return {
      list: [],
      loading: false,
      modalShow: false,
      modalShowMsg: '',

      confirmModalShow: false,
      confirmModalTitle: this.$t('orders.lbl.printReceipt'),
      confirmModalMsg: 'Please confirm to print receipt',
      conModalLoading: false,

      detailsModalTitle: '',
      detailsModalShow: false,

      receiptData: {},
      orderDetails: {},

      dtFormat: receiptConfig.dtFormat,
    };
  },
  computed: {
    ...mapState({
      salesChannelMap: state => state.lookup.salesChannelMap,
      dashStats: state => state.app.dashStats,
    }),
    ...mapGetters(['getStoreName']),
  },
  watch: {
    confirmModalShow(value) {
      if (value == false) {
        this.receiptData = {};
      }
    },
  },
  created() {
    this.getConfirmedOrderList();
    if (this.isUndefinedNullOrEmpty(this.salesChannelMap)) this.fetchSalesChannelList();
  },
  methods: {
    ...mapMutations(['SET_DASH_STATS']),
    ...mapActions(['fetchSalesChannelList', 'appendCompletedOrderList']),
    getConfirmedOrderList() {
      const data = {
        page: 1,
        pageSize: 100,
        status: ORDER_STATUS.READY,
      };
      return orderApi.list(data).then(res => {
        this.list = res.data.map(item => {
          return {
            ...item,
            loading: false,
          };
        });
      });
    },
    close(item) {
      const data = {
        id: item.id,
      };
      item.loading = true;

      return orderApi
        .close(data)
        .then(async res => {
          if (res.ret == '0') {
            this.modalShowMsg = this.$t('orders.success.collect');
            this.modalShow = true;

            this.appendCompletedOrderList(item);
            let dashStats = this.dashStats;
            dashStats.orders.ready -= 1;
            this.SET_DASH_STATS(dashStats);
            this.$emit('updateCount', -1);
            setTimeout(() => {
              this.modalShow = false;
            }, 500);

            const printGrp = [ORDER_CHANNEL.DELIVERY_POS, ORDER_CHANNEL.DELIVERY_WEB];
            if (printGrp.indexOf(item.channel) !== -1) {
              const orderDetails = await this.getDetail(item.id);

              const deliveryNoteData = {
                payMethod: orderDetails.payMethod,
                orderSeq: orderDetails.seq,
                orderDT: this.setTimeZone(new Date(), this.dtFormat),
                company: this.$t('app.org.name'),
                store: `${this.$t('app.org.storePrefix')} ${this.getStoreName}`,
                items: item.items.map(item => {
                  return {
                    ...item,
                    rowItem: item,
                  };
                }),
                customer: {
                  custName: orderDetails.custName,
                  phone: orderDetails.phone,
                },
                deliveryAddress: {
                  address: orderDetails.address,
                  postcode: orderDetails.postcode,
                  city: orderDetails.city,
                },
              };

              const receipt = new DeliveryNote(deliveryNoteData);
              const printObj = receipt.printObj;

              try {
                const printRes = await this.printDeliveryNote(printObj);
                if (printRes.data.ret != '0') {
                  this.showError(printRes.data.msg);
                }
              } catch (error) {
                this.showError(this.$t('orders.print.doNote.failed'));
              }
            }
            await this.getConfirmedOrderList();
          }
          item.loading = false;
        })
        .catch(() => {
          item.loading = false;
        });
    },
    getDetail(id) {
      return orderApi.detail({ id }).then(res => {
        return res.data;
      });
    },
    showPrintReceiptModal(item) {
      this.receiptData = item;
      this.confirmModalShow = true;
    },
    printDeliveryNote(data) {
      return printApi.orderReceipt(data).then(res => {
        return res;
      });
    },
    async printReceipt() {
      const data = {
        payMethod: this.receiptData.payMethod,
        orderSeq: this.receiptData.seq,
        orderDT: this.setTimeZone(this.receiptData.cdate, this.dtFormat),
        company: this.$t('app.org.name'),
        store: `${this.$t('app.org.storePrefix')} ${this.getStoreName}`,
        totalAmt: this.receiptData.amt,
        items: this.receiptData.items.map(item => {
          return {
            ...item,
            rowItem: item,
          };
        }),
      };

      const receipt = new OrderReceipt(data);
      const printObj = receipt.printObj;

      this.conModalLoading = true;
      return printApi
        .orderReceipt(printObj)
        .then(res => {
          this.conModalLoading = false;

          if (res.data.ret == '0') {
            this.confirmModalShow = false;
            this.receiptData = {};

            this.modalShowMsg = this.$t('orders.success.printRequestSent');
            this.modalShow = true;
            setTimeout(() => {
              this.modalShow = false;
            }, 500);
          } else {
            this.showError(res.data.msg);
          }
          return res;
        })
        .catch(() => {
          this.showError(this.$t('orders.print.failed'));
          this.conModalLoading = false;
        });
    },
    async viewDetails(item) {
      if (item.channel == ORDER_CHANNEL.DELIVERY_POS || item.channel == ORDER_CHANNEL.DELIVERY_WEB) {
        this.detailsModalTitle = this.$t('orders.lbl.deliveryInformation');
      } else if (item.channel == ORDER_CHANNEL.PICKUP_WEB) {
        this.detailsModalTitle = this.$t('orders.lbl.pickupInformation');
      } else if ((item.channel == ORDER_CHANNEL.POS || item.channel == ORDER_CHANNEL.TAKEAWAY_WEB) && item.custUserId > 0) {
        this.detailsModalTitle = this.$t('orders.lbl.takeawayInformation');
      }
      this.orderDetails = await this.getOrderDetail(item.id);
      this.detailsModalShow = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.channel-badge {
  border-radius: 4px;
  background-color: #66fd54;
  color: #000;
  font-size: 9px;
  padding: 3px;
}

.items {
  font-size: 0.95rem;
}

.theme--light.v-data-table.simple-table .v-data-table__wrapper thead.header tr th {
  padding: 2px 10px;
}

.theme--light.v-data-table.simple-table .v-data-table__wrapper tbody.body tr > td {
  padding: 2px 10px;
}
</style>
