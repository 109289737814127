<template>
  <CommonModal
    v-model="show"
    :persistent="true"
    :modal-size="'modal-lg'"
    :close-btn="closeBtn"
    :title="$t('orders.act.choosePymtMethod')"
    :footer="false"
  >
    <template v-if="payMethodList.length > 0" #body-content>
      <div class="font-1dot2r font-weight-medium mb-2">Baoger Club</div>
      <ValidationObserver ref="observer2">
        <v-row>
          <v-col cols="12" sm="4">
            <TextField v-model="member.mobile" :label="$t('member.lbl.phoneNo')" :rules="rules.mobile" :single-line="false" />
          </v-col>
          <v-col cols="12" sm="4">
            <TextField v-model="member.name" :label="$t('member.lbl.name')" :rules="rules.name" :single-line="false" />
          </v-col>
        </v-row>
      </ValidationObserver>

      <v-divider class="mt-4 mb-3"></v-divider>
      <div class="font-1dot2r font-weight-medium mb-2">Takeaway</div>
      <v-row>
        <v-col v-for="(item, index) in takeawayPayMethodList" :key="index" cols="6" sm="2">
          <div
            class="d-flex justify-center align-center text-center h-100 payment-item-wrap cursor-pointer pa-1"
            @click="clicked({ id: data.orderId, payMethod: item.value, grp: item.grp, orderType: ORDER_TYPE.TAKEAWAY })"
          >
            {{ item.text }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="payMethodList.length > 0" cols="12" sm="4">
          <ValidationObserver ref="observer">
            <TextField v-model="notes" :label="$t('lbl.remarks')" :rules="rules.notes" :single-line="false" />
          </ValidationObserver>
        </v-col>
      </v-row>

      <v-divider class="mt-4 mb-3"></v-divider>

      <div class="font-1dot2r font-weight-medium mb-2">Delivery</div>
      <v-row>
        <v-col v-for="(item, index) in deliveryPayMethodList" :key="index" cols="6" sm="2">
          <div
            class="d-flex justify-center align-center text-center h-100 payment-item-wrap cursor-pointer pa-1"
            @click="clicked({ id: data.orderId, payMethod: item.value, grp: item.grp, orderType: ORDER_TYPE.DELIVERY })"
          >
            {{ item.text }}
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-end align-center">
        <div v-if="data.amt != amt" class="line-through price-before mr-2">{{ `${$t('lbl.rm')}${priceFormat(amt)}` }}</div>
        <div class="total-price text-right">{{ `${$t('lbl.rm')}${priceFormat(data.amt)}` }}</div>
      </div>
    </template>
  </CommonModal>
</template>
<script>
import CommonModal from '@/components/Modal';
import { ORDER_TYPE } from '@/common/orderEnum';
import { PAY_METHOD } from '@/common/paymentEnum';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'PayMethodModal',
  components: {
    CommonModal,
  },
  props: {
    closeBtn: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notes: '',
      member: {
        mobile: '',
        name: '',
      },
      rules: {
        notes: { required: true },
        mobile: { required: false, min: 9, regex: /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/ },
        name: { required: false, min: 2 },
      },
      amt: null,
    };
  },
  computed: {
    ...mapState({
      payMethodList: state => state.lookup.payMethodList,
    }),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    takeawayPayMethodList() {
      return this.payMethodList.filter(item => item.value != PAY_METHOD.COD);
    },
    deliveryPayMethodList() {
      const cod = this.payMethodList.filter(item => item.value == PAY_METHOD.CASH || item.value == PAY_METHOD.COD);
      if (this.isUndefinedNullOrEmpty(cod)) return [];
      return cod;
    },
    ORDER_TYPE() {
      return ORDER_TYPE;
    },
  },
  created() {
    if (this.isUndefinedNullOrEmpty(this.payMethodList)) this.fetchPayMethodList();
    this.amt = this.data.amt;
  },
  methods: {
    ...mapActions(['fetchPayMethodList']),
    close() {
      this.$emit('input', false);
    },
    clicked({ id, payMethod, grp, orderType }) {
      const skipValidation = [PAY_METHOD.CASH, PAY_METHOD.COD];

      this.rules.mobile = { ...this.rules.mobile, required: this.member.name ? true : false };
      this.rules.name = { ...this.rules.name, required: this.member.mobile ? true : false };

      this.$nextTick(async () => {
        const valid2 = await this.$refs.observer2.validate();

        if (skipValidation.indexOf(payMethod) == -1) {
          const valid = await this.$refs.observer.validate();
          if (!valid || !valid2) return;
        }
        if (!valid2) return;

        this.$emit('clicked', { id, payMethod, grp, notes: this.notes, orderType, member: this.member });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// .v-card {
//   .grp-title {
//     font-size: 20px;
//     font-weight: 500;
//   }
// }
// .modal-body-custom {
//   color: #000;
//   // display: flex;
//   // justify-content: center;
//   padding: 14px 15px 10px;
// }
.payment-item-wrap {
  border: 2px solid #000;
  border-radius: 4px;
  min-height: 100px;
}
.price-before {
  color: var(--grey-dark-1);
  font-size: 20px;
}
.total-price {
  color: var(--red);
  font-size: 35px;
  font-weight: 600;
}
</style>
