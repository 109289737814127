var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:_vm.className,attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":_vm.itemKey,"items-per-page":_vm.paginationConfig.pageSize,"page":_vm.paginationConfig.page,"server-items-length":_vm.disablePagination ? null : _vm.paginationConfig.total,"loading":_vm.loading,"loading-text":_vm.loadingText,"hide-default-footer":_vm.hideDefaultFooter,"hide-default-header":_vm.hideDefaultHeader,"disable-sort":_vm.disableSort,"disable-pagination":_vm.disablePagination,"mobile-breakpoint":_vm.mobileBreakpoint,"show-select":_vm.showSelect,"selectable-key":_vm.selectableKey,"height":_vm.height,"fixed-header":_vm.fixedHeader,"item-class":_vm.itemClassFunc},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.paginationConfig, "pageSize", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.paginationConfig, "pageSize", $event)},_vm.itemsPerPageChg],"update:page":function($event){return _vm.$set(_vm.paginationConfig, "page", $event)},"toggle-select-all":_vm.toggleSelectAll,"item-selected":_vm.itemSelected,"click:row":_vm.clickRow},scopedSlots:_vm._u([_vm._l((_vm.headers),function(ref){
var value = ref.value;
return {key:("header." + value),fn:function(ref){
var header = ref.header;
return [_vm._t(("header-" + value),function(){return [_vm._v(" "+_vm._s(header.text)+" ")]},{"header":header})]}}}),_vm._l((_vm.headers),function(ref){
var value = ref.value;
return {key:("item." + value),fn:function(ref){
var item = ref.item;
return [_vm._t(("item-" + value),function(){return [_vm._v(" "+_vm._s(item[value])+" ")]},{"item":item})]}}}),{key:"footer",fn:function(){return [(_vm.isItemsExist && _vm.showFooter)?_c('div',{staticClass:"v-data-custom-footer d-flex justify-end align-center font-weight-bold py-3 px-5"},[_c('div',{staticClass:"custom-pagination d-flex align-center"},[_c('div',{staticClass:"custom-pagination__back d-flex align-center",class:[_vm.isPaginationFirstPage ? 'custom-pagination--disabled' : 'custom-pagination__back--hover'],on:{"click":_vm.getPreviousPagination}},[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("mdi-chevron-left")]),_c('span',[_vm._v(_vm._s(_vm.$t('act.back')))])],1),_c('div',{staticClass:"px-3"},[_vm._v(_vm._s(_vm.paginationConfig.page))]),_c('div',{staticClass:"custom-pagination__next d-flex align-center",class:[_vm.isPaginationLastPage ? 'custom-pagination--disabled' : 'custom-pagination__next--hover'],on:{"click":_vm.getNextPagination}},[_c('span',[_vm._v(_vm._s(_vm.$t('act.next')))]),_c('v-icon',{attrs:{"size":"14"}},[_vm._v("mdi-chevron-right")])],1)])]):_vm._e()]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }