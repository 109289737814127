<template>
  <div class="page-wrap">

    <template v-if="!isUndefinedNullOrEmpty(getEditOrder)">
      <div cols="12" class="px-1 pt-2 pb-4 text-left d-flex align-center">
        <div v-if="getEditOrder.custName">
          You are editing order for <span class="font-weight-bold">{{ getEditOrder.custName }}</span>
        </div>
        <div v-else>
          You are editing cart for order #<span class="font-weight-bold">{{ getEditOrder.id }}</span>
        </div>
        <v-icon class="ml-2" color="#333333" size="25" @click="cancelEditMode">mdi-close-circle-outline</v-icon>
      </div>

      <template v-if="!getEditOrder.custUserId">
        <div class="cart-wrap px-4 py-4 px-sm-6 py-sm-4 py-md-4">
          <div class="font-1dot2r font-weight-medium mb-2">Baoger Club</div>
          <ValidationObserver ref="observer2">
            <v-row>
              <v-col cols="12" sm="4">
                <TextField v-model="member.mobile" :label="$t('member.lbl.phoneNo')" :rules="rules.custMobile" :single-line="false" />
              </v-col>
              <v-col cols="12" sm="4">
                <TextField v-model="member.name" :label="$t('member.lbl.name')" :rules="rules.custName" :single-line="false" />
              </v-col>
            </v-row>
          </ValidationObserver>
          </div>
        <div class="mb-4"></div>
      </template>
    </template>

    <v-row v-if="form.length > 0">
      <v-col v-for="(item, index) in form" :key="index" cols="12" class="d-flex py-1">
        <div class="d-flex flex-column flex-sm-row align-start align-sm-center flex-grow-1 cart-wrap px-4 py-4 px-sm-4 py-sm-4">
          <!-- <img v-if="!isMobileView" class="cart-img mr-6" :src="item.images[0].url" alt="cart-img"> -->
          <div class="set-item-wrap w-100">
            <div class="d-flex justify-space-between align-center mb-2">
              <div class="set-item__title">
                {{ item.label }}
              </div>
              <v-icon
                v-if="isMobileView"
                class="delete-icon"
                color="var(--primary-black-color)"
                size="25"
                @click="removeItem(index)"
                >mdi-trash-can-outline</v-icon
              >
            </div>
            <ul>
              <li v-for="(cartItem, cartItemIndex) in item.items" :key="cartItemIndex" class="cart-item mb-1">
                {{ `${cartItem.name} x ${cartItem.qty}` }}
              </li>
            </ul>
          </div>
          <div v-if="!isMobileView" class="set-item__price mr-2">
            {{ `${$t('lbl.rm')}${priceFormat(item.price)}` }}
          </div>

          <div class="d-flex justify-space-between align-center" :class="{ 'w-100': isMobileView }">
            <div v-if="isMobileView" class="set-item__price mr-2">
              {{ `${$t('lbl.rm')}${priceFormat(item.price)}` }}
            </div>
            <div class="set-item-qty-wrap">
              <PlusMinusField v-model="item.qty" :min-value="1" filled outlined dense />
            </div>
          </div>
          <v-icon v-if="!isMobileView" class="delete-icon ml-6" color="var(--primary-black-color)" size="30" @click="removeItem(index)"
            >mdi-trash-can-outline</v-icon
          >
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <div class="d-flex justify-center align-center cart-wrap px-6 py-6">
          <div class="font-1dot25r font-weight-bold">
            {{ $t('orders.lbl.cartEmpty') }}
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="bottom-bar d-flex justify-space-between">
      <div class="d-flex flex-column justify-center">
        <div class="total-text">{{ $t('lbl.total') }}</div>
        <div class="total-price">{{ `${$t('lbl.rm')}${totalPrice}` }}</div>
      </div>
      <div class="d-flex align-center">
        <AppBtn class="btn-black-border mr-2" :height="50" :width="isMobileView ? 100 : 160" @click="addMore">{{
          $t('cart.act.addMore')
        }}</AppBtn>
        <AppBtn
          v-if="form.length > 0"
          class="btn-orange-border"
          :height="50"
          :width="isMobileView ? 100 : 160"
          @click="showPayMethod"
          >{{ $t('cart.act.placeOrder') }}</AppBtn
        >
      </div>
    </div>
    <PayMethodModal
      v-if="payMethodModalShow"
      v-model="payMethodModalShow"
      :data="payMethodData"
      :close-btn="true"
      @clicked="payMethodClicked"
    />
    <CommonModal v-model="successModal" :modal-size="'modal-xs'" :header="false" :footer="false">
      <template #body>
        <div class="modal-body d-flex align-center">
          <v-icon class="mr-2" color="var(--primary-color)" size="60">mdi-check-circle-outline</v-icon>
          <div class="modal-body__text">{{ $t('cart.success.order.confirm') }}</div>
        </div>
      </template>
    </CommonModal>

    <CommonModal
      v-if="deliveryInfoModalShow"
      v-model="deliveryInfoModalShow"
      :modal-size="'modal-md'"
      :title="'Delivery Info'"
      :persistent="true"
    >
      <template #body-content>
        <ValidationObserver ref="observer">
          <div>
            <div class="font-1dot1r font-weight-bold mb-2">Delivery Address</div>
            <v-row>
              <v-col cols="12" sm="4">
                <TextField
                  v-model="deliveryData.unit"
                  :rules="rules.unit"
                  :single-line="false"
                  :label="`${$t('delivery.lbl.unitBlockHouse')} *`"
                  :validation-label="$t('delivery.lbl.unitBlockHouse')"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <TextField
                  v-model="deliveryData.address"
                  :rules="rules.address"
                  :single-line="false"
                  :label="`${$t('delivery.lbl.address')} *`"
                  :validation-label="$t('delivery.lbl.address')"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <TextField
                  v-model="deliveryData.postcode"
                  :rules="rules.postcode"
                  :max-length="postcodeLength"
                  :single-line="false"
                  :label="`${$t('delivery.lbl.postcode')} *`"
                  :validation-label="$t('delivery.lbl.postcode')"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <TextField
                  v-model="deliveryData.city"
                  :rules="rules.city"
                  :single-line="false"
                  :label="`${$t('delivery.lbl.city')} *`"
                  :validation-label="$t('delivery.lbl.city')"
                />
              </v-col>
            </v-row>
            <v-divider class="my-3"></v-divider>

            <v-row>
              <v-col cols="12" sm="4">
                <div class="font-1dot1r font-weight-bold mb-2">Delivery Time</div>
                <ValidationProvider v-slot="{ errors }" :name="$t('delivery.lbl.deliveryTime')" :rules="rules.scheduleType">
                  <v-radio-group
                    v-model="deliveryData.scheduleType"
                    class="radio-option-wrap mb-2"
                    :error-messages="errors[0]"
                    :hide-details="'auto'"
                    @change="scheduleTypeChg"
                  >
                    <v-radio class="mb-3" :label="$t('orders.lbl.orderNow')" :value="SCHEDULE_TYPE.NOW"> </v-radio>
                    <v-radio :label="$t('orders.lbl.orderInAdvance')" :value="SCHEDULE_TYPE.IN_ADVANCE"> </v-radio>
                    <template v-if="deliveryData.scheduleType == SCHEDULE_TYPE.IN_ADVANCE">
                      <v-row>
                        <v-col cols="12" sm="12">
                          <Select
                            v-model="deliveryData.schedule"
                            :rules="rules.schedule"
                            :options="scheduleList"
                            :single-line="true"
                            :label="$t('delivery.lbl.deliveryTime')"
                            :placeholder="$t('delivery.lbl.selectTimeSlot')"
                            :clearable="false"
                            @change="scheduleChg"
                          />
                        </v-col>
                      </v-row>
                    </template>
                  </v-radio-group>
                </ValidationProvider>
              </v-col>
              <v-divider class="my-3" vertical></v-divider>
              <v-col cols="12" sm="4">
                <div class="font-1dot1r font-weight-bold mb-2">Delivery Fee</div>
                <v-row>
                  <v-col cols="12" sm="12">
                    <TextField
                      v-model="deliveryData.deliveryFee"
                      :rules="rules.deliveryFee"
                      :type="'number'"
                      :min="1"
                      :step="'0.1'"
                      :single-line="true"
                      :label="'Delivery Fee'"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-divider class="my-3" vertical></v-divider>
              <v-col cols="12" sm="4">
                <div class="font-1dot1r font-weight-bold mb-2">{{ $t('orders.lbl.orderRemarks') }}</div>
                <v-row>
                  <v-col cols="12" sm="12">
                    <TextField
                      v-model="deliveryData.notes"
                      :rules="rules.notes"
                      :single-line="true"
                      :label="$t('orders.lbl.orderRemarks')"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>

            <div class="font-1dot1r font-weight-bold mb-2">Customer Details</div>
            <v-row>
              <v-col cols="12" sm="4">
                <TextField
                  v-model="deliveryData.mobile"
                  :rules="rules.mobile"
                  :single-line="false"
                  :label="`${$t('delivery.lbl.phoneNo')} *`"
                  :validation-label="$t('delivery.lbl.phoneNo')"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <TextField
                  v-model="deliveryData.name"
                  :rules="rules.name"
                  :single-line="false"
                  :label="`${$t('delivery.lbl.name')} *`"
                  :validation-label="$t('delivery.lbl.name')"
                />
              </v-col>
            </v-row>
          </div>
        </ValidationObserver>
      </template>
      <template #footer>
        <div class="modal-footer" style="position:sticky; bottom:0">
          <div class="text-right">
            <AppBtn class="btn-orange" :loading="confirmOrderLoading" @click="proceedWithOrder">{{ $t('act.confirm') }}</AppBtn>
          </div>
        </div>
      </template>
    </CommonModal>

    <CommonModal
      v-if="amendConfirmedModalShow"
      v-model="amendConfirmedModalShow"
      :modal-size="'modal-xs'"
      :title="`Amend Order #${getEditOrder.seq}`"
      :persistent="true"
    >
      <template #body-content>
        <ValidationObserver ref="observer">
          <div>
            <div class="font-1dot1r mb-2">
              <div class="mb-1">You are amending a confirmed order.</div>
              <div>
                <span>Amount to pay 额外支付: </span>
                <span v-if="amendPriceDiff >= 0" class="font-red-common font-weight-bold font-1dot25r">{{
                  `${$t('lbl.rm')}${priceFormat(amendPriceDiff)}`
                }}</span>
                <span v-else-if="amendPriceDiff < 0" class="font-red-common font-weight-bold font-1dot25r">{{
                  `(${$t('lbl.rm')}${priceFormat(Math.abs(amendPriceDiff))})`
                }}</span>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </template>
      <template #footer>
        <div class="modal-footer" style="position:sticky; bottom:0">
          <div class="text-right">
            <AppBtn class="btn-orange" :loading="amendOrderLoading" @click="amendOrder">{{ $t('act.confirm') }}</AppBtn>
          </div>
        </div>
      </template>
    </CommonModal>

    <!-- Amt different modal start -->
    <CommonModal
      v-if="amtDiffModalShow"
      v-model="amtDiffModalShow"
      :modal-size="'modal-xs'"
      :title="`Discount`"
      :close-btn="false"
      :persistent="true"
    >
      <template #body-content>
        <ValidationObserver ref="observer">
          <div class="d-flex align-center">
            <div class="mr-2">Amount to pay:</div>
            <div class="font-red-common font-weight-bold font-1dot25r">
              {{ `${$t('lbl.rm')}${priceFormat(payMethodData.amt)}` }}
            </div>
          </div>
        </ValidationObserver>
      </template>
      <template #footer>
        <div class="modal-footer" style="position:sticky; bottom:0">
          <div class="text-right">
            <AppBtn class="btn-orange" :loading="amendOrderLoading" @click="confirmOrderEvent">{{ $t('act.confirm') }}</AppBtn>
          </div>
        </div>
      </template>
    </CommonModal>
    <!-- Amt different modal end -->

    <v-overlay :value="pymtLoading" :z-index="500">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { orderApi, orderItemApi } from '@/api/order';
import PlusMinusField from '@/components/PlusMinusField';
import CommonModal from '@/components/Modal';
import PayMethodModal from './components/PayMethodModal2';
import OrderReceipt from '@/module/order/receipt';
import { ORDER_CHANNEL, ORDER_TYPE, ORDER_STATUS, SCHEDULE_TYPE } from '@/common/orderEnum';
import { PAY_METHOD } from '@/common/paymentEnum';
import { receiptConfig } from '@/module/order/config';
import { printApi } from '@/api/order';
import { lookupApi } from '@/api/lookup';

export default {
  name: 'Cart',
  components: {
    CommonModal,
    PlusMinusField,
    PayMethodModal,
  },
  data() {
    return {
      postcodeLength: 5,

      notes: '',
      member: {
        mobile: '',
        name: '',
      },

      payMethodModalShow: false,
      successModal: false,
      payMethodData: {},
      payMethodPayload: {},
      payReqData: {},
      form: [],
      confirmOrderLoading: false,
      pymtLoading: false,
      receiptData: {},
      dtFormat: receiptConfig.dtFormat,

      deliveryInfoModalShow: false,

      // storeId: 0, // Pickup uses locId. Pass storeId: 0 here to bypass API check.
      deliveryData: {
        unit: '',
        address: '',
        postcode: '',
        city: '',

        scheduleType: null,
        schedule: null,
        deliveryFee: null,

        mobile: '',
        name: '',
        notes: '',
      },
      scheduleList: [],

      amendConfirmedModalShow: false,
      amendOrderLoading: false,

      amtDiffModalShow: false,
      amtDiffLoading: false,

      confirmOrderEvent: null,

      rules: {
        custMobile: { required: false, min: 9, regex: /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/ },
        custName: { required: false, min: 2 },

        unit: { required: true },
        address: { required: true },
        postcode: { required: true, max: 5 },
        city: { required: true },

        scheduleType: { required: true },
        schedule: { required: true },

        mobile: { required: true, min: 9, regex: /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/ },
        name: { required: true, min: 2 },
        notes: { required: false, max: 50 },

        deliveryFee: { required: false },
      },
    };
  },
  computed: {
    ...mapState({
      menuList: state => state.menu.menuList,
      menuItemList: state => state.menu.menuItemList,
      menuItemOptList: state => state.menu.menuItemOptList,
      menuItemOptGrpList: state => state.menu.menuItemOptGrpList,
      menuItemOptGrpSubList: state => state.menu.menuItemOptGrpSubList,
      payMethodList: state => state.lookup.payMethodList,
    }),
    ...mapGetters(['getCartItems', 'getEditOrder', 'getStoreName']),
    totalPrice() {
      let total = 0;
      for (let item of this.form) {
        total = total + item.qty * item.price;
      }
      return this.priceFormat(total);
    },
    SCHEDULE_TYPE() {
      return SCHEDULE_TYPE;
    },
    amendPriceDiff() {
      return this.payReqData.amt - this.getEditOrder.amt;
      //return this.totalPrice - this.getEditOrder.amt;
    },
  },
  watch: {
    form: {
      deep: true,
      handler(value) {
        this.updateWholeCart(value);
      },
    },
    payMethodModalShow(value) {
      if (value == false) this.payMethodData = {};
    },
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.getCartItems));
    if (this.isUndefinedNullOrEmpty(this.menuList)) this.fetchMenuList();
    if (this.isUndefinedNullOrEmpty(this.menuItemList)) this.fetchMenuItemList();
    if (this.isUndefinedNullOrEmpty(this.menuItemOptList)) this.fetchMenuItemOptList();
    if (this.isUndefinedNullOrEmpty(this.menuItemOptGrpList)) this.fetchMenuItemOptGrpList();
    if (this.isUndefinedNullOrEmpty(this.menuItemOptGrpSubList)) this.fetchMenuItemOptGrpSubList();
    if (this.isUndefinedNullOrEmpty(this.payMethodList)) this.fetchPayMethodList();
  },
  methods: {
    ...mapMutations(['UPDATE_EDIT_ORDER', 'SET_LAST_ORDER_ID']),
    ...mapActions([
      'fetchMenuList',
      'fetchMenuItemList',
      'fetchMenuItemOptList',
      'fetchMenuItemOptGrpList',
      'fetchMenuItemOptGrpSubList',
      'fetchPayMethodList',
      'addToCart',
      'removeFromCart',
      'clearCart',
      'updateWholeCart',
    ]),
    removeItem(index) {
      this.form.splice(index, 1);
    },
    addMore() {
      this.navigatePush('Home');
    },
    closeDeliveryModal() {
      this.deliveryInfoModalShow = false;
    },
    showPayMethod() {
      this.payMethodData = {
        amt: Number(this.totalPrice),
      };

      this.rules.custMobile = { ...this.rules.custMobile, required: this.member.name ? true : false };
      this.rules.custName = { ...this.rules.custName, required: this.member.mobile ? true : false };

      this.$nextTick(async () => {
        if (this.getEditOrder.status == ORDER_STATUS.CONFIRMED) {

          const valid = await this.$refs.observer2.validate();

          if (!valid) return;

          this.orderReq();
          this.amendConfirmedModalShow = true;
        } else {
          this.payMethodModalShow = true;
        }
      });
    },
    payMethodClicked(payload) {
      this.payMethodPayload = {
        payMethod: payload.payMethod,
        notes: payload.notes,
        channel: payload.orderType == ORDER_TYPE.DELIVERY ? ORDER_CHANNEL.DELIVERY_POS : ORDER_CHANNEL.POS,
        grp: payload.grp,
        orderType: payload.orderType,
        mobile: payload.member.mobile,
        name: payload.member.name,
      };

      if (payload.orderType == ORDER_TYPE.DELIVERY) {
        this.payMethodModalShow = false;
        this.deliveryInfoModalShow = true;
      } else {
        this.proceedWithOrder();
      }
    },
    async proceedWithOrder() {
      if (this.pymtLoading == true) {
        return;
      }

      if (this.payMethodPayload.orderType == ORDER_TYPE.DELIVERY) {
        const valid = await this.$refs.observer.validate();
        if (!valid) {
          return;
        }

        this.confirmOrderLoading = true;
      } else {
        this.pymtLoading = true;
      }

      try {
        await this.placeOrder();

        const data = {
          id: this.payMethodData.orderId,
          payMethod: this.payMethodPayload.payMethod,
          notes: this.payMethodPayload.notes,
          grp: this.payMethodPayload.grp,
          orderType: this.payMethodPayload.orderType,
        };

        if (this.payMethodData.amt == this.totalPrice) {
          this.confirmOrder(data);
        } else {
          this.confirmOrderEvent = async () => {
            this.amendOrderLoading = true;
            await this.confirmOrder(data);
            this.amendOrderLoading = false;
          };
          this.confirmOrderLoading = false;
          this.pymtLoading = false;
          this.amtDiffModalShow = true;
        }
      } catch (error) {
        this.pymtLoading = false;
        this.confirmOrderLoading = false;
      }
    },
    async orderReq() {
      let data = {
        custUserId: this.getEditOrder.custUserId,
        amt: Number(this.totalPrice),
        items: this.processItems(this.form),
        mobile: this.member.mobile,
        name: this.member.name,
      };

      data.orderId = this.getEditOrder.id;
      return await this.submitOrderReq(data);
    },
    submitOrderReq(data) {
      return orderApi
        .req(data)
        .then(async res => {
          if (res.ret == '0') {
            this.payReqData = {
              amt: res.data.amt,
              //orderId: res.data.orderId,
            };
          } else {
            if (res.ret == 'InvalidRequest') {
              if (res.data?.cause[0]?.err) {
                this.showError(res.data.cause[0].err);
              } else {
                this.showError(res.msg);
              }
            }
            throw new Error(res.ret);
          }
        })
        .catch(error => {
          throw error;
        });
    },
    async placeOrder() {
      let data = {
        amt: Number(this.totalPrice),
        items: this.processItems(this.form),
        mobile: this.payMethodPayload.mobile,
        name: this.payMethodPayload.name,
      };

      if (!this.isUndefinedNullOrEmpty(this.getEditOrder)) {
        data.status = 4;
        data.orderId = this.getEditOrder.id;
        return await this.placeEditOrder(data);
      } else {
        data.channel = this.payMethodPayload.channel;
        return await this.placeNewOrder(data);
      }
    },
    placeNewOrder(data) {
      return orderApi
        .place(data)
        .then(async res => {
          if (res.ret == '0') {
            this.payMethodData = {
              amt: res.data.amt,
              orderId: res.data.orderId,
            };
            this.receiptData = {
              totalAmt: res.data.amt,
              items: this.getCartItems.map(item => {
                return {
                  ...item,
                  rowItem: `${item.qty}x ${item.menu ? item.menu : item.label}`,
                };
              }),
            };
            await this.clearCart();
            return res;
            // this.$nextTick(() => {
            //   this.payMethodModalShow = true;
            // });
          } else {
            if (res.ret == 'InvalidRequest') {
              if (res.data?.cause[0]?.err) {
                this.showError(res.data.cause[0].err);
              } else {
                this.showError(res.msg);
              }
            }
            throw new Error(res.ret);
          }
        })
        .catch(error => {
          throw error;
        });
    },
    placeEditOrder(data) {
      return orderItemApi
        .create(data)
        .then(async res => {
          if (res.ret == '0') {
            this.payMethodData = {
              amt: res.data.amt,
              orderId: res.data.orderId,
            };
            this.receiptData = {
              totalAmt: res.data.amt,
              items: this.getCartItems.map(item => {
                return {
                  ...item,
                  rowItem: `${item.qty}x ${item.menu ? item.menu : item.label}`,
                };
              }),
            };
            this.UPDATE_EDIT_ORDER(null);
            await this.clearCart();
            return res;
            // this.$nextTick(() => {
            //   this.payMethodModalShow = true;
            // });
          } else {
            throw new Error(res.ret);
          }
        })
        .catch(error => {
          throw error;
        });
    },
    confirmOrder(payload) {
      let data = {
        id: payload.id,
        payMethod: payload.payMethod,
        notes: payload.notes,
      };

      if (payload.orderType == ORDER_TYPE.DELIVERY) {
        const deliveryData = {
          address: `${this.deliveryData.unit}, ${this.deliveryData.address}`,
          postcode: this.deliveryData.postcode,
          city: this.deliveryData.city,
          doDate: this.deliveryData.scheduleType == SCHEDULE_TYPE.NOW ? null : this.deliveryData.schedule,
          mobile: this.deliveryData.mobile,
          name: this.deliveryData.name,
          notes: this.deliveryData.notes,
          deliveryFee: this.deliveryData.deliveryFee || null,
        };
        data = {
          ...data,
          ...deliveryData,
        };
      }

      this.SET_LAST_ORDER_ID(data.id);

      return orderApi
        .confirm(data)
        .then(async res => {
          if (res.ret == '0') {
            const printGrp = [1 /* Cash */, 2 /* Card */, 4 /* E-Wallet */];
            if (printGrp.indexOf(payload.grp) !== -1) {
              this.receiptData = {
                ...this.receiptData,
                payMethod: data.payMethod,
                openCashReg: data.payMethod == PAY_METHOD.CASH ? true : false,
                orderSeq: res.data.seq,
                orderDT: this.setTimeZone(new Date(), this.dtFormat),
                company: this.$t('app.org.name'),
                store: `${this.$t('app.org.storePrefix')} ${this.getStoreName}`,
              };
              const receipt = new OrderReceipt(this.receiptData);
              const printObj = receipt.printObj;

              try {
                const printRes = await this.printReceipt(printObj);
                if (printRes.data.ret != '0') {
                  this.showError(printRes.data.msg);
                }
                this.successModal = true;
                setTimeout(() => {
                  this.successModal = false;
                  this.navigateReplace('Home');
                }, 500);
              } catch (error) {
                this.showError(this.$t('orders.print.failed'));
                this.navigateReplace('Home');
              }
            } else {
              this.successModal = true;
              setTimeout(() => {
                this.successModal = false;
                if (this.amtDiffModalShow) this.amtDiffModalShow = false;
                this.navigateReplace('Home');
              }, 500);
            }
          }
          this.pymtLoading = false;
          this.payMethodModalShow = false;
          this.payMethodData = {};
          this.confirmOrderLoading = false;
        })
        .catch(() => {
          this.pymtLoading = false;
          this.confirmOrderLoading = false;
        });
    },
    async amendOrder() {
      let data = {
        amt: Number(this.totalPrice),
        items: this.processItems(this.form),
        mobile: this.member.mobile,
        name: this.member.name,
        status: 4,
        orderId: this.getEditOrder.id,
      };

      const getEditOrder = this.getEditOrder;

      this.amendOrderLoading = true;
      try {
        await this.placeEditOrder(data);
        this.amendOrderLoading = false;
      } catch (error) {
        this.amendOrderLoading = false;
      }
      this.amendConfirmedModalShow = false;

      const obj = this.payMethodList.find(item => item.value == getEditOrder.payMethod);

      const printGrp = [1 /* Cash */, 2 /* Card */, 4 /* E-Wallet */];
      if (printGrp.indexOf(obj.grp) !== -1) {
        this.receiptData = {
          ...this.receiptData,
          payMethod: getEditOrder.payMethod,
          openCashReg: getEditOrder.payMethod == PAY_METHOD.CASH ? true : false,
          orderSeq: getEditOrder.seq,
          orderDT: this.setTimeZone(new Date(), this.dtFormat),
          company: this.$t('app.org.name'),
          store: `${this.$t('app.org.storePrefix')} ${this.getStoreName}`,
        };
        const receipt = new OrderReceipt(this.receiptData);
        const printObj = receipt.printObj;

        try {
          const printRes = await this.printReceipt(printObj);
          if (printRes.data.ret != '0') {
            this.showError(printRes.data.msg);
          }
          this.successModal = true;
          setTimeout(() => {
            this.successModal = false;
            this.navigateReplace('Home');
          }, 500);
        } catch (error) {
          this.showError(this.$t('orders.print.failed'));
          this.navigateReplace('Home');
        }
      } else {
        this.successModal = true;
        setTimeout(() => {
          this.successModal = false;
          this.navigateReplace('Home');
        }, 500);
      }
    },
    processItems(data) {
      return data.map(item => {
        return {
          itemId: item.itemId,
          qty: item.qty,
          items: !this.isUndefinedNullOrEmpty(item.items)
            ? item.items.map(innerItem => {
                return {
                  itemId: innerItem.itemId,
                  qty: innerItem.qty,
                };
              })
            : null,
        };
      });
    },
    cancelEditMode() {
      this.UPDATE_EDIT_ORDER(null);
      this.updateWholeCart([]);
      this.form = [];
    },
    printReceipt(data) {
      return printApi.orderReceipt(data).then(res => {
        return res;
      });
    },
    scheduleTypeChg(value) {
      if (value == SCHEDULE_TYPE.IN_ADVANCE) {
        if (this.isUndefinedNullOrEmpty(this.scheduleList)) {
          this.getScheduleList();
        }
      }
    },
    scheduleChg() {},
    getScheduleList() {
      return lookupApi.getScheduleList({ grp: 'delivery' }).then(res => {
        if (!this.isUndefinedNullOrEmpty(res.data)) {
          this.scheduleList = res.data.map(item => {
            return {
              text: item.name,
              value: item.utc,
            };
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  // overflow-y: scroll;
  margin-bottom: 80px;
}
.cart-wrap {
  background-color: #fff;
  border-radius: 10px;

  .cart-img {
    max-width: 45px;
    width: 100%;
  }

  .set-item-wrap {
    .set-item {
      &__title {
        font-size: 1.1rem;
        font-weight: 600;
      }
    }
    .cart-item {
      font-size: 1.1rem;
    }
  }

  .set-item__price {
    color: #ff0000;
    font-size: 1.25rem;
    font-weight: 600;
    min-width: 120px;
  }

  .set-item-qty-wrap {
    min-width: 120px;
    max-width: 150px;
  }
}
.delete-icon {
  &::after {
    display: none;
  }
}
.bottom-bar {
  background-color: var(--primary-black-color);
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  padding: 12px 25px;
  height: 80px;

  .total-text {
    color: var(--primary-color);
    font-size: 20px;
  }
  .total-price {
    color: var(--primary-color);
    font-size: 1.5rem;
    font-weight: 500;
  }
}
.modal-body {
  background-color: #231f20;
  height: 100px;

  &__text {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 600;
  }
}
.order-wrap {
  background-color: #fff;
  border: 2px solid var(--primary-black-color);
  border-radius: 5px;
}
</style>
