<template>
  <div>
    <v-simple-table class="table-wrap simple-table" fixed-header height="520px">
      <template v-slot:default>
        <thead class="header">
          <tr>
            <th class="text-left">
              {{ $t('orders.lbl.orderNo') }}
            </th>
            <th class="text-left">
              {{ $t('orders.lbl.item') }}
            </th>
            <th class="text-left">
              {{ $t('orders.lbl.quantity') }}
            </th>
            <th class="text-left">
              {{ $t('lbl.orderDate') }}
            </th>
            <th class="text-left">
              {{ $t('orders.lbl.channel') }}
            </th>
            <th class="text-right"></th>
          </tr>
        </thead>
        <tbody class="body">
          <tr v-for="(item, index) in list" :key="index">
            <td>
              <div class="font-weight-medium">{{ item.custName }}</div>
              <div class="font-weight-medium">{{ item.phone }}</div>
              <div>{{ item.seq }}</div>
            </td>
            <td class="py-2">
              <ul>
                <li v-for="(orderItem, orderItemIndex) in item.items" :key="orderItemIndex" class="mb-0 items">
                  {{ orderItem }}
                </li>
              </ul>
            </td>
            <td>{{ item.totItem }}</td>
            <td>{{ setTimeZone(item.cdate) }}</td>
            <td>
              <div class="d-flex align-center">
                <div class="mr-2">{{ salesChannelMap[item.channel] }}</div>
                <div v-if="isDelivery(item.channel) || isPickup(item.channel)" class="cursor-pointer">
                  <IconWrap :icon="'mdi-eye'" :size="24" @click.native.stop="viewDetails(item)" />
                </div>
              </div>
            </td>
            <td class="text-right">
              <v-icon class="mr-3" @click.stop="showPrintReceiptModal(item)">mdi-printer</v-icon>
              <!-- <AppBtn class="btn-orange" :loading="item.loading" @click="close(item)">{{ $t('orders.act.collect') }}</AppBtn> -->
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <SuccessModal v-model="modalShow" :msg="modalShowMsg" />
    <ConfirmModal
      v-model="confirmModalShow"
      :title="confirmModalTitle"
      :msg="confirmModalMsg"
      :loading="conModalLoading"
      @confirm="printReceipt"
    />
    <CommonModal v-model="detailsModalShow" :modal-size="'modal-sm-2'" :title="detailsModalTitle" :footer="false">
      <template #body-content>
        <div class="body-wrap">
          <div class="d-flex mb-2">
            <div class="mr-2">
              {{ isDelivery(orderDetails.channel) ? $t('orders.lbl.deliveryTime') : $t('orders.lbl.pickupTime') }}:
            </div>
            <div class="fw-500">
              {{ !isUndefinedNullOrEmpty(orderDetails.doDate) ? setTimeZone(orderDetails.doDate) : $t('lbl.now') }}
            </div>
          </div>
          <div class="d-flex mb-2">
            <div class="mr-2">{{ $t('orders.lbl.receiver') }}:</div>
            <div class="fw-500">{{ orderDetails.custName || '-' }}</div>
          </div>
          <div class="d-flex mb-2">
            <div class="mr-2">{{ $t('orders.lbl.contactNo') }}:</div>
            <div class="fw-500">{{ orderDetails.phone || '-' }}</div>
          </div>
          <div v-if="isDelivery(orderDetails.channel)" class="d-flex mb-2">
            <div class="mr-2">{{ $t('orders.lbl.address') }}:</div>
            <div class="fw-500">{{ `${orderDetails.address}, ${orderDetails.postcode} ${orderDetails.city}` }}</div>
          </div>
          <div v-if="isDelivery(orderDetails.channel)" class="text-right mt-3">
            <AppBtn class="btn-orange" :loading="printDoNoteloading" @click="printDeliveryNote(orderDetails)">{{
              $t('act.print')
            }}</AppBtn>
          </div>
        </div>
      </template>
    </CommonModal>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import CommonModal from '@/components/Modal';
import SuccessModal from '@/components/Modal/SuccessModal';
import { orderApi, printApi } from '@/api/order';
import { ORDER_CHANNEL } from '@/common/orderEnum';
import OrderReceipt from '@/module/order/receipt';
import OrderMixin from '@/mixins/module/order';
import DeliveryNote from '@/module/order/delivery-note';
import { receiptConfig } from '@/module/order/config';

export default {
  name: 'OrderHistoryList',
  components: {
    ConfirmModal,
    CommonModal,
    SuccessModal,
  },
  mixins: [OrderMixin],
  data() {
    return {
      list: [],
      loading: false,
      modalShow: false,
      modalShowMsg: '',

      confirmModalShow: false,
      confirmModalTitle: this.$t('orders.lbl.printReceipt'),
      confirmModalMsg: 'Please confirm to print receipt',
      conModalLoading: false,

      detailsModalTitle: '',
      detailsModalShow: false,

      receiptData: {},
      orderDetails: {},
      printDoNoteloading: false,

      dtFormat: receiptConfig.dtFormat,
    };
  },
  computed: {
    ...mapState({
      salesChannelMap: state => state.lookup.salesChannelMap,
    }),
    ...mapGetters(['getCompletedOrderList', 'getStoreName']),
  },
  watch: {
    confirmModalShow(value) {
      if (value == false) {
        this.receiptData = {};
      }
    },
  },
  created() {
    this.processCompletedOrderList();
    if (this.isUndefinedNullOrEmpty(this.salesChannelMap)) this.fetchSalesChannelList();
  },
  methods: {
    ...mapActions(['fetchSalesChannelList']),
    processCompletedOrderList() {
      if (!this.isUndefinedNullOrEmpty(this.getCompletedOrderList)) {
        this.list = this.getCompletedOrderList.map(item => {
          return {
            ...item,
            loading: false,
          };
        });
      }
    },
    showPrintReceiptModal(item) {
      this.receiptData = item;
      this.confirmModalShow = true;
    },
    async printDeliveryNote(payload) {
      const data = {
        payMethod: payload.payMethod,
        orderSeq: payload.seq,
        orderDT: this.setTimeZone(payload.cdate, this.dtFormat),
        company: this.$t('app.org.name'),
        store: `${this.$t('app.org.storePrefix')} ${this.getStoreName}`,
        items: payload.items.map(item => {
          return {
            rowItem: item,
          };
        }),
        customer: {
          custName: payload.custName,
          phone: payload.phone,
        },
        deliveryAddress: {
          address: payload.address,
          postcode: payload.postcode,
          city: payload.city,
        },
      };

      const receipt = new DeliveryNote(data);
      const printObj = receipt.printObj;

      try {
        this.printDoNoteloading = true;
        const printRes = await this.print(printObj);
        this.printDoNoteloading = false;
        if (printRes.data.ret != '0') {
          this.showError(printRes.data.msg);
        }
      } catch (error) {
        this.printDoNoteloading = false;
        this.showError(this.$t('orders.print.doNote.failed'));
      }
    },
    async printReceipt() {
      const data = {
        orderSeq: this.receiptData.seq,
        orderDT: this.setTimeZone(this.receiptData.cdate, this.dtFormat),
        company: this.$t('app.org.name'),
        store: `${this.$t('app.org.storePrefix')} ${this.getStoreName}`,
        totalAmt: this.receiptData.amt,
        items: this.receiptData.items.map(item => {
          return {
            ...item,
            rowItem: item,
          };
        }),
      };

      if (this.receiptData.channel == ORDER_CHANNEL.DELIVERY_POS || this.receiptData.channel == ORDER_CHANNEL.DELIVERY_WEB) {
        const orderDetails = await this.getDetail(this.receiptData.id);
        data.payMethod = orderDetails.payMethod;
      }

      const receipt = new OrderReceipt(data);
      const printObj = receipt.printObj;

      this.conModalLoading = true;
      return printApi
        .orderReceipt(printObj)
        .then(res => {
          this.conModalLoading = false;

          if (res.data.ret == '0') {
            this.confirmModalShow = false;
            this.receiptData = {};

            this.modalShowMsg = this.$t('orders.success.printRequestSent');
            this.modalShow = true;
            setTimeout(() => {
              this.modalShow = false;
            }, 500);
          } else {
            this.showError(res.data.msg);
          }
          return res;
        })
        .catch(() => {
          this.showError(this.$t('orders.print.failed'));
          this.conModalLoading = false;
        });
    },
    print(data) {
      return printApi.orderReceipt(data).then(res => {
        return res;
      });
    },
    async viewDetails(item) {
      if (item.channel == ORDER_CHANNEL.DELIVERY_POS || item.channel == ORDER_CHANNEL.DELIVERY_WEB) {
        this.detailsModalTitle = this.$t('orders.lbl.deliveryInformation');
      } else if (item.channel == ORDER_CHANNEL.PICKUP_WEB) {
        this.detailsModalTitle = this.$t('orders.lbl.pickupInformation');
      }
      this.orderDetails = await this.getOrderDetail(item.id);
      this.detailsModalShow = true;
    },
    getDetail(id) {
      return orderApi.detail({ id }).then(res => {
        return res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.channel-badge {
  border-radius: 4px;
  background-color: #66fd54;
  color: #000;
  font-size: 9px;
  padding: 3px;
}

.items {
  font-size: 0.95rem;
}

.theme--light.v-data-table.simple-table .v-data-table__wrapper thead.header tr th {
  padding: 2px 10px;
}

.theme--light.v-data-table.simple-table .v-data-table__wrapper tbody.body tr > td {
  padding: 2px 10px;
}
</style>
