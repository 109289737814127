<template>
  <div>
    <CommonModal v-model="show" :modal-size="'modal-lg'">
      <template #header>
        <div class="modal-header">
          <div class="d-flex justify-space-between align-center">
            <div class="modal-header__title">
              {{ data.name }} <span class="font-primary-orange">{{ `(${$t('lbl.rm')}${priceFormat(data.price)})` }}</span>
            </div>
            <v-icon color="#fff" @click="close">mdi-close</v-icon>
          </div>
        </div>
      </template>
      <template #body-content>
        <div>
          <div>
            <div v-for="(baoGrp, baoGrpIndex) in baoArr" :key="baoGrpIndex" class="mb-3">
              <div v-if="baoGrp.items.length > 1" class="grp-title px-1 mb-1">
                {{ baoGrp.grpName }} <span class="grp-note font-red-common">{{ `(Choose ${baoGrp.cnt})` }}</span>
              </div>
              <!-- <div class="bao-grp-wrap"> -->
              <v-row class="mx-0 my-0">
                <v-col v-for="(bao, index) in baoGrp.items" :key="index" cols="12" sm="4" md="4" class="px-1 py-1 cursor-pointer">
                  <div class="item-wrap d-flex align-center pa-2 h-100">
                    <img class="item-img mr-1" :src="getItemImg(bao.images[0].url)" alt="item" />
                    <div class="item-label">{{ bao.name }}</div>
                    <div class="d-flex align-center ml-auto" style="width: 36%">
                      <PlusMinusField
                        v-model="baoForm[baoGrpIndex][bao.itemId].count"
                        class="plus-minus-field"
                        filled
                        outlined
                        dense
                        :disabled-minus="baoGrp.chgDisabled"
                        :disabled-plus="baoDisabledArr[baoGrpIndex] || baoGrp.chgDisabled"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
              <!-- </div> -->
              <!-- <div v-if="baoGroupErrArr[baoGrpIndex] == false" class="font-red-common font-weight-medium mt-2">
                {{ 'Please select an item' }}
              </div> -->
              <v-divider class="set-divider mt-3"></v-divider>
            </div>
          </div>

          <div>
            <div class="px-1 font-1r font-weight-bold">
              Set Drinks
              <span v-if="beverageGrpItemsArr[0].length > 1" class="grp-note font-red-common">{{
                `(Choose ${beverageGrpCntArr[0]})`
              }}</span>
            </div>
            <template v-for="(group, index) in beverageGrpItemsArr">
              <div :key="index" class="my-1">
                <v-row class="mx-0 my-0">
                  <v-col
                    v-for="(item, itemIndex) in group"
                    :key="itemIndex"
                    cols="12"
                    sm="4"
                    md="4"
                    class="px-1 py-1 cursor-pointer"
                  >
                    <div class="item-wrap d-flex align-center pa-2 h-100">
                      <img class="item-img mr-1" :src="getItemImg(item.images[0].url)" alt="item" />
                      <div class="item-label">{{ item.name }}</div>
                      <div class="d-flex align-center ml-auto" style="max-width: 105px; width: 100%;">
                        <PlusMinusField
                          v-model="beverageForm[item.itemId].count"
                          class="plus-minus-field"
                          filled
                          outlined
                          dense
                          :disabled-minus="beverageChgDisabled[index]"
                          :disabled-plus="beverageDisabledArr[index]"
                        />
                      </div>
                      <!-- <div class="item-right">
                        <div
                          v-if="!isUndefinedNullOrEmpty(group[0].price) && group[0].price > 0"
                          class="item-price font-weight-bold"
                        >
                          {{ `+${$t('lbl.rm')}${priceFormat(group[0].price - beverageBasePrice)}` }}
                        </div>
                        <div v-else-if="group[0].price == 0" class="item-price font-weight-bold font-black">
                          {{ `${$t('lbl.rm')}${priceFormat(group[0].price)}` }}
                        </div>
                      </div> -->
                    </div>
                  </v-col>
                </v-row>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="modal-footer" style="position:sticky; bottom:0">
          <div class="d-flex justify-space-between align-center">
            <div class="d-flex flex-column justify-center">
              <div class="total-text">{{ $t('lbl.total') }}</div>
              <div class="total-price">{{ `${$t('lbl.rm')}${priceFormat(setTotalPrice)}` }}</div>
            </div>
            <div class="d-flex justify-center">
              <PlusMinusField v-model="qty" class="qty-bar px-4" :min-value="1" filled outlined dense />
            </div>
            <AppBtn class="btn-orange" :loading="loading" @click="preAddToCart">{{ $t('act.addToCart') }}</AppBtn>
          </div>
        </div>
      </template>
    </CommonModal>
    <MessageModal
      v-if="errMsgModalShow"
      v-model="errMsgModalShow"
      :body-content-styles="{ 'font-size': '1.1rem', 'font-weight': '500' }"
      :modal-size="'modal-xs-2'"
      :header-title="msgModalTitle"
      :msg="msgModalBody"
      @confirm="errMsgModalShow = false"
    >
      <template #header>
        <div></div>
      </template>
    </MessageModal>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import CommonModal from '@/components/Modal';

export default {
  name: 'ComboSetModal',
  components: {
    CommonModal,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      baoArr: [],
      baoForm: [],
      baoDisabledArr: [],
      baoDisabled: false,
      loading: false,
      defaultImg: require('@/assets/logo.svg'),

      promotionItems: [],
      promotionItemMenuId: 9,

      beverageBasePrice: 0,

      setTotalPrice: 0,
      setBasePrice: 0,
      setPrice: 0,
      beveragePrice: 0,
      qty: 1,

      beverageGrpItemsArr: [],
      beverageGrpCntArr: [],
      beverageChgDisabled: [],
      beverageDisabledArr: [],
      beverageForm: {},

      baoGroupErrArr: [],
      selectedDrink: null,
      // setQty: 1,

      errMsgModalShow: false,
      msgModalTitle: '',
      msgModalBody: 'Please select all required items.',
    };
  },
  computed: {
    ...mapState({
      menuList: state => state.menu.menuList,
      menuItemList: state => state.menu.menuItemList,
      menuItemOptList: state => state.menu.menuItemOptList,
      menuItemOptGrpList: state => state.menu.menuItemOptGrpList,
      menuItemOptGrpSubList: state => state.menu.menuItemOptGrpSubList,
    }),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    baoForm: {
      deep: true,
      handler() {
        this.setBasePrice = this.setPrice /* + this.beveragePrice */;
        this.setTotalPrice = this.setPrice /* + this.beveragePrice */;
        this.qty = 1; // reset qty to 1

        for (let index in this.baoForm) {
          const baoGrp = this.baoForm[index];

          let baoGrpCnt = 0;
          for (let key in baoGrp) {
            const bao = baoGrp[key];
            baoGrpCnt += bao.count ? bao.count : 0;

            if (baoGrpCnt >= this.baoArr[index].cnt) {
              this.baoDisabledArr[index] = true;
              break;
            }
          }

          if (baoGrpCnt < this.baoArr[index].cnt /* Limit */) {
            this.baoDisabledArr[index] = false;
          }
        }
        // this.baoDisabled = true;
      },
    },
    beverageForm: {
      deep: true,
      handler(value) {
        // let count = 0;
        // this.beveragePrice = 0;
        // for (let key in value) {
        //   count = count + value[key].count;
        //   this.beveragePrice = this.beveragePrice + value[key].count * value[key].addPrice;
        // }
        // this.setBasePrice = this.setPrice + this.beveragePrice;
        // this.setTotalPrice = this.setPrice + this.beveragePrice;
        // this.qty = 1; // reset qty to 1

        const beverageCntLimit = this.beverageGrpCntArr[0]; // Handle only one group of beverage for now
        let beverageGrpCnt = 0;

        for (let key in this.beverageForm) {
          const beverage = this.beverageForm[key];
          beverageGrpCnt += beverage.count ? beverage.count : 0;

          if (beverageGrpCnt >= beverageCntLimit) {
            this.beverageDisabledArr[0] = true; // Handle only one group of beverage for now
          }
        }

        if (beverageGrpCnt < beverageCntLimit) {
          this.beverageDisabledArr[0] = false; // Handle only one group of beverage for now
        }
      },
    },
    qty(value) {
      this.setTotalPrice = this.setBasePrice * value;
    },
  },
  created() {
    const baoFirstItem = this.data;

    const baoOptGrp = baoFirstItem.optGrp.slice(0, baoFirstItem.optGrp.length - 1);

    for (let optGrpId of baoOptGrp) {
      const matchedGrpSub = this.menuItemOptGrpSubList.find(
        grpSub => grpSub.itemId == baoFirstItem.itemId && grpSub.grpId == optGrpId
      );

      const items = matchedGrpSub.optItemIds.map(itemId => {
        const menuItemOpt = this.menuItemOptList.find(itemOpt => itemOpt.grpId == optGrpId && itemOpt.itemId == itemId);
        this.promotionItems = this.menuItemList.filter(item => item.menuId == this.promotionItemMenuId);

        const promoItemObj = this.promotionItems.find(
          promoItem => promoItem.recId == menuItemOpt.itemId && promoItem.setId == baoFirstItem.menuId
        );
        return {
          itemId: menuItemOpt.itemId,
          name: menuItemOpt.name,
          label: menuItemOpt.label,
          images: this.menuItemList.find(item => item.itemId == menuItemOpt.itemId).images,
          promoPrice: !this.isUndefinedNullOrEmpty(promoItemObj?.price) ? promoItemObj?.price : null,
        };
      });

      this.baoArr.push({
        price: baoFirstItem.price,
        menuId: baoFirstItem.menuId,
        setBase: baoFirstItem.setBase,
        cnt: matchedGrpSub.cnt,
        chgDisabled: matchedGrpSub.optItemIds.length == 1 ? true : false,
        grpName: this.menuItemOptGrpList.find(item => item.id == optGrpId).name,
        items,
      });
      this.baoDisabledArr.push(false);
    }

    for (let i = 0; i < this.baoArr.length; i++) {
      const bao = this.baoArr[i];
      this.$set(this.baoForm, i, {});

      for (let item of bao.items) {
        this.$set(this.baoForm[i], item.itemId, {
          count: bao.items.length == 1 ? bao.cnt : 0,
          cnt: bao.cnt, // limit
          price: bao.price,
          promoPrice: item.promoPrice,
          menuId: bao.menuId,
          setBase: bao.setBase,
          itemId: item.itemId,
          name: item.name,
          label: item.label,
        });
      }
    }

    let filteredItems = [this.data];
    let beverageGrp = [];
    for (let item of filteredItems) {
      let temp = {
        ...item,
        items: [...this.menuItemOptList.filter(itemOpt => itemOpt.grpId == item.optGrp[item.optGrp.length - 1])],
      };
      beverageGrp.push(temp);
      this.beverageDisabledArr.push(false);
    }

    // Format is in array. Handle multiple groups of beverage. Eg. Tea +RM5, Coffee +RM7
    let beverageGrpItemsArr = beverageGrp.map((beverage, index) => {
      let beverageItemId = beverage.itemId;
      let beverageOptGrpId = beverage.optGrp[beverage.optGrp.length - 1];
      let beverageOptItem = this.menuItemOptGrpSubList.find(
        item => item.itemId == beverageItemId && item.grpId == beverageOptGrpId
      );
      let beverageOptItemId = beverageOptItem.optItemIds[0]; // Get first item

      let beverageOptGrp = this.menuItemOptGrpList.find(item => item.id == beverageOptGrpId);

      let selectedItem = this.menuItemList.find(item => item.itemId == beverageOptItemId); // To get price & info of this beverage group

      this.beverageGrpCntArr[index] = beverageOptItem.cnt;

      return beverage.items.map(item => {
        return {
          ...item,
          grpName: beverageOptGrp.name,
          menuId: beverage.menuId,
          menuItemId: beverage.itemId,
          setBase: selectedItem.setBase,
          price: selectedItem.price,
          images: selectedItem.images,
        };
      });
    });
    // this.beverageBasePrice = beverageGrpItemsArr[0][0].setBase;

    // this.selectedDrink = beverageGrpItemsArr[0][0].itemId;

    for (let beverageGrp of beverageGrpItemsArr) {
      for (let i = 0; i < beverageGrp.length; i++) {
        let temp = beverageGrp[i];
        // let count = temp.setBase > 0 ? 1 : 0;
        this.$set(this.beverageForm, temp.itemId, {
          ...temp,
          // addPrice: temp.price == 0 ? 0 : temp.price - this.beverageBasePrice,
          count: beverageGrp.length == 1 ? this.beverageGrpCntArr[i] : 0,
        });
        this.beverageChgDisabled[i] = beverageGrp.length == 1 ? true : false;
      }
    }

    this.beverageGrpItemsArr = beverageGrpItemsArr;

    // if (this.data.grp == MENU_GRP.COMBO) {
    this.setPrice = this.data.price;
  },
  methods: {
    ...mapActions(['addToCart']),
    close() {
      this.$emit('input', false);
    },
    preAddToCart() {
      let baoGroupCntArr = [];

      for (let item of this.baoArr) {
        baoGroupCntArr.push(item.cnt);
      }

      let baoGroupQtyArr = [];

      for (let index in this.baoForm) {
        let current = this.baoForm[index];
        baoGroupQtyArr[index] = 0;
        for (let key in current) {
          baoGroupQtyArr[index] += current[key].count;
        }
      }

      this.baoGroupErrArr = [];
      let valid = true;
      for (let index in baoGroupQtyArr) {
        if (baoGroupQtyArr[index] != baoGroupCntArr[index]) {
          valid = false;
          this.baoGroupErrArr[index] = false;
        }
      }

      let beverageFormCount = 0;
      for (let key in this.beverageForm) {
        beverageFormCount += this.beverageForm[key].count;
      }

      if (!valid || beverageFormCount != this.beverageGrpCntArr[0]) {
        this.errMsgModalShow = true;
        return;
      }

      let finalData = {};

      let items = [];
      for (let grp of this.baoForm) {
        for (let key in grp) {
          let item = grp[key];
          if (item.count > 0) {
            items.push({ qty: item.count, itemId: item.itemId, name: item.name, label: item.label });
          }
        }
      }

      for (let key in this.beverageForm) {
        let item = this.beverageForm[key];
        if (item.count == 1) {
          items.push({ qty: item.count, itemId: item.itemId, name: item.name, label: item.label });
        }
      }

      let menuItemDetails = this.data;

      finalData.qty = this.qty;
      finalData.price = this.setBasePrice;
      finalData.label = menuItemDetails.label;
      finalData.menu = menuItemDetails.menu;
      finalData.images = menuItemDetails.images;
      finalData.itemId = menuItemDetails.itemId;
      finalData.items = items;

      this.loading = true;
      return this.addToCart(finalData).then(() => {
        this.$emit('success');
      });
    },
    getItemImg(imgUrl) {
      if (this.isUndefinedNullOrEmpty(imgUrl)) {
        return this.defaultImg;
      }
      return imgUrl;
    },
    // selectBao(index, itemId) {
    //   let current = this.baoForm[index];
    //   for (let key in current) {
    //     if (key == itemId) {
    //       this.baoForm[index][key].count = 1;
    //       this.setPrice = !this.isUndefinedNullOrEmpty(this.baoForm[index][key].promoPrice)
    //         ? this.baoForm[index][key].promoPrice
    //         : this.baoArr[index].price;
    //     } else {
    //       this.baoForm[index][key].count = 0;
    //     }
    //   }
    // },
    // selectDrink(itemId) {
    //   for (let key in this.beverageForm) {
    //     if (key == itemId) {
    //       if (this.selectedDrink == itemId) {
    //         // If current selected item == last selected item, treat it as unselect and fallback to the base drink
    //         // this.beverageForm[key].count = 0;
    //         // this.beverageForm['301'].count = 1; // 301-Ice Lemon Tea
    //         // this.selectedDrink = 301;
    //       } else {
    //         this.beverageForm[key].count = 1;
    //         this.selectedDrink = itemId;
    //       }
    //     } else {
    //       this.beverageForm[key].count = 0;
    //     }
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
@import '../../../styles/mixin.scss';
.v-card {
  .grp-title {
    font-size: 1rem;
    font-weight: 500;
  }
  .grp-note {
    font-size: 0.85rem;
  }
}
.modal-header {
  background-color: #000;
  position: sticky;
  top: 0;
  &__title {
    color: #fff !important;
    font-size: 1.25rem;
    font-weight: 600;
  }
}
// .bao-grp-wrap {
//   display: grid;
//   grid-template-columns: repeat(6, 1fr);
//   grid-gap: 0.75rem;
//   row-gap: 0.3rem;

//   @include for-sm {
//     grid-template-columns: repeat(2, 1fr);
//     width: 100%;
//   }
// }
.item-wrap {
  border: 2px solid #000;
  border-radius: 5px;
  // box-shadow: 1px 1px 3px 1px #8e8e8e;
  &.selected {
    // border: 4px solid var(--primary-color);
    border: 4px solid #00b35f;
    box-shadow: none;
  }
}

.item-img {
  max-width: 55px;
  width: 100%;
  // @include for-sm {
  //   max-width: 70px;
  // }
}
.item-label {
  color: var(--primary-black-color);
  font-size: 0.9rem;
  font-weight: 500;
}
.item-price {
  color: var(--red-common);
  font-size: 0.9rem;
  font-weight: 500;
}
.qty-bar::v-deep {
  max-width: 200px;
  .v-input {
    .v-input__prepend-outer,
    .v-input__append-outer {
      .v-btn {
        background-color: transparent;
        border: none;
        i {
          color: #000 !important;
        }
      }
    }
  }
}
.total-text {
  color: #000;
  font-size: 1.25rem;
}
.total-price {
  color: var(--red-common);
  font-size: 1.5rem;
  font-weight: 500;
}
.modal-footer {
  background-color: #e0e0e0;
  border-top: 1px solid #bdbdbd;
  bottom: 0;
  position: sticky;
}
.set-divider {
  border-color: rgb(0 0 0 / 30%);
}
.font-black {
  color: var(--black);
}
// .plus-minus-wrap {
//   max-width: 150px;
//   margin: 0 auto;
// }
.plus-minus-field-wrap {
  max-width: 80%;
  margin: 0 auto;
}
</style>
