<template>
  <v-container id="dashboard-view" fluid tag="section">
    <MenuAll v-if="isReady" />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { dashApi } from '@/api/dash';
import MenuAll from '@/views/Menu/MenuAll';

export default {
  name: 'Home',
  components: {
    MenuAll,
  },
  data() {
    return {
      isReady: false,
    };
  },
  computed: {
    ...mapState({
      menuList: state => state.menu.menuList,
      menuItemList: state => state.menu.menuItemList,
      menuItemOptList: state => state.menu.menuItemOptList,
      menuItemOptGrpList: state => state.menu.menuItemOptGrpList,
      menuItemOptGrpSubList: state => state.menu.menuItemOptGrpSubList,
    }),
  },
  async created() {
    let promises = [];
    if (this.isUndefinedNullOrEmpty(this.menuList)) promises.push(this.fetchMenuList());
    if (this.isUndefinedNullOrEmpty(this.menuItemList)) promises.push(this.fetchMenuItemList());
    if (this.isUndefinedNullOrEmpty(this.menuItemOptList)) promises.push(this.fetchMenuItemOptList());
    if (this.isUndefinedNullOrEmpty(this.menuItemOptGrpList)) promises.push(this.fetchMenuItemOptGrpList());
    if (this.isUndefinedNullOrEmpty(this.menuItemOptGrpSubList)) promises.push(this.fetchMenuItemOptGrpSubList());

    this.getOrderQueueTotalRec();
    this.getDashboardStats();

    if (promises.length > 0) {
      try {
        await Promise.all(promises);
        this.isReady = true;
      } catch (error) {
        console.error('error', error);
      }
    } else {
      this.isReady = true;
    }
  },
  methods: {
    ...mapActions([
      'fetchMenuList',
      'fetchMenuItemList',
      'fetchMenuItemOptList',
      'fetchMenuItemOptGrpList',
      'fetchMenuItemOptGrpSubList',
      'getOrderQueueTotalRec',
    ]),
    ...mapMutations(['SET_DASH_STATS']),
    getDashboardStats() {
      return dashApi.dashboardStats().then(res => {
        this.SET_DASH_STATS(res.data);
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
