<template>
  <div>
    <v-simple-table class="table-wrap simple-table" fixed-header height="520px">
      <template v-slot:default>
        <thead class="header">
          <tr>
            <th class="text-left" style="min-width: 110px">
              {{ $t('orders.lbl.orderNo') }}
            </th>
            <th class="text-left" style="min-width: 150px">
              Customer
            </th>
            <th class="text-left" style="min-width: 200px">
              Delivery Info
            </th>
            <th class="text-left" style="min-width: 200px">
              {{ $t('orders.lbl.item') }}
            </th>
            <th class="text-left" style="min-width: 120px">
              {{ $t('orders.lbl.orderStatus') }}
            </th>
            <th class="text-left">
              {{ $t('lbl.amount') }}
            </th>
            <th class="text-right"></th>
          </tr>
        </thead>
        <tbody class="body">
          <tr v-for="(item, index) in list" :key="index">
            <td>
              <div>{{ item.seq }}</div>
              <div>{{ salesChannelMap[item.channel] }}</div>
            </td>
            <td>
              <div class="d-flex align-start">
                <div class="mr-2">
                  <div>{{ item.custName }}</div>
                  <div>{{ item.phone }}</div>
                  <div v-if="item.notes" class="font-red-common">{{ `* ${item.notes}` }}</div>
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex align-center">
                <div class="mr-2">
                  <div>{{ item.address }}</div>
                  <div>
                    Time:
                    <span class="font-weight-bold font-red-common">
                      {{ !isUndefinedNullOrEmpty(item.doDate) ? setTimeZone(item.doDate) : setTimeZone(item.cdate) }}
                    </span>
                  </div>
                  <div>
                    Driver: <span>{{ item.driver || '-' }}</span>
                  </div>
                  <div>
                    Fee:
                    <span>{{
                      !isUndefinedNullOrEmpty(item.deliveryFee) ? `${$t('lbl.rm')}${priceFormat(item.deliveryFee)}` : '-'
                    }}</span>
                  </div>
                  <div>
                    {{ $t('lbl.status') }}:
                    <span class="font-weight-bold">{{
                      !isUndefinedNullOrEmpty(item.deliveryStatus) ? $t('map.deliveryStatus')[item.deliveryStatus] : '-'
                    }}</span>
                  </div>
                </div>
                <div class="cursor-pointer flex-grow-1 text-right">
                  <IconWrap :icon="'mdi-eye'" :size="24" @click.native.stop="showDeliveryModal(item)" />
                </div>
              </div>
            </td>
            <td class="py-2">
              <ul>
                <li v-for="(orderItem, orderItemIndex) in item.items" :key="orderItemIndex" class="mb-1">
                  {{ orderItem }}
                </li>
              </ul>
            </td>
            <td>{{ $t('map.orderStatus')[item.status] }}</td>
            <td>{{ `${$t('lbl.rm')}${priceFormat(item.amt)}` || '-' }}</td>
            <td>
              <IconWrap class="cursor-pointer" :icon="'mdi-pencil'" :size="24" @click.native.stop="showOrderDetails(item)" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <SuccessModal v-model="successModalShow" :msg="modalShowMsg" />

    <!-- <ConfirmModal v-model="confirmModalShow" :title="confirmModalTitle" :msg="confirmModalMsg" :loading="conModalLoading" @confirm="printReceipt" /> -->

    <CommonModal v-model="deliveryModalShow" :modal-size="'modal-sm-2'" :title="detailsModalTitle" :footer="false">
      <template #body-content>
        <div class="body-wrap">
          <div class="d-flex mb-2">
            <div class="mr-2">{{ $t('orders.lbl.deliveryTime') }}:</div>
            <div class="fw-500">
              {{ !isUndefinedNullOrEmpty(doInfoDetails.doDate) ? setTimeZone(doInfoDetails.doDate) : $t('lbl.now') }}
            </div>
          </div>
          <div class="d-flex mb-2">
            <div class="mr-2">{{ $t('lbl.orderDate') }}:</div>
            <div class="fw-500">
              {{ !isUndefinedNullOrEmpty(doInfoDetails.cdate) ? setTimeZone(doInfoDetails.cdate) : $t('lbl.now') }}
            </div>
          </div>

          <v-divider class="my-3"></v-divider>

          <div class="d-flex mb-2">
            <div class="mr-2">{{ $t('delivery.lbl.deliveryChannel') }}:</div>
            <div class="fw-500">{{ deliveryChannelMap[doInfoDetails.deliveryId] || '-' }}</div>
          </div>
          <div class="d-flex mb-2">
            <div class="mr-2">{{ $t('delivery.lbl.driver') }}:</div>
            <div class="fw-500">{{ doInfoDetails.driver || '-' }}</div>
          </div>
          <div class="d-flex mb-2">
            <div class="mr-2">{{ $t('delivery.lbl.deliveryFee') }}:</div>
            <div class="fw-500">
              {{
                !isUndefinedNullOrEmpty(doInfoDetails.deliveryFee)
                  ? `${$t('lbl.rm')}${priceFormat(doInfoDetails.deliveryFee)}`
                  : '-'
              }}
            </div>
          </div>

          <v-divider class="my-3"></v-divider>

          <div class="d-flex mb-2">
            <div class="mr-2">{{ $t('orders.lbl.receiver') }}:</div>
            <div class="fw-500">{{ doInfoDetails.custName || '-' }}</div>
          </div>
          <div class="d-flex mb-2">
            <div class="mr-2">{{ $t('orders.lbl.contactNo') }}:</div>
            <div class="fw-500">{{ doInfoDetails.phone || '-' }}</div>
          </div>
          <div v-if="isDelivery(doInfoDetails.channel)" class="d-flex mb-2">
            <div class="mr-2">{{ $t('orders.lbl.address') }}:</div>
            <div class="fw-500">{{ `${doInfoDetails.address}, ${doInfoDetails.postcode} ${doInfoDetails.city}` }}</div>
          </div>
          <!-- <div class="d-flex mb-2">
            <div class="mr-2">{{ $t('orders.lbl.payMethod') }}:</div>
            <div class="fw-500">{{ payMethodMap[doInfoDetails.payMethod] }}</div>
          </div> -->
          <div v-if="isDelivery(doInfoDetails.channel)" class="text-right mt-3">
            <AppBtn class="btn-orange" :loading="printDoNoteloading" @click="printDeliveryNote(doInfoDetails)">{{
              $t('act.print')
            }}</AppBtn>
          </div>
        </div>
      </template>
    </CommonModal>
    <DetailsModal v-if="detailsModalShow" v-model="detailsModalShow" :data="orderDetails" @confirm="updateOrder" />
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
// import ConfirmModal from '@/components/Modal/ConfirmModal';
import CommonModal from '@/components/Modal';
import SuccessModal from '@/components/Modal/SuccessModal';
import DetailsModal from './Details';
import { orderApi, printApi } from '@/api/order';
import OrderMixin from '@/mixins/module/order';
import DeliveryNote from '@/module/order/delivery-note';
import { receiptConfig } from '@/module/order/config';
import { extractJsonKey } from '@/util/tools';

export default {
  name: 'OrderHistoryList',
  components: {
    // ConfirmModal,
    CommonModal,
    SuccessModal,
    DetailsModal,
  },
  mixins: [OrderMixin],
  data() {
    return {
      list: [],
      loading: false,
      successModalShow: false,
      modalShowMsg: '',

      confirmModalShow: false,
      confirmModalTitle: this.$t('orders.lbl.printReceipt'),
      confirmModalMsg: 'Please confirm to print receipt',
      conModalLoading: false,

      detailsModalTitle: this.$t('orders.lbl.deliveryInformation'),
      deliveryModalShow: false,
      detailsModalShow: false,

      receiptData: {},
      doInfoDetails: {},
      orderDetails: {},
      printDoNoteloading: false,

      dtFormat: receiptConfig.dtFormat,
    };
  },
  computed: {
    ...mapState({
      salesChannelMap: state => state.lookup.salesChannelMap,
      deliveryChannelMap: state => state.lookup.deliveryChannelMap,
      // payMethodMap: state => state.lookup.payMethodMap,
    }),
    ...mapGetters(['getCompletedOrderList', 'getStoreName']),
  },
  watch: {
    confirmModalShow(value) {
      if (value == false) {
        this.receiptData = {};
      }
    },
  },
  created() {
    this.getTodayOrder();
    // if (this.isUndefinedNullOrEmpty(this.payMethodMap)) this.fetchPayMethodList();
    if (this.isUndefinedNullOrEmpty(this.salesChannelMap)) this.fetchSalesChannelList();
    if (this.isUndefinedNullOrEmpty(this.deliveryChannelMap)) this.fetchDeliveryChannelList();
  },
  methods: {
    ...mapActions(['fetchSalesChannelList', 'fetchPayMethodList', 'fetchDeliveryChannelList']),
    getTodayOrder() {
      return orderApi.todayDelivery({}).then(res => {
        this.list = res.data;
      });
    },
    showPrintReceiptModal(item) {
      this.receiptData = item;
      this.confirmModalShow = true;
    },
    async printDeliveryNote(payload) {
      const data = {
        payMethod: payload.payMethod,
        orderSeq: payload.seq,
        orderDT: this.setTimeZone(payload.cdate, this.dtFormat),
        company: this.$t('app.org.name'),
        store: `${this.$t('app.org.storePrefix')} ${this.getStoreName}`,
        items: payload.items.map(item => {
          return {
            rowItem: item,
          };
        }),
        customer: {
          custName: payload.custName,
          phone: payload.phone,
        },
        deliveryAddress: {
          address: payload.address,
          postcode: payload.postcode,
          city: payload.city,
        },
      };

      const receipt = new DeliveryNote(data);
      const printObj = receipt.printObj;

      try {
        this.printDoNoteloading = true;
        const printRes = await this.print(printObj);
        this.printDoNoteloading = false;
        if (printRes.data.ret != '0') {
          this.showError(printRes.data.msg);
        }
      } catch (error) {
        this.printDoNoteloading = false;
        this.showError(this.$t('orders.print.doNote.failed'));
      }
    },
    print(data) {
      return printApi.orderReceipt(data).then(res => {
        return res;
      });
    },
    showDeliveryModal(item) {
      this.doInfoDetails = item;
      this.deliveryModalShow = true;
    },
    async showOrderDetails(item) {
      this.orderDetails = await this.getDetail(item.id);
      this.$nextTick(() => {
        this.detailsModalShow = true;
      });
    },
    getDetail(id) {
      return orderApi.detail({ id }).then(res => {
        return res.data;
      });
    },
    updateOrder(data) {
      this.modalShowMsg = this.$t('success.update');
      this.$nextTick(() => {
        this.successModalShow = true;

        setTimeout(() => {
          this.successModalShow = false;
        }, 500);
      });

      if (this.isUndefinedNullOrEmpty(data)) {
        this.detailsModalShow = false;
        return;
      }

      const index = this.list.findIndex(item => item.id == data.id);

      if (!this.isUndefinedNullOrEmpty(index)) {
        const keys = ['id', 'notes', 'doDate', 'postcode', 'address', 'city'];
        const extracted = extractJsonKey(keys, data);

        this.$set(this.list, index, {
          ...this.list[index],
          ...extracted,
          phone: data.mobile,
          custName: data.name,
          driver: data.driver,
          deliveryFee: data.deliveryFee,
          deliveryId: data.deliveryId,
        });
      }
      this.detailsModalShow = false;
    },
  },
};
</script>
