<template>
  <div>
    <v-simple-table class="table-wrap simple-table" fixed-header height="520px">
      <template v-slot:default>
        <thead class="header">
          <tr>
            <th class="text-left">
              {{ $t('orders.lbl.orderNo') }}
            </th>
            <th class="text-left">
              {{ $t('orders.lbl.item') }}
            </th>
            <th class="text-left">
              {{ $t('lbl.date') }}
            </th>
            <!-- <th class="text-left">
              {{ $t('orders.lbl.channel') }}
            </th> -->
            <th class="text-right"></th>
          </tr>
        </thead>
        <tbody class="body">
          <tr v-for="(item, index) in list" :key="index">
            <td>
              <div class="font-weight-medium">{{ item.custName }}</div>
              <div>{{ item.seq }}</div>
              <div style="font-size: 0.9rem">({{ salesChannelMap[item.channel] }})</div>
            </td>
            <td class="py-2">
              <ul>
                <li v-for="(orderItem, orderItemIndex) in item.items" :key="orderItemIndex" class="mb-1">
                  {{ orderItem }}
                </li>
              </ul>
              <div v-if="item.notes" class="ml-2 mt-1 fw-500" style="color: #ff5252">{{ `* ${item.notes}` }}</div>
            </td>
            <td>{{ setTimeZone(item.doDate) }}</td>
            <!-- <td>{{ salesChannelMap[item.channel] }}</td> -->
            <td class="text-right">
              <AppBtn class="btn-orange mr-2 mb-2 mb-sm-0" @click="showPayMethodModal(item)">{{ $t('orders.act.pay') }}</AppBtn>
              <AppBtn class="btn-blue-light mr-2 mb-2 mb-sm-0" @click="editOrder(item)">{{ $t('orders.act.edit') }}</AppBtn>
              <AppBtn class="btn-grey" :loading="item.loading" @click="cancelOrder(item)">{{ $t('orders.act.cancel') }}</AppBtn>
              <!-- <AppBtn class="btn-orange" :loading="loading" @click="complete(item.id)">{{ $t('orders.act.complete') }}</AppBtn> -->
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <PayMethodModal
      v-if="payMethodModalShow"
      v-model="payMethodModalShow"
      :close-btn="true"
      :data="payMethodData"
      @clicked="confirmOrder"
    />
    <SuccessModal v-model="modalShow" :msg="successModalMsg" />
    <ConfirmModal
      v-model="confirmModalShow"
      :title="confirmModalTitle"
      :msg="confirmModalMsg"
      :loading="conModalLoading"
      @confirm="confirmEditOrder"
    />
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { orderApi, orderItemApi } from '@/api/order';
import { printApi } from '@/api/order';
import { ORDER_STATUS } from '@/common/orderEnum';
import PayMethodModal from '@/views/Cart/components/PayMethodModal';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import SuccessModal from '@/components/Modal/SuccessModal';
import OrderReceipt from '@/module/order/receipt';
import { receiptConfig } from '@/module/order/config';
import { PAY_METHOD } from '@/common/paymentEnum';
import OrderMixin from '@/mixins/module/order';

export default {
  name: 'PayQueue',
  components: {
    PayMethodModal,
    ConfirmModal,
    SuccessModal,
  },
  mixins: [OrderMixin],
  data() {
    return {
      list: [],
      loading: false,
      pymtLoading: false,
      conModalLoading: false,
      modalShow: false,
      // total: 0,
      payMethodModalShow: false,
      payMethodData: {},
      successModalMsg: '',
      editOrderObj: null,
      confirmModalShow: false,
      confirmModalTitle: this.$t('cart.lbl.editCart'),
      confirmModalMsg: '',

      receiptData: {},
      dtFormat: receiptConfig.dtFormat,
    };
  },
  computed: {
    ...mapState({
      salesChannelMap: state => state.lookup.salesChannelMap,
      dashStats: state => state.app.dashStats,
    }),
    ...mapGetters(['getCartItems', 'getStoreName']),
  },
  payMethodModalShow(value) {
    if (value == false) this.payMethodData = {};
  },
  created() {
    this.getPendingPymtOrderList();
    if (this.isUndefinedNullOrEmpty(this.salesChannelMap)) this.fetchSalesChannelList();
  },
  methods: {
    ...mapMutations(['UPDATE_EDIT_ORDER', 'SET_DASH_STATS', 'SET_LAST_ORDER_ID']),
    ...mapActions(['fetchSalesChannelList', 'clearCart', 'updateWholeCart']),
    // getListNTotalRec() {
    //   this.getTotalRec().then(() => {
    //     this.getPendingPymtOrderList();
    //   });
    // },
    // getTotalRec() {
    //   const data = {
    //     page: 0,
    //     status: ORDER_STATUS.CONFIRMED,
    //   };
    //   return orderApi.list(data).then(res => {
    //     this.total = res.data.total;
    //   });
    // },
    getPendingPymtOrderList() {
      const data = {
        page: 1,
        pageSize: 100,
        status: ORDER_STATUS.DRAFT,
      };
      return orderApi.list(data).then(res => {
        this.list = res.data.map(item => {
          return {
            ...item,
            loading: false,
          };
        });
      });
    },
    showPayMethodModal(item) {
      this.payMethodData = { orderId: item.id, amt: item.amt };
      this.receiptData = item;
      this.$nextTick(() => {
        this.payMethodModalShow = true;
      });
    },
    confirmOrder(payload) {
      if (this.pymtLoading == true) {
        return;
      }
      const data = {
        id: payload.id,
        payMethod: payload.payMethod,
        notes: payload.notes,
      };

      this.pymtLoading = true;

      this.SET_LAST_ORDER_ID(data.id);

      return orderApi
        .confirm(data)
        .then(async res => {
          this.pymtLoading = false;
          this.payMethodModalShow = false;
          this.payMethodData = {};
          if (res.ret == '0') {
            this.successModalMsg = this.$t('orders.success.pay');
            this.modalShow = true;
            this.getPendingPymtOrderList();
            let dashStats = this.dashStats;
            dashStats.orders.queue -= 1;
            dashStats.orders.todo += 1;
            this.SET_DASH_STATS(dashStats);
            // this.$emit('updateCount', -1);
            setTimeout(() => {
              this.modalShow = false;
            }, 500);

            const printGrp = [1 /* Cash */, 2 /* Card */, 4 /* E-Wallet */];
            if (printGrp.indexOf(payload.grp) !== -1) {
              this.receiptData = {
                ...this.receiptData,
                openCashReg: data.payMethod == PAY_METHOD.CASH ? true : false,
                orderSeq: res.data.seq,
                orderDT: this.setTimeZone(new Date(), this.dtFormat),
                company: this.$t('app.org.name'),
                store: `${this.$t('app.org.storePrefix')} ${this.getStoreName}`,
                totalAmt: this.receiptData.amt,
                items: this.receiptData.items.map(item => {
                  return {
                    ...item,
                    rowItem: item,
                  };
                }),
              };

              const receipt = new OrderReceipt(this.receiptData);
              const printObj = receipt.printObj;

              try {
                const printRes = await this.printReceipt(printObj);
                if (printRes.data.ret != '0') {
                  this.showError(printRes.data.msg);
                }
              } catch (error) {
                this.showError(this.$t('orders.print.failed'));
              }
            }
          }
        })
        .catch(() => {
          this.pymtLoading = false;
        });
    },
    editOrder(item) {
      if (this.getCartItems.length > 0) {
        this.confirmModalMsg = this.$t('cart.lbl.editCart.confirmMsg1');
      } else {
        this.confirmModalMsg = this.$t('cart.lbl.editCart.confirmMsg2');
      }
      this.editOrderObj = {
        id: item.id,
        custUserId: item.custUserId,
        custName: item.custName,
        status: item.status,
        amt: item.amt,
        payMethod: item.payMethod,
        seq: item.seq,
      };
      this.confirmModalShow = true;
    },
    async confirmEditOrder() {
      this.conModalLoading = true;
      let data;
      try {
        data = await this.getOrderItemList(this.editOrderObj.id);
      } catch (error) {
        this.conModalLoading = false;
      }
      const cart = this.processEditCartItems(data);
      this.confirmModalShow = false;
      if (!this.isUndefinedNullOrEmpty(cart)) {
        this.clearCart();
        this.UPDATE_EDIT_ORDER(this.editOrderObj);
        this.updateWholeCart(cart);
        this.navigatePush('Cart');
      }
      this.conModalLoading = false;
      this.editOrderObj = null;
    },
    getOrderItemList(orderId) {
      const params = {
        page: 1,
        pageSize: 20,
        orderId,
      };
      return orderItemApi.list(params).then(res => {
        return res.data;
      });
    },
    cancelOrder(item) {
      const data = {
        id: item.id,
      };
      item.loading = true;
      return orderApi
        .void(data)
        .then(async res => {
          if (res.ret == '0') {
            this.successModalMsg = this.$t('orders.success.cancel');
            this.modalShow = true;
            let dashStats = this.dashStats;
            dashStats.orders.queue -= 1;
            this.SET_DASH_STATS(dashStats);
            // this.$emit('updateCount', -1);
            setTimeout(() => {
              this.modalShow = false;
            }, 500);
            await this.getPendingPymtOrderList();
          }
          item.loading = false;
        })
        .catch(() => {
          item.loading = false;
        });
    },
    printReceipt(data) {
      return printApi.orderReceipt(data).then(res => {
        return res;
      });
    },
  },
};
</script>
