<template>
  <CommonModal
    v-model="show"
    :persistent="true"
    :modal-size="'modal-lg'"
    :close-btn="closeBtn"
    :title="$t('orders.act.choosePymtMethod')"
    :footer="false"
  >
    <template #body-content>
      <v-row>
        <v-col v-for="(item, index) in payMethodList" :key="index" cols="6" sm="2">
          <div
            class="d-flex justify-center align-center text-center h-100 payment-item-wrap cursor-pointer"
            @click="clicked({ id: data.orderId, payMethod: item.value, grp: item.grp })"
          >
            {{ item.text }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="payMethodList.length > 0" cols="12" sm="6">
          <ValidationObserver ref="observer">
            <TextField v-model="notes" :label="$t('lbl.remarks')" :rules="rules.notes" :single-line="false" />
          </ValidationObserver>
        </v-col>
        <v-col cols="12" class="total-price text-right">{{ `${$t('lbl.rm')}${priceFormat(data.amt)}` }}</v-col>
      </v-row>
    </template>
  </CommonModal>
</template>
<script>
import CommonModal from '@/components/Modal';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'PayMethodModal',
  components: {
    CommonModal,
  },
  props: {
    closeBtn: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notes: '',
      rules: {
        notes: { required: true },
      },
    };
  },
  computed: {
    ...mapState({
      payMethodList: state => state.lookup.payMethodList,
    }),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  created() {
    if (this.isUndefinedNullOrEmpty(this.payMethodList)) this.fetchPayMethodList();
  },
  methods: {
    ...mapActions(['fetchPayMethodList']),
    close() {
      this.$emit('input', false);
    },
    async clicked({ id, payMethod, grp }) {
      if (payMethod != 1 /* Cash */) {
        const valid = await this.$refs.observer.validate();
        if (!valid) return;
      }
      this.$emit('clicked', { id, payMethod, grp, notes: this.notes });
    },
  },
};
</script>
<style lang="scss" scoped>
// .v-card {
//   .grp-title {
//     font-size: 20px;
//     font-weight: 500;
//   }
// }
// .modal-body-custom {
//   color: #000;
//   // display: flex;
//   // justify-content: center;
//   padding: 14px 15px 10px;
// }
.payment-item-wrap {
  border: 2px solid #000;
  border-radius: 4px;
  min-height: 100px;
}
.total-price {
  color: var(--red);
  font-size: 40px;
  font-weight: 600;
}
</style>
