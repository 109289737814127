import { lookupApi } from '@/api/lookup';

const lookup = {
  state: {
    payMethodList: [],
    payMethodMap: {},
    salesChannelList: [],
    salesChannelMap: {},
    deliveryChannelList: [],
    deliveryChannelMap: {},
    provinceList: [],
    stockAdjTypeList: [],
    stockAdjTypeMap: {},
  },
  getters: {
    // dark: (state, getters) => {
    //   return state.dark || getters.gradient.indexOf('255, 255, 255') === -1;
    // },
  },
  mutations: {
    SET_PAY_METHOD_LIST(state, payload) {
      state.payMethodList = payload;
    },
    SET_PAY_METHOD_MAP(state, payload) {
      state.payMethodMap = payload;
    },
    SET_SALES_CHANNEL_LIST(state, payload) {
      state.salesChannelList = payload;
    },
    SET_SALES_CHANNEL_MAP(state, payload) {
      state.salesChannelMap = payload;
    },
    SET_DELIVERY_CHANNEL_LIST(state, payload) {
      state.deliveryChannelList = payload;
    },
    SET_DELIVERY_CHANNEL_MAP(state, payload) {
      state.deliveryChannelMap = payload;
    },
    SET_PROVINCE_LIST(state, payload) {
      state.provinceList = payload;
    },
    SET_STOCK_ADJ_TYPE_LIST(state, payload) {
      state.stockAdjTypeList = payload;
    },
    SET_STOCK_ADJ_TYPE_MAP(state, payload) {
      state.stockAdjTypeMap = payload;
    },
  },
  actions: {
    fetchPayMethodList({ commit }, data) {
      return lookupApi.payMethodList(data).then(response => {
        const data = response.data;
        let list = [],
          map = {};
        for (let item of data) {
          list.push({ text: item.name, value: item.id, grp: item.grp });
          map[item.id] = item.name;
        }
        commit('SET_PAY_METHOD_LIST', list);
        commit('SET_PAY_METHOD_MAP', map);
        return response;
      });
    },
    fetchSalesChannelList({ commit }, data) {
      return lookupApi.salesChannelList(data).then(response => {
        const data = response.data;
        let list = [],
          map = {};
        for (let item of data) {
          list.push({ text: item.name, value: item.id });
          map[item.id] = item.name;
        }
        commit('SET_SALES_CHANNEL_LIST', list);
        commit('SET_SALES_CHANNEL_MAP', map);
        return response;
      });
    },
    fetchDeliveryChannelList({ commit }, data) {
      return lookupApi.deliveryChannelList(data).then(response => {
        const data = response.data;
        let list = [],
          map = {};
        for (let item of data) {
          list.push({ text: item.name, value: item.id });
          map[item.id] = item.name;
        }
        commit('SET_DELIVERY_CHANNEL_LIST', list);
        commit('SET_DELIVERY_CHANNEL_MAP', map);
        return response;
      });
    },
    fetchProvinceList({ commit }, data) {
      return lookupApi.provinceList(data).then(response => {
        const data = response.data;
        let list = [];
        for (let item of data) {
          list.push({ text: item.shortName, value: item.shortName });
        }
        commit('SET_PROVINCE_LIST', list);
        return response;
      });
    },
    fetchStockAdjTypeList({ commit }, data) {
      return lookupApi.stockAdjTypeList(data).then(response => {
        const data = response.data;
        let list = [],
          map = {};
        for (let item of data) {
          list.push({ text: item.name, value: item.id, dr: item.dr });
          map[item.id] = item.name;
        }
        commit('SET_STOCK_ADJ_TYPE_LIST', list);
        commit('SET_STOCK_ADJ_TYPE_MAP', map);
        return response;
      });
    },
  },
};
export default lookup;
