import Api from '../api';

export const stockApi = {
  ddl(params) {
    return Api.get('stock/bal/picklist', params);
  },
  list(params) {
    return Api.get('stock/bal/list', params);
  },
  alertList(params) {
    return Api.get('stock/bal/list/alert', params);
  },
};

export const stockAdjApi = {
  list(params) {
    return Api.get('stock/bal/adjust/list', params);
  },
  detail(params) {
    return Api.get('stock/bal/adjust', params);
  },
  create(data) {
    return Api.post('stock/bal/adjust/create', data);
  },
  update(data) {
    return Api.post('stock/bal/adjust/update', data);
  },
};

export const stockItemApi = {
  ddl(params) {
    return Api.get('stock/item/picklist', params);
  },
  list(params) {
    return Api.get('stock/item/list', params);
  },
  allowReorderList(params) {
    /* Type: 1-Service item, 2-Allow reorder */
    params.type = 2;
    return Api.get('stock/item/list', params);
  },
  detail(params) {
    return Api.get('stock/item', params);
  },
  stop(data) {
    return Api.post('stock/item/stop', data);
  },
  resume(data) {
    return Api.post('stock/item/resume', data);
  },
};

export const stockOrderApi = {
  list(params) {
    return Api.get('stock/order/list', params);
  },
  detail(params) {
    return Api.get('stock/order', params);
  },
  create(data) {
    console.log('data', data);
    return Api.post('stock/order/create', data);
  },
  update(data) {
    return Api.post('stock/order/update', data);
  },
};

export const stockOrderItemApi = {
  list(params) {
    return Api.get('stock/order/item/list', params);
  },
  detail(params) {
    return Api.get('stock/order/item', params);
  },
  create(data) {
    return Api.post('stock/order/item/create', data);
  },
  update(data) {
    return Api.post('stock/order/item/update', data);
  },
};
