import Vue from 'vue';
import io from 'socket.io-client';
import { ERROR_MSG } from './errors';
import { isUndefinedNullOrEmpty } from '@/util/tools';

export default class WebSocket {
  constructor(url, path) {
    this.url = url;
    this.path = path;
    this.subscribeList = [];
    this.emitList = [];

    this.VM = new Vue({
      data: () => ({
        connected: false,
      }),
    });
  }

  connect() {
    if (isUndefinedNullOrEmpty(this.url)) throw ERROR_MSG.INVALID_URL;
    let opts = {
      upgrade: false,
      transports: ['websocket', 'polling'],
      reconnectionDelay: 3000,
      reconnectionDelayMax: 5000,
    };
    if (this.path) opts['path'] = this.path;
    this.io = io.connect(this.url, opts);

    this.io.on('connect', () => {
      console.log('Connected');
      this.VM.$data.connected = true;
    });

    this.io.on('connect_error', () => {
      console.log('Connect error');
    });

    this.io.on('disconnect', () => {
      console.log('Disconnected');
      this.VM.$data.connected = false;
    });

    this.io.on('reconnect', () => {
      console.log('Reconnect');
      this.VM.$data.connected = true;
    });

    return this;
  }

  emit(event, param, callback, retryOffset = 1000) {
    if (false === this.connected) {
      //help user to re-emit event even when emit method is called before websocket connected
      const timeout = setTimeout(() => {
        this.emit(event, param, callback, retryOffset);
        clearTimeout(timeout);
      }, retryOffset);
      return;
    }

    if (isUndefinedNullOrEmpty(event)) throw ERROR_MSG.INVALID_EVENT;
    if ('function' === typeof param) throw ERROR_MSG.INVALID_EMIT_PARAM;
    if ('function' !== typeof callback) throw ERROR_MSG.INVALID_CALLBACK;

    // console.log('event', event);
    this.io.emit(event, param);
    return this.io.on(event, callback);
  }

  subscribe(event, callback, retryOffset = 1000) {
    if (false === this.connected) {
      //help user to re-subscribe event even when subscribe method is called before websocket connected
      const timeout = setTimeout(() => {
        this.subscribe(event, callback, retryOffset);
        clearTimeout(timeout);
      }, retryOffset);
      return;
    }
    if (isUndefinedNullOrEmpty(event)) throw ERROR_MSG.INVALID_EVENT;
    if ('function' !== typeof callback) throw ERROR_MSG.INVALID_CALLBACK;
    // only subscribe once
    if (-1 != this.subscribeList.indexOf(event)) {
      this.unsubscribe(event);
    }

    this.io.on(event, callback);
    this.subscribeList.push(event);
    return;
  }

  unsubscribe(event) {
    if (isUndefinedNullOrEmpty(event)) throw ERROR_MSG.INVALID_EVENT;
    if (isUndefinedNullOrEmpty(this.subscribeList)) return;
    const pos = this.subscribeList.indexOf(event);
    if (pos != -1) {
      this.subscribeList.splice(pos, 1);
      this.io.removeListener(event);
    }
    return;
  }

  disconnect() {
    return this.io.disconnect();
  }

  reconnect(callback) {
    this.io.on('reconnect', () => {
      callback();
    });
  }

  get connected() {
    return this.VM.$data.connected;
  }

  get client() {
    return this.io;
  }
}
