<template>
  <div>
    <v-simple-table class="table-wrap simple-table" fixed-header height="560px">
      <template v-slot:default>
        <thead class="header">
          <tr>
            <th class="text-left" style="min-width: 80px;">
              {{ $t('orders.lbl.orderNo') }}
            </th>
            <th class="text-left" style="min-width: 290px;">
              {{ $t('orders.lbl.item') }}
            </th>
            <th class="text-left" style="min-width: 75px;">
              {{ $t('orders.lbl.quantity') }}
            </th>
            <th class="text-left" style="min-width: 120px;">
              {{ $t('orders.lbl.channel') }}
            </th>
            <th class="text-left" style="min-width: 90px;">
              {{ $t('lbl.time') }}
            </th>
            <th class="text-right" style="min-width: 220px;"></th>
          </tr>
        </thead>
        <tbody class="body">
          <tr
            v-for="(item, index) in list"
            :key="index"
            class="cursor-pointer"
            :class="{ 'pointer-events-none': disableRow, 'bg-grey': getHourDiff(item.doDate) }"
            @click="clicked(item)"
          >
            <td>
              <div class="d-flex align-center">
                <div class="mr-1">{{ item.seq }}</div>
              </div>
              <div class="d-flex align-center">
                <div v-if="isSelfOrder(item.channel)" class="channel-badge">{{ $t('orders.lbl.selfOrder') }}</div>
              </div>
            </td>
            <td class="py-2">
              <ul>
                <li v-for="(orderItem, orderItemIndex) in item.items" :key="orderItemIndex" class="mb-0 items">
                  {{ orderItem }}
                </li>
              </ul>
              <div v-if="item.notes" class="ml-2 mt-1 fw-500" style="color: #ff5252">{{ `* ${item.notes}` }}</div>
            </td>
            <td>
              <div class="d-flex align-center">
                <div class="mr-2">{{ item.totItem }}</div>
              </div>
            </td>
            <td>
              <div class="d-flex w-100 mr-2 amt">{{ `${$t('lbl.rm')} ${priceFormat(item.amt)}`}}</div>
              <div class="d-flex align-center">
                <div class="d-flex mr-2">{{ salesChannelMap[item.channel] }}</div>
                <div v-if="isDelivery(item.channel) || isPickup(item.channel) || item.custUserId>0" class="cursor-pointer">
                  <IconWrap :icon="'mdi-eye'" :size="24" @click.native.stop="viewDetails(item)" />
                </div>
              </div>
            </td>
            <td>{{ setTimeZone(item.doDate, 'h:mma') }}</td>
            <td class="text-right">
              <v-icon class="mr-3" @click.stop="showPrintReceiptModal(item)">mdi-printer</v-icon>
              <AppBtn v-if="allowEdit(item)" class="btn-blue-light mr-2 mb-2 mb-sm-0" @click.stop="editOrder(item)">{{
                $t('orders.act.edit')
              }}</AppBtn>
              <AppBtn class="btn-orange" :loading="item.loading" @click.stop="complete(item)">{{
                $t('orders.act.complete')
              }}</AppBtn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <SuccessModal v-model="modalShow" :msg="modalShowMsg" />
    <OrderItemList v-model="orderItemModalShow" :list="orderItemList" :list-by-group="orderItemListByGroup" />
    <ConfirmModal
      v-model="confirmModalShow"
      :title="confirmModalTitle"
      :msg="confirmModalMsg"
      :loading="conModalLoading"
      @confirm="printReceipt"
    />
    <CommonModal v-model="detailsModalShow" :modal-size="'modal-sm-2'" :title="detailsModalTitle" :footer="false">
      <template #body-content>
        <div class="body-wrap">
          <div v-if="isDelivery(orderDetails.channel) || isPickup(orderDetails.channel)" class="d-flex mb-2">
            <div class="mr-2">
              {{ isDelivery(orderDetails.channel) ? $t('orders.lbl.deliveryTime') : $t('orders.lbl.pickupTime') }}:
            </div>
            <div class="fw-500">
              {{ !isUndefinedNullOrEmpty(orderDetails.doDate) ? setTimeZone(orderDetails.doDate) : $t('lbl.now') }}
            </div>
          </div>
          <div class="d-flex mb-2">
            <div class="mr-2">{{ $t('orders.lbl.receiver') }}:</div>
            <div class="fw-500">{{ orderDetails.custName || '-' }}</div>
          </div>
          <div class="d-flex mb-2">
            <div class="mr-2">{{ $t('orders.lbl.contactNo') }}:</div>
            <div class="fw-500">{{ orderDetails.phone || '-' }}</div>
          </div>
          <div v-if="isDelivery(orderDetails.channel)" class="d-flex mb-2">
            <div class="mr-2">{{ $t('orders.lbl.address') }}:</div>
            <div class="fw-500">{{ `${orderDetails.address}, ${orderDetails.postcode} ${orderDetails.city}` }}</div>
          </div>
          <div class="d-flex mb-2" v-if="orderDetails.notes">
            <div class="mr-2">{{ $t('orders.lbl.remarks') }}:</div>
            <div class="fw-500">{{ orderDetails.notes }}</div>
          </div>
        </div>
      </template>
    </CommonModal>
    <ConfirmModal
      v-model="editModalShow"
      :title="editModalTitle"
      :msg="editModalMsg"
      :loading="conModalLoading"
      @confirm="confirmEditOrder"
    />
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { orderApi, orderItemListApi } from '@/api/order';
import { printApi } from '@/api/order';
import { ORDER_STATUS, ORDER_ITEM_TYPE, ORDER_CHANNEL } from '@/common/orderEnum';
import { PAY_METHOD } from '@/common/paymentEnum';
import SuccessModal from '@/components/Modal/SuccessModal';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import CommonModal from '@/components/Modal';
import OrderItemList from './OrderItemList';
import OrderReceipt from '@/module/order/receipt';
import OrderMixin from '@/mixins/module/order';
import { receiptConfig } from '@/module/order/config';

export default {
  name: 'QueueList',
  components: {
    ConfirmModal,
    CommonModal,
    OrderItemList,
    SuccessModal,
  },
  mixins: [OrderMixin],
  data() {
    return {
      list: [],
      modalShow: false,
      modalShowMsg: '',
      // total: 0,
      orderItemList: [],
      orderItemModalShow: false,

      orderItemListByGroup: [],

      confirmModalShow: false,
      confirmModalTitle: this.$t('orders.lbl.printReceipt'),
      confirmModalMsg: 'Please confirm to print receipt',
      conModalLoading: false,

      detailsModalTitle: '',
      detailsModalShow: false,

      receiptData: {},
      orderDetails: {},

      dtFormat: receiptConfig.dtFormat,

      disableRow: false,

      editOrderObj: null,
      editModalShow: false,
      editModalTitle: this.$t('cart.lbl.editCart'),
      editModalMsg: '',
      editModalLoading: false,
    };
  },
  computed: {
    ...mapState({
      salesChannelMap: state => state.lookup.salesChannelMap,
      dashStats: state => state.app.dashStats,
    }),
    ...mapGetters(['getStoreName', 'getCartItems']),
  },
  watch: {
    orderItemModalShow(value) {
      if (value == false) {
        this.orderItemList = [];
      }
    },
    confirmModalShow(value) {
      if (value == false) {
        this.receiptData = {};
      }
    },
  },
  created() {
    this.getConfirmedOrderList();
    if (this.isUndefinedNullOrEmpty(this.salesChannelMap)) this.fetchSalesChannelList();
  },
  methods: {
    ...mapMutations(['UPDATE_EDIT_ORDER', 'SET_DASH_STATS']),
    ...mapActions(['fetchSalesChannelList', 'clearCart', 'updateWholeCart']),
    getHourDiff(endDate) {
      let startDate = new Date();
      endDate = Date.parse(endDate);

      const diff = (endDate - startDate) / 36e5;
      return diff >= 1 ? true : false;
    },
    // getListNTotalRec() {
    //   this.getTotalRec().then(() => {
    //     this.getConfirmedOrderList();
    //   });
    // },
    // getTotalRec() {
    //   const data = {
    //     page: 0,
    //     status: ORDER_STATUS.CONFIRMED,
    //   };
    //   return orderApi.list(data).then(res => {
    //     this.total = res.data.total;
    //   });
    // },
    getConfirmedOrderList() {
      const data = {
        page: 1,
        pageSize: 100,
        status: ORDER_STATUS.CONFIRMED,
      };
      return orderApi.list(data).then(res => {
        this.list = res.data.map(item => {
          return {
            ...item,
            loading: false,
          };
        });
      });
    },
    editOrder(item) {
      if (this.getCartItems.length > 0) {
        this.editModalMsg = this.$t('cart.lbl.editCart.confirmMsg1');
      } else {
        this.editModalMsg = this.$t('cart.lbl.editCart.confirmMsg2');
      }
      this.editOrderObj = {
        id: item.id,
        custUserId: item.custUserId,
        custName: item.custName,
        status: item.status,
        amt: item.amt - item.deliveryFee,
        payMethod: item.payMethod,
        seq: item.seq,
      };
      this.editModalShow = true;
    },
    async confirmEditOrder() {
      this.editModalLoading = true;
      let res;
      try {
        res = await this.getOrderItemList(this.editOrderObj.id);
      } catch (error) {
        this.editModalLoading = false;
      }
      const cart = this.processEditCartItems(res.data);

      const finalCart = cart.filter(item => item.itemId < 9900 /* Delivery fee */);

      this.editModalShow = false;
      if (!this.isUndefinedNullOrEmpty(finalCart)) {
        this.clearCart();
        this.UPDATE_EDIT_ORDER(this.editOrderObj);
        this.updateWholeCart(finalCart);
        this.navigatePush('Cart');
      }
      this.editModalLoading = false;
      this.editOrderObj = null;
    },
    complete(item) {
      const data = {
        id: item.id,
      };
      item.loading = true;
      this.disableRow = true;

      return orderApi
        .complete(data)
        .then(async res => {
          if (res.ret == '0') {
            this.modalShowMsg = this.$t('orders.success.complete');
            this.modalShow = true;
            let dashStats = JSON.parse(JSON.stringify(this.dashStats));
            dashStats.orders.todo -= 1;
            dashStats.orders.ready += 1;
            this.SET_DASH_STATS(dashStats);
            // this.$emit('updateCount', -1);
            setTimeout(() => {
              this.modalShow = false;
            }, 500);

            await this.getConfirmedOrderList();
          }
          item.loading = false;
          this.disableRow = false;
        })
        .catch(() => {
          item.loading = false;
          this.disableRow = false;
        });
    },
    getOrderItemList(orderId, extraParams = null) {
      const params = {
        page: 1,
        pageSize: 100,
        orderId,
        ...extraParams,
      };
      return orderItemListApi.list(params).then(res => {
        return res;
      });
    },
    async clicked(item) {
      const { data } = await this.getOrderItemList(item.id);
      this.orderItemList = data
        .filter(item => item.type == ORDER_ITEM_TYPE.ALA_CARTE)
        .map(item => {
          return {
            ...item,
            loading: false,
          };
        });
      this.orderItemListByGroup = this.processEditCartItems(data);
      this.$nextTick(() => {
        this.orderItemModalShow = true;
      });
    },
    showPrintReceiptModal(item) {
      this.receiptData = item;
      this.confirmModalShow = true;
    },
    async printReceipt() {
      let data = {
        payMethod: this.receiptData.payMethod,
        orderSeq: this.receiptData.seq,
        orderDT: this.setTimeZone(this.receiptData.cdate, this.dtFormat),
        company: this.$t('app.org.name'),
        store: `${this.$t('app.org.storePrefix')} ${this.getStoreName}`,
        totalAmt: this.receiptData.amt,
        items: this.receiptData.items.map(item => {
          return {
            ...item,
            rowItem: item,
          };
        }),
      };

      const receipt = new OrderReceipt(data);
      const printObj = receipt.printObj;

      this.conModalLoading = true;
      return printApi
        .orderReceipt(printObj)
        .then(res => {
          this.conModalLoading = false;

          if (res.data.ret == '0') {
            this.confirmModalShow = false;
            this.receiptData = {};

            this.modalShowMsg = this.$t('orders.success.printRequestSent');
            this.modalShow = true;
            setTimeout(() => {
              this.modalShow = false;
            }, 500);
          } else {
            this.showError(res.data.msg);
          }
          return res;
        })
        .catch(() => {
          this.showError(this.$t('orders.print.failed'));
          this.conModalLoading = false;
        });
    },
    allowEdit(item) {
      if ([PAY_METHOD.CASH, PAY_METHOD.COD, PAY_METHOD.U_PAY_ME].indexOf(item.payMethod) != -1) return true;
      if (item.payMethod > 199 /* Refer to PAY_METHOD enum */) return true;
      return false;
    },
    async viewDetails(item) {
      if (item.channel == ORDER_CHANNEL.DELIVERY_POS || item.channel == ORDER_CHANNEL.DELIVERY_WEB) {
        this.detailsModalTitle = this.$t('orders.lbl.deliveryInformation');
      } else if (item.channel == ORDER_CHANNEL.PICKUP_WEB) {
        this.detailsModalTitle = this.$t('orders.lbl.pickupInformation');
      } else if ((item.channel == ORDER_CHANNEL.POS || item.channel == ORDER_CHANNEL.TAKEAWAY_WEB) && item.custUserId > 0) {
        this.detailsModalTitle = this.$t('orders.lbl.takeawayInformation');
      }

      this.orderDetails = await this.getOrderDetail(item.id);
      this.detailsModalShow = true;
    },
    getDetail(id) {
      return orderApi.detail({ id }).then(res => {
        return res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.channel-badge {
  border-radius: 4px;
  background-color: #66fd54;
  color: #000;
  font-size: 10px;
  padding: 3px;
}

.amt {
  font-size: 0.95rem;
  font-weight: 600;
}

.items {
  font-size: 0.95rem;
}

.phone {
  font-size: 0.85rem;
}

.name {
  font-size: 0.85rem;
}

.bg-grey {
  background-color: #bdbdbd;
}

.theme--light.v-data-table.simple-table .v-data-table__wrapper thead.header tr th {
  padding: 2px 10px;
}

.theme--light.v-data-table.simple-table .v-data-table__wrapper tbody.body tr > td {
  padding: 2px 10px;
}

.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.bg-grey:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: #afafaf;
}
</style>
