<template>
  <CommonModal v-model="show" :modal-size="'modal-md'" :title="`${headerTitle} #${details.seq}`">
    <template #body-content>
      <ValidationObserver ref="observer">
        <div>
          <div class="font-weight-medium font-09r mb-2">Delivery Channel</div>
          <v-row>
            <v-col cols="12" sm="4">
              <Select
                v-model="details.deliveryId"
                :rules="rules.deliveryId"
                :options="deliveryChannelList"
                :single-line="true"
                :label="$t('delivery.lbl.deliveryChannel')"
                :placeholder="$t('delivery.lbl.deliveryChannel')"
                :clearable="false"
                :disabled="disabledUpdate"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <TextField
                v-model="details.driver"
                :rules="rules.driver"
                :single-line="false"
                :label="`${$t('delivery.lbl.driver')} *`"
                :validation-label="$t('delivery.lbl.driver')"
                :disabled="disabledUpdate"
              />
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>

          <div class="font-weight-medium font-09r mb-2">Delivery Address</div>
          <v-row>
            <v-col cols="12" sm="4">
              <TextField
                v-model="details.address"
                :rules="rules.address"
                :single-line="false"
                :label="`${$t('delivery.lbl.address')} *`"
                :validation-label="$t('delivery.lbl.address')"
                :disabled="disabledUpdate"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <TextField
                v-model="details.postcode"
                :rules="rules.postcode"
                :max-length="postcodeLength"
                :single-line="false"
                :label="`${$t('delivery.lbl.postcode')} *`"
                :validation-label="$t('delivery.lbl.postcode')"
                :disabled="disabledUpdate"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <TextField
                v-model="details.city"
                :rules="rules.city"
                :single-line="false"
                :label="`${$t('delivery.lbl.city')} *`"
                :validation-label="$t('delivery.lbl.city')"
                :disabled="disabledUpdate"
              />
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>

          <v-row>
            <v-col cols="12" sm="4">
              <div class="font-weight-medium font-09r mb-2">Delivery Time</div>
              <v-row>
                <v-col cols="12" sm="12">
                  <Select
                    v-model="details.doDate"
                    :rules="rules.doDate"
                    :options="scheduleList"
                    :single-line="true"
                    :label="$t('delivery.lbl.deliveryTime')"
                    :placeholder="$t('delivery.lbl.selectTimeSlot')"
                    :clearable="false"
                    :disabled="disabledUpdate"
                    @change="scheduleChg"
                  />
                </v-col>
              </v-row>
              <!-- <ValidationProvider v-slot="{ errors }" :name="$t('delivery.lbl.deliveryTime')" :rules="rules.scheduleType">
                <v-radio-group
                  class="radio-option-wrap mb-2"
                  v-model="scheduleType"
                  :error-messages="errors[0]"
                  :hide-details="'auto'"
                  :disabled="disabledUpdate"
                  @change="scheduleTypeChg"
                >
                  <v-radio class="mb-3" :label="$t('orders.lbl.orderNow')" :value="SCHEDULE_TYPE.NOW"> </v-radio>
                  <v-radio :label="$t('orders.lbl.orderInAdvance')" :value="SCHEDULE_TYPE.IN_ADVANCE"> </v-radio>
                  <template v-if="scheduleType == SCHEDULE_TYPE.IN_ADVANCE">
                    <v-row>
                      <v-col cols="12" sm="12">
                        <Select
                          v-model="details.doDate"
                          :rules="rules.doDate"
                          :options="scheduleList"
                          :single-line="true"
                          :label="$t('delivery.lbl.deliveryTime')"
                          :placeholder="$t('delivery.lbl.selectTimeSlot')"
                          :clearable="false"
                          :disabled="disabledUpdate"
                          @change="scheduleChg"
                        />
                      </v-col>
                    </v-row>
                  </template>
                </v-radio-group>
              </ValidationProvider> -->
            </v-col>
            <v-divider class="my-3" vertical></v-divider>
            <v-col cols="12" sm="4">
              <div class="font-weight-medium font-09r mb-2">Delivery Fee</div>
              <v-row>
                <v-col cols="12" sm="12">
                  <TextField
                    v-model="details.deliveryFee"
                    :rules="rules.deliveryFee"
                    :type="'number'"
                    :min="1"
                    :step="'0.1'"
                    :single-line="true"
                    :label="'Delivery Fee'"
                    :disabled="disabledUpdate"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-divider class="my-3" vertical></v-divider>
            <v-col cols="12" sm="4">
              <div class="font-weight-medium font-09r mb-2">{{ $t('orders.lbl.orderRemarks') }}</div>
              <v-row>
                <v-col cols="12" sm="12">
                  <TextField
                    v-model="details.notes"
                    :rules="rules.notes"
                    :single-line="true"
                    :label="$t('orders.lbl.orderRemarks')"
                    :disabled="disabledUpdate"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-divider class="my-3"></v-divider>

          <div class="font-weight-medium font-09r mb-2">Customer Details</div>
          <v-row>
            <v-col cols="12" sm="4">
              <TextField
                v-model="details.phone"
                :rules="rules.phone"
                :single-line="false"
                :label="`${$t('delivery.lbl.phoneNo')} *`"
                :validation-label="$t('delivery.lbl.phoneNo')"
                :disabled="disabledUpdate"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <TextField
                v-model="details.custName"
                :rules="rules.custName"
                :single-line="false"
                :label="`${$t('delivery.lbl.name')} *`"
                :validation-label="$t('delivery.lbl.name')"
                :disabled="disabledUpdate"
              />
            </v-col>
          </v-row>
        </div>
      </ValidationObserver>
    </template>
    <template #footer>
      <div class="modal-footer" style="position:sticky; bottom:0">
        <div class="text-right">
          <AppBtn class="btn-grey" @click="close">{{ $t('act.close') }}</AppBtn>
          <AppBtn v-if="!disabledUpdate" class="btn-orange ml-2" :loading="confirmOrderLoading" @click="update">{{
            $t('act.confirm')
          }}</AppBtn>
        </div>
      </div>
    </template>
  </CommonModal>
</template>
<script>
import CommonModal from '@/components/Modal';
import { ORDER_CHANNEL, SCHEDULE_TYPE } from '@/common/orderEnum';
import { lookupApi } from '@/api/lookup';
import { orderApi } from '@/api/order';
import { extractJsonKey, isToday } from '@/util/tools';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    CommonModal,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      deliveryChannelList: state => state.lookup.deliveryChannelList,
    }),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isDelivery() {
      const channel = [ORDER_CHANNEL.DELIVERY_POS, ORDER_CHANNEL.DELIVERY_WEB, ORDER_CHANNEL.DELIVERY_MOBILE];
      return channel.indexOf(this.details.channel) != -1;
    },
    isPickup() {
      const channel = [ORDER_CHANNEL.PICKUP_WEB, ORDER_CHANNEL.PICKUP_MOBILE];
      return channel.indexOf(this.details.channel) != -1;
    },
    SCHEDULE_TYPE() {
      return SCHEDULE_TYPE;
    },
  },
  data() {
    return {
      headerTitle: '',
      scheduleType: null,

      details: {},
      initDetails: {},

      scheduleList: [],
      deliveryPartnerList: [],

      postcodeLength: 5,

      confirmOrderLoading: false,

      disabledUpdate: false,

      rules: {
        scheduleType: { required: true },
        doDate: { required: true },
        deliveryId: { required: true },
        deliveryFee: { required: false },
        driver: { required: true },
        custName: { required: true, min: 2 },
        phone: { required: true, min: 9, regex: /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/ },
        address: { required: true },
        postcode: { required: true, max: 5 },
        city: { required: true },
        notes: { required: false, max: 50 },
      },
    };
  },
  created() {
    this.populateData(this.data);

    if (this.isUndefinedNullOrEmpty(this.deliveryChannelList)) this.fetchDeliveryChannelList();
    this.getScheduleList();
  },
  methods: {
    ...mapActions(['fetchDeliveryChannelList']),
    populateData(data) {
      this.details = {
        ...data,
      };
      this.initDetails = JSON.parse(JSON.stringify(this.details));

      if (this.isDelivery) {
        this.headerTitle = 'Delivery Order';
      } else if (this.isPickup) {
        this.headerTitle = 'Pickup Order';
      }

      if (this.isUndefinedNullOrEmpty(this.details.doDate)) {
        this.scheduleType = SCHEDULE_TYPE.NOW;
      } else {
        this.scheduleType = SCHEDULE_TYPE.IN_ADVANCE;
      }

      if (!isToday(new Date(this.details.cdate))) {
        // not using doDate as it can be null
        this.disabledUpdate = true;
      }
    },
    close() {
      this.$emit('input', false);
    },
    sameObj(obj1, obj2) {
      for (let key in obj1) {
        if (obj1[key] != obj2[key]) return false;
      }
      return true;
    },
    async update() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;

      const keys = [
        'id',
        'notes',
        'doDate',
        'deliveryFee',
        'deliveryId',
        'driver',
        'postcode',
        'address',
        'city',
        'phone',
        'custName',
      ];

      const initExtracted = extractJsonKey(keys, this.initDetails);
      const extracted = extractJsonKey(keys, this.details);

      if (this.sameObj(initExtracted, extracted)) {
        this.$emit('confirm', {});
      } else {
        let data = {
          ...extracted,
          deliveryFee: extracted.deliveryFee ? Number(extracted.deliveryFee) : 0,
          mobile: this.details.phone,
          name: this.details.custName,
        };
        delete data.phone;
        delete data.custName;

        this.confirmOrderLoading = true;

        return orderApi
          .updateEx(data)
          .then(res => {
            if (res.ret == '0') {
              this.$emit('confirm', data);
            }
            this.confirmOrderLoading = false;
          })
          .catch(() => {
            this.confirmOrderLoading = false;
          });
      }
    },
    scheduleTypeChg(value) {
      if (value == SCHEDULE_TYPE.IN_ADVANCE) {
        if (this.isUndefinedNullOrEmpty(this.scheduleList)) {
          this.getScheduleList();
        }
      } else {
        this.details.doDate = null;
      }
    },
    scheduleChg() {},
    getScheduleList() {
      return lookupApi.getScheduleList({ grp: 'delivery' }).then(res => {
        if (!this.isUndefinedNullOrEmpty(res.data)) {
          this.scheduleList = res.data.map(item => {
            return {
              text: item.name,
              value: item.utc,
            };
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
