export const PAY_METHOD = {
  CASH: 1,
  COD: 2,
  U_PAY_ME: 9,
  GRAB_FOOD: 162,
  STAFF_MEAL: 201,
  FREE_TRIAL: 202,
  FREE_WITH_DELIVERY: 203,
  MISHANDLING: 204,
  FOC_OR_PROMO: 205,
};
