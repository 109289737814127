import { authApi } from '@/api/auth';
import AppStorage from '@/plugins/storage/index.js';
import { sha256 } from 'js-sha256';
import jwt from 'jsonwebtoken';

const user = {
  state: {
    dark: false,
    drawer: {
      image: 0,
      gradient: 0,
      mini: false,
    },
    gradients: [
      'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
      'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',
      'rgba(244, 67, 54, .8), rgba(244, 67, 54, .8)',
    ],
    images: [
      'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
      'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-2.jpg',
      'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-3.jpg',
      'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-4.jpg',
    ],
    notifications: [],
    rtl: false,
    token: null,
    expireTime: null,
    orgId: null,
    userId: null,
    userName: '',
    storeName: '',
  },
  getters: {
    dark: (state, getters) => {
      return state.dark || getters.gradient.indexOf('255, 255, 255') === -1;
    },
    gradient: state => {
      return state.gradients[state.drawer.gradient];
    },
    image: state => {
      return state.drawer.image === '' ? state.drawer.image : state.images[state.drawer.image];
    },
    getExpireTime: state => {
      return state.expireTime ? state.expireTime : AppStorage.local.get('expireTime');
    },
    getToken: state => {
      return state.token ? state.token : AppStorage.local.get('token');
    },
    getUserName: state => {
      return state.userName ? state.userName : AppStorage.local.get('userName');
    },
    getStoreName: state => {
      return state.storeName ? state.storeName : AppStorage.local.get('storeName');
    },
  },
  mutations: {
    SET_GRADIENT(state, payload) {
      state.drawer.gradient = payload;
    },
    SET_IMAGE(state, payload) {
      state.drawer.image = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
      payload ? AppStorage.local.set('token', payload) : AppStorage.local.remove('token');
    },
    SET_EXPIRETIME(state, payload) {
      state.expireTime = payload;
      payload ? AppStorage.local.set('expireTime', payload) : AppStorage.local.remove('expireTime');
    },
    SET_ORG_ID(state, payload) {
      state.orgId = payload;
      payload ? AppStorage.local.set('orgId', payload) : AppStorage.local.remove('orgId');
    },
    SET_USER_ID(state, payload) {
      state.userId = payload;
      payload ? AppStorage.local.set('userId', payload) : AppStorage.local.remove('userId');
    },
    SET_USER_NAME(state, payload) {
      state.userName = payload;
      payload ? AppStorage.local.set('userName', payload) : AppStorage.local.remove('userName');
    },
    SET_STORE_NAME(state, payload) {
      state.storeName = payload;
      payload ? AppStorage.local.set('storeName', payload) : AppStorage.local.remove('storeName');
    },
  },
  actions: {
    login_act({ commit, dispatch }, data) {
      data.password = sha256(data.password);
      return authApi
        .login(data)
        .then(async response => {
          // let data = response.data;
          return response;
        })
        .catch(error => {
          throw error;
        });
    },
    processLoginSuccess_act({ commit }, data) {
      const decodedJwt = jwt.decode(data.token);

      // commit('SET_EXPIRETIME', data.expireTime);
      commit('SET_TOKEN', data.token);
      commit('SET_EXPIRETIME', decodedJwt.exp);
      commit('SET_ORG_ID', data.orgId);
      commit('SET_USER_ID', data.userId);
      commit('SET_USER_NAME', data.name);
      commit('SET_STORE_NAME', data.storeName);
      commit('SET_LOC_ID', data.locId);
      return data;
    },
    logout_act({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '');
        commit('SET_EXPIRETIME', '');
        commit('SET_ORG_ID', '');
        commit('SET_USER_ID', '');
        commit('SET_USER_NAME', '');
        commit('SET_STORE_NAME', '');
        commit('SET_LOC_ID', null);
        commit('SET_STORE_LOC_FLAG', 0);
        commit('CLEAR_CART');
        // AppStorage.local.remove('isLogin');
        // AppStorage.session.remove('forceQ');
        // AppStorage.session.remove('quickReg');
        // AppStorage.session.remove('selectedCountry');
        // router.replace({ name: 'Login' }).catch();
        return resolve();
      });
    },
  },
};
export default user;
