<template>
  <div>
    <DataTable
      :headers="headers"
      :items="list"
      :pagination-config="pagination"
      :mobile-breakpoint="0"
      :show-footer="false"
      disable-pagination
    >
      <template #item-cdate="{ item }">
        <div>{{ setTimeZone(item.cdate) }}</div>
      </template>
      <template #item-items="{ item }">
        <ul v-if="!isUndefinedNullOrEmpty(item.items)" class="my-2">
          <li v-for="(doItem, index) in item.items" :key="index">{{ doItem }}</li>
        </ul>
        <span v-else>-</span>
      </template>
      <template #item-doItems="{ item }">
        <ul v-if="!isUndefinedNullOrEmpty(item.doItems)" class="my-2">
          <li v-for="(doItem, index) in item.doItems" :key="index">{{ doItem }}</li>
        </ul>
        <span v-else>-</span>
      </template>
      <template #item-status="{ item }">
        <div class="font-weight-bold">{{ $t('map.stockOrderStatus')[item.status] }}</div>
      </template>
      <template #item-action="{ item }">
        <div class="d-flex justify-end my-2">
          <AppBtn
            v-if="item.status == STOCK_ORDER_STATUS.APPROVED"
            class="btn-orange"
            :loading="item.loading"
            @click="receive(item)"
            >{{ $t('stocks.act.receive') }}</AppBtn
          >
        </div>
      </template>
    </DataTable>
    <SuccessModal v-model="modalShow" :msg="$t('stocks.success.refillOrder')" :modal-width="450" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { stockOrderApi } from '@/api/stock';
import DataTable from '@/components/Table/DataTable';
import { STOCK_ORDER_STATUS } from '@/common/stockEnum';
export default {
  name: 'RefillDO',
  components: {
    DataTable,
  },
  data() {
    return {
      list: [],
      pagination: {
        total: 0,
      },
      modalShow: false,
    };
  },
  computed: {
    ...mapState({
      paginationOpts: state => state.app.paginationOpts,
    }),
    headers() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return [
          { width: '12%', text: this.$t('stocks.lbl.orderId'), value: 'id' },
          { width: '18%', text: this.$t('stocks.lbl.orderDate'), value: 'cdate' },
          { width: '40%', text: this.$t('stocks.lbl.item'), value: 'items' },
          { width: '40%', text: this.$t('stocks.lbl.doItem'), value: 'doItems' },
          { width: '20%', text: this.$t('lbl.status'), value: 'status' },
          { width: '15%', value: 'action' },
        ];
      } else if (this.$vuetify.breakpoint.sm) {
        return [
          { width: '12%', text: this.$t('stocks.lbl.orderId'), value: 'id' },
          { width: '18%', text: this.$t('stocks.lbl.orderDate'), value: 'cdate' },
          { width: '40%', text: this.$t('stocks.lbl.item'), value: 'items' },
          { width: '40%', text: this.$t('stocks.lbl.doItem'), value: 'doItems' },
          { width: '20%', text: this.$t('lbl.status'), value: 'status' },
          { width: '15%', value: 'action' },
        ];
      } else {
        return [
          { width: '12%', text: this.$t('stocks.lbl.orderId'), value: 'id' },
          { width: '18%', text: this.$t('stocks.lbl.orderDate'), value: 'cdate' },
          { width: '40%', text: this.$t('stocks.lbl.item'), value: 'items' },
          { width: '40%', text: this.$t('stocks.lbl.doItem'), value: 'doItems' },
          { width: '20%', text: this.$t('lbl.status'), value: 'status' },
          { width: '15%', value: 'action' },
        ];
      }
    },
    STOCK_ORDER_STATUS() {
      return STOCK_ORDER_STATUS;
    },
  },
  created() {
    this.pagination = { ...this.paginationOpts, ...this.pagination };
    this.getRefillDOList();
  },
  methods: {
    getRefillDOList() {
      const params = {
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
        status: STOCK_ORDER_STATUS.CONFIRMED,
      };
      return stockOrderApi.list(params).then(res => {
        this.list = res.data.map(item => {
          return {
            ...item,
            loading: false,
          };
        });
      });
    },
    receive(item) {
      const data = {
        orderId: item.id,
        status: STOCK_ORDER_STATUS.COMPLETED,
      };
      item.loading = true;
      return stockOrderApi
        .update(data)
        .then(res => {
          item.loading = false;
          if (res.ret == '0') {
            this.modalShow = true;
            setTimeout(() => {
              this.modalShow = false;
              const index = this.list.indexOf(item);
              this.list.splice(index, 1);
            }, 500);
          }
        })
        .catch(() => {
          item.loading = false;
        });
    },
  },
};
</script>
